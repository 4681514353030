import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aid-provider-logo',
  template: `
    <div
      *ngIf="logoUrl"
      class="logo padding-vertical-8"
      [ngStyle]="{ 'background-image': 'url(' + logoUrl + ' )' }"
    ></div>
  `,
  styleUrls: ['./provider-logo.component.scss']
})
export class ProviderLogoComponent implements OnInit {
  @Input() logoUrl: string;
  constructor() {}

  ngOnInit() {}
}
