import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

/**
 * This component allows you to select value from the options
 * or add a new one
 */

@Component({
  selector: 'aid-autocomplete',
  template: `
    <mat-form-field class="full-width">
      <mat-label *ngIf="label && applyPipes">{{
        label | translate | titlecase
      }}</mat-label>
      <mat-label *ngIf="label && !applyPipes">{{ label }}</mat-label>

      <input
        [required]="isRequired"
        matInput
        #input
        [value]="uppercase ? input.value.toUpperCase() : input.value"
        [matAutocomplete]="auto"
        [formControl]="control"
        data-cy="autocomplete-customer-location"
      />
      <mat-spinner
        *ngIf="loading"
        class="mat-select-search-spinner"
        diameter="16"
      >
      </mat-spinner>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onAddOption()"
        [autoActiveFirstOption]="true"
      >
        <mat-option
          *ngIf="!options || (options.length === 0 && !control.value)"
        >
          {{ notFound | translate | sentancecase }}
        </mat-option>

        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          <span *ngIf="translate && item">
            {{ item | translate | sentancecase }}
          </span>
          <span *ngIf="!translate && item"> {{ item }} </span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="control.hasError('required')">
        {{ label || 'this-field' | translate | titlecase }}
        {{ 'is-required' | translate }}
      </mat-error>
      <mat-error *ngFor="let error of control.errors | keyvalue">
        <ng-container *ngIf="displayError(error.key)">
          {{ error.value | translate }}
        </ng-container>
      </mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  @Input('options') set items(options: string[]) {
    if (options) {
      this.options = options;
      this.initFilter();
    }
  }

  constructor() {}
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() applyPipes = true;
  @Input() translate = false;
  @Input() loading: boolean;
  @Input() notFound = 'no-items-found';
  @Input() uppercase = false;

  filteredItems: Observable<any[]>;
  options: string[];

  private ngUnsubscribe = new Subject<void>();
  @Input() displayFn = item => item.name;

  ngOnInit() {}

  get isRequired(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  initFilter() {
    this.filteredItems = this.control
      ? this.control.valueChanges.pipe(
          takeUntil(this.ngUnsubscribe),
          debounceTime(1000),
          startWith(''),
          map(item => (item ? this.filterItems(item) : this.options.slice()))
        )
      : null;
  }

  filterItems(name: string) {
    return this.options.filter(item =>
      item ? item.toLowerCase().indexOf(name.toLowerCase()) === 0 : null
    );
  }

  onAddOption() {
    if (!this.control.value) {
      return;
    }
    if (!this.options.some(entry => entry === this.control.value)) {
      const index = this.options.push(this.control.value) - 1;
      this.control.setValue(this.options[index]);
    }
  }

  displayError(error): boolean {
    return !(error === 'required');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
