import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { TableColumns } from '@aid/shared/types/interfaces/table-columns.interface';

@Component({
  selector: 'aid-http-table',
  template: `
    <div class="container full-height" fxLayout="column" fxFlexFill>
      <div class="loading-shade" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>

      <div
        [ngStyle]="{
          'max-height': tableHeight ? tableHeight + 'px' : '100%'
        }"
        style="overflow: auto; position: relative"
        fxLayout="row"
      >
        <table mat-table [dataSource]="data" matSort class="full-width">
          <ng-container
            *ngFor="let column of tableColumns | keyvalue"
            matColumnDef="{{ column.key }}"
          >
            <ng-container *ngIf="!!column.value.actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="action-column">
                <button
                  *ngFor="let action of column.value.actions"
                  mat-icon-button
                  (click)="action.onPress(element)"
                >
                  <mat-icon svgIcon="{{ action.icon }}"></mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container *ngIf="!column.value.actions">
              <th mat-header-cell *matHeaderCellDef>
                {{ column.value.title | translate | uppercase }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="!column.value.isArray; else isArray">
                  <div *ngIf="column.value.displayFn; else noProperty">
                    {{ column.value.displayFn(element) }}
                  </div>
                  <ng-template #noProperty>
                    {{
                      element[column.key] ? element[column.key] : '-'
                    }}</ng-template
                  >
                </div>
                <ng-template #isArray>
                  <div *ngFor="let item of element[column.key]">
                    <div *ngIf="column.value.displayFn; else noPropertyArray">
                      {{ item ? column.value.displayFn(item) : '-' }}
                    </div>
                    <ng-template #noPropertyArray>
                      {{ item ? item : '-' }}</ng-template
                    >
                  </div>
                  <div *ngIf="element[column.key].length === 0">-</div>
                </ng-template>
              </td>
            </ng-container>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            [ngClass]="{ selectable: selectable }"
            (click)="onSelect(row)"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>

      <aid-empty-table
        [loading]="loading"
        [data]="data"
        [message]="empty"
      ></aid-empty-table>
      <div
        fxLayout
        fxLayoutAlign="center center"
        class="padding-16"
        *ngIf="!data"
      >
        <mat-spinner diameter="48"></mat-spinner>
      </div>
      <mat-paginator
        *ngIf="count"
        [pageSizeOptions]="[10, 20, 30]"
        [length]="count"
        (page)="onPage($event)"
      ></mat-paginator>
    </div>
  `,
  styleUrls: ['./http-table.component.scss']
})
export class HttpTableComponent implements OnInit {
  @Input() data: any[];
  @Input() loading: boolean;
  @Input() tableColumns: TableColumns;
  @Input() tableHeight: number;
  @Input() canEdit = false;
  @Input() canRemove = false;
  @Input() selectable = false;
  @Input() count: number;
  @Input() empty: string;

  @Output() edit = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() page = new EventEmitter<PageEvent>();

  displayedColumns: string[];
  timer = 0;

  constructor() {}

  ngOnInit() {
    this.displayedColumns = Object.keys(this.tableColumns);
  }

  onSelect(row) {
    if (this.selectable) {
      this.select.emit(row);
    }
  }

  onPage(page: PageEvent) {
    page.pageIndex++;
    this.page.emit(page);
  }
}
