import { Injectable } from '@angular/core';
import { Notification } from '@aid/notifications/types/classes';
import { NotificationType } from '@aid/notifications/types/enums';

@Injectable()
export class NotificationIconService {
  constructor() {}

  getIcon(notification: Notification): string {
    switch (notification.type) {
      case NotificationType.REMINDER:
        return 'calendar';
      case NotificationType.DAMAGE:
        return 'fire';
      case NotificationType.CUSTOMER_COMMENT:
      case NotificationType.CUSTOMER:
      case NotificationType.DOCUMENT_IMPORT_CUSTOMER:
        return 'people';
      case NotificationType.MISSING_INSURANCE:
      case NotificationType.INSURANCE_COMMENT:
      case NotificationType.INSURANCE:
      case NotificationType.DOCUMENT_IMPORT_INSURANCE:
        return 'shield';
      case NotificationType.GDV_FILE_FINISHED:
        return 'file_text';
      case NotificationType.COMMISSION_FILE_FINISHED:
        return 'percent';
    }
  }
}
