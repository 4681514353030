import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { SnackBarService } from '@aid/shared/services';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap((_response: HttpEvent<any>) => {
        if (_response instanceof HttpErrorResponse) {
          const _error = _response;
          if (_error.status === 500) {
            this.snackBarService.error('error-went-wrong', 6000);
          }
          return throwError(_error);
        }
        return of(_response);
      })
    );
  }
}
