import { Injectable } from '@angular/core';
import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { Customer } from '@aid/customers/types/classes';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import { OrganizationService } from '@aid/core/services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class MemberCustomersService extends CrudPaginationService<Customer> {
  private memberId: number;

  constructor(
    private http: HttpClient,
    private organizationService: OrganizationService
  ) {
    super(http);
  }

  get url(): string {
    return `members/${this.memberId}/customers`;
  }

  setCurrentMember() {
    const memberId = this.organizationService.organizationMember.member;
    if (this.memberId !== memberId) {
      this.memberId = memberId;
      this.changeParams(new QueryParams());
    }
  }

  setMemberId(memberId: number) {
    if (this.memberId !== memberId) {
      this.memberId = memberId;
      this.changeParams(new QueryParams());
    }
  }

  protected filterSubscribe(): boolean {
    return !!this.memberId;
  }

  add(value: Customer): Observable<Customer> {
    throw new Error('Create not working');
  }

  edit(value: Customer): Observable<Customer> {
    throw new Error('Edit not working');
  }

  delete(valueId: number): Observable<Customer> {
    throw new Error('Delete not working');
  }

  get(valueId: number): Observable<Customer> {
    throw new Error('Get not working');
  }
}
