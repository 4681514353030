import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DisplayUploadFileComponent } from '@aid/shared/components/display-upload-file/display-upload-file.component';

@Component({
  selector: 'aid-upload-file',
  template: `
    <input
      type="file"
      #file
      style="display: none"
      (change)="onAddFile($event)"
      [accept]="acceptFiles"
    />
    <aid-display-upload-file
      [displayedUrl]="displayedUrl"
      [defaultUrl]="defaultUrl"
      [showDefault]="showDefault"
      [horizontalSize]="horizontalSize"
      [verticalSize]="verticalSize"
      [details]="details"
      [title]="title"
      [width]="width"
      [height]="height"
      (upload)="onUploadPhoto()"
      (cancelPhoto)="onClose()"
    ></aid-display-upload-file>

    <mat-error
      *ngIf="!willCropPhoto && !isSizeValid"
      class="mat-small"
      style="font-weight:600"
    >
      *{{ 'size-error' | translate | sentancecase }} {{ horizontalSize }}x{{
        verticalSize
      }}px
    </mat-error>
  `,
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input() defaultUrl: string;
  @Input() willCropPhoto = false;
  @Input() acceptFiles: string;
  @Input() control: UntypedFormControl;
  @Input() horizontalSize: number;
  @Input() details: string;
  @Input() title: string;
  @Input() verticalSize: number;
  @ViewChild('file') file: ElementRef;

  @ViewChild(DisplayUploadFileComponent)
  clickToUpload: DisplayUploadFileComponent;
  width: number;
  height: number;
  displayedUrl: string;
  showDefault = true;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  onUploadPhoto() {
    this.cdRef.detectChanges();
    this.file.nativeElement.click();
  }

  onAddFile(event) {
    if (this.willCropPhoto) {
      this.control.setValue(event);
      return;
    }
    const photo = event.target.files[0];
    if (photo) {
      const reader = new FileReader();
      reader.readAsDataURL(photo);

      reader.onloadend = () => {
        this.displayedUrl = reader.result as string;
        this.showDefault = true;

        if (!this.willCropPhoto) {
          const img = new Image();
          img.src = reader.result as string;

          img.onload = (e: any) => {
            this.width = e.path[0].width;
            this.height = e.path[0].height;

            this.cdRef.detectChanges();

            if (this.isSizeValid) {
              this.control.setValue(photo);
            }
          };
        }
      };
    }
    this.cdRef.detectChanges();
  }

  onCancel() {
    this.onClose();
    this.showDefault = true;
  }

  onClose() {
    this.file.nativeElement.value = '';
    this.control.setValue(null);
    this.displayedUrl = null;
    this.showDefault = false;
    this.width = null;
    this.height = null;
  }

  get isSizeValid() {
    if (!this.width && !this.height) {
      return true;
    }
    return (
      this.width === this.horizontalSize && this.height === this.verticalSize
    );
  }
}
