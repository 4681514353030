import { HttpClient } from '@angular/common/http';
import { OrganizationService } from '@aid/core/services/organization.service';

export abstract class OrganizationResourceService {
  private _refresh: boolean;

  protected constructor(
    protected http: HttpClient,
    protected organizationService: OrganizationService
  ) {
    this.organizationService.organization$.subscribe(() => this.onRefresh());
  }

  onRefresh() {
    this._refresh = true;
  }

  clearRefresh() {
    this._refresh = false;
  }

  get refresh() {
    return this._refresh;
  }

  abstract get resource();

  get url() {
    return `organizations/${this.organizationService.organization.id}/${
      this.resource
    }`;
  }
}
