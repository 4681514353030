import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnredirectedRoutesService {
  private routesRegister: { [url: string]: boolean } = {};

  constructor() {}

  subscribeRoute(route: string) {
    this.routesRegister[route] = true;
  }

  isUrlRegistered(url: string): boolean {
    const paths = url.split('/');
    return paths.some(path => this.routesRegister[path.trim()] === true);
  }
}
