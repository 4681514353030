import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { tap } from 'rxjs/operators';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'aid-upload-photo',
  template: `
    <div
      fxLayout="column"
      fxLayoutAlign="space-between center"
      class="full-height"
    >
      <div *ngIf="!cropPhoto; else cropImage" class="upload-file">
        <aid-upload-file
          [defaultUrl]="displayedUrl"
          [control]="control"
          [willCropPhoto]="true"
          [acceptFiles]="acceptedFiles"
          [details]="details"
        ></aid-upload-file>
      </div>
      <ng-template #cropImage>
        <image-cropper
          class="image-cropper"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="aspectRatio"
          [resizeToWidth]="horizontalSize"
          [resizeToHeight]="verticalSize"
          [onlyScaleDown]="true"
          [cropperMinWidth]="horizontalSize"
          [cropperMinHeight]="verticalSize"
          [roundCropper]="roundCropper"
          outputType="base64"
          (imageCropped)="imageCropped($event)"
          [alignImage]="'center'"
        ></image-cropper>
      </ng-template>
    </div>
  `,
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {
  @Input() displayedUrl: string;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() willCropPhoto = false;
  @Input() horizontalSize: number;
  @Input() verticalSize: number;
  @Input() details: string;
  @Input() acceptedFiles = '.png,.jpg,jpeg';

  @Input() aspectRatio: number;
  @Input() roundCropper: boolean;
  imageChangedEvent;
  cropPhoto = false;

  constructor() {}

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      if (this.imageChangedEvent) {
        return;
      }

      this.cropPhoto = true;
      this.imageChangedEvent = value;
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.dataURItoBlob(event.base64)
      .pipe(tap(file => this.control.setValue(file)))
      .subscribe();
  }

  dataURItoBlob(dataURI): Observable<File> {
    return new Observable((observer: Observer<File>) => {
      const url = dataURI.split(',')[1];
      const byteString = window.atob(url);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/png' });
      const randomNumber: number = Math.random();
      const file: File = new File([blob], 'croppedImage' + randomNumber, {
        type: 'image/png',
        lastModified: Date.now()
      });
      observer.next(file);
      observer.complete();
    });
  }
}
