import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MenuOption } from '@aid/shared/types/interfaces';

@Component({
  selector: 'aid-options-menu',
  template: `
    <mat-card class="no-padding">
      <aid-options-menu-title [title]="title"></aid-options-menu-title>

      <div class="padding-vertical-16" fxLayout="column" fxLayoutGap="16px">
        <div
          *ngFor="let option of menuOptions"
          class="padding-horizontal-32 mat-subtitle-2 pointer"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGapf="8px"
          (click)="onSelectOption(option)"
        >
          <mat-icon
            *ngIf="option.selected"
            color="primary"
            svgIcon="keyboard_arrow_right"
          ></mat-icon>
          <div>{{ option.title | translate }}</div>
        </div>
      </div>
    </mat-card>
  `,
  styleUrls: ['./options-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionsMenuComponent implements OnInit {
  @Input() title: string;
  @Input() menuOptions: MenuOption[];
  @Output() selectOption = new EventEmitter<MenuOption>();

  constructor() {}

  ngOnInit() {}

  onSelectOption(option: MenuOption) {
    this.selectOption.emit(option);
  }
}
