/* eslint-disable */

export class PaginationParams {
  page? = 1;
  pageSize? = 30;
  cursor?: string;
}

export class QueryParams extends PaginationParams {
  ordering?: string;
  search?: string;
  [key: string]: string | number | boolean | string[];
}
