import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'aid-social-media-logo',
  template: `
    <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center">
      <img [src]="logo" alt="ICON" height="30" width="30" />
      <div class="mat-subtitle-2">{{ value || '-' }}</div>
    </div>
  `,
  styleUrls: ['./social-media-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialMediaLogoComponent implements OnInit {
  @Input() value: string;
  @Input() logo: string;
  constructor() {}

  ngOnInit() {}
}
