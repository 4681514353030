import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {
  private readonly folder = 'assets/icons';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  private iconPath(icon: string) {
    return `${this.folder}/sharp-${icon}-24px.svg`;
  }

  private newIconPath(icon: string) {
    return `${this.folder}/new/${icon}.svg`;
  }

  private outlineIcon(icon: string) {
    return `${this.folder}/new/${icon}-outline.svg`;
  }

  addSvgIcons() {
    const folder = 'assets/icons';
    const iconDefinitions = {
      first_name: this.newIconPath('first_name'),
      last_name: this.newIconPath('last_name'),
      first_name_col: `${folder}/first-name.svg`,
      last_name_col: `${folder}/last-name.svg`,
      contacts: `${folder}/sharp-contacts-24px.svg`,
      supervised_user_circle: `${folder}/sharp-supervised_user_circle-24px.svg`,
      whatshot: `${folder}/sharp-whatshot-24px.svg`,
      dashboard: `${folder}/sharp-dashboard-24px.svg`,
      message: `${folder}/sharp-message-24px.svg`,
      perm_device_information: `${folder}/sharp-perm_device_information-24px.svg`,
      lock: `${folder}/sharp-lock-24px.svg`,
      sms: `${folder}/sharp-sms-24px.svg`,
      flag: this.newIconPath('flag-outline'),
      german_flag: `${folder}/german_flag.svg`,
      english_flag: `${folder}/english_flag.svg`,
      event_note: `${folder}/sharp-event_note-24px.svg`,
      logout: this.newIconPath('log-out-outline'),
      keyboard_arrow_left: `${folder}/sharp-keyboard_arrow_left-24px.svg`,
      keyboard_arrow_right: this.newIconPath('arrow-ios-forward-outline'),
      add: this.newIconPath('plus-outline'),
      close: this.iconPath('close'),
      search: this.newIconPath('search-outline'),
      more: this.iconPath('more_vert'),
      more_horiz: this.iconPath('more_horiz'),
      supervisor: this.iconPath('supervisor_account'),
      chat: this.iconPath('chat'),
      link: this.newIconPath('link-outline'),
      account: this.iconPath('account_circle'),
      visibility: this.iconPath('visibility'),
      // folder: this.iconPath('folder_shared'),
      location: this.iconPath('location_on'),
      bank: this.iconPath('account_balance'),
      gas: this.iconPath('gas'),
      energy: this.iconPath('energy'),
      insurance: this.newIconPath('shield-outline'),
      add_circle: this.newIconPath('plus-circle-outline'),
      email: this.newIconPath('email-outline'),
      new_folder: this.newIconPath('folder-outline'),
      folder: this.newIconPath('folder'),
      new_grid: this.newIconPath('grid'),
      info: this.newIconPath('info-outline'),
      new_keypad: this.newIconPath('keypad-outline'),
      message_square: this.newIconPath('message-square-outline'),
      people: this.newIconPath('people-outline'),
      person: this.newIconPath('person-outline'),
      fire: this.newIconPath('fire'),
      new_arrow_left: this.newIconPath('arrow-left-outline'),
      new_arrow_ios_back: this.newIconPath('arrow-ios-back-outline'),
      bulb: this.newIconPath('bulb-outline'),
      layers: this.newIconPath('layers-outline'),
      settings: this.newIconPath('settings-outline'),
      archive: this.newIconPath('archive-outline'),
      flash: this.newIconPath('flash-outline'),
      calendar: this.newIconPath('calendar-outline'),
      eye: this.newIconPath('eye-outline'),
      eye_off: this.newIconPath('eye-off-outline'),
      swap: this.newIconPath('swap-outline'),
      credit_card: this.newIconPath('credit-card-outline'),
      edit: this.newIconPath('edit-2-outline'),
      close_circle: this.newIconPath('close-circle'),
      attach_file: this.newIconPath('attach-2-outline'),
      attached_file: this.newIconPath('attach-outline'),
      upload: this.newIconPath('cloud-upload-outline'),
      delete: this.newIconPath('trash-2-outline'),
      new_close: this.newIconPath('close-outline'),
      shield: this.newIconPath('shield-outline'),
      pdf_file: this.newIconPath('pdf-file'),
      file_text: this.newIconPath('file-text-outline'),
      external_link: this.newIconPath('external-link-outline'),
      download: this.newIconPath('cloud-download-outline'),
      bookmark: this.newIconPath('bookmark'),
      notification: this.newIconPath('bell-outline'),
      more_vertical: this.newIconPath('more-vertical'),
      bell_off: this.newIconPath('bell-off-outline'),
      clock: this.newIconPath('clock-outline'),
      list: this.newIconPath('list-outline'),
      filter: this.newIconPath('filter'),
      time: this.newIconPath('clock-outline'),
      home: this.newIconPath('home-outline'),
      persons: this.newIconPath('persons'),
      phone: this.newIconPath('phone'),
      offline_bolt: this.newIconPath('offline_bolt'),
      arrow_down: this.newIconPath('arrow-ios-downward-outline'),
      umbrella: this.newIconPath('umbrella-outline'),
      person_add: this.newIconPath('person-add-outline'),
      file_add: this.newIconPath('file-add-outline'),
      png_file: this.newIconPath('file-png'),
      jpg_file: this.newIconPath('file-jpg'),
      sync: this.newIconPath('sync-outline'),
      question_mark: this.newIconPath('question-mark-circle-outline'),
      check: this.newIconPath('checkmark-outline'),
      information: this.newIconPath('info'),
      globe: this.newIconPath('globe-outline'),
      euro: this.newIconPath('euro'),
      add_insurance: this.newIconPath('add_insurance'),
      cube: this.newIconPath('cube-outline'),
      price_tag: this.newIconPath('pricetags-outline'),
      percent: this.newIconPath('percent-outline'),
      earth: this.newIconPath('globe-2-outline'),
      radio: this.newIconPath('radio-outline'),
      edit_2: this.newIconPath('edit-outline'),
      chat_circle: this.newIconPath('message-circle-outline'),
      lock_icon: this.newIconPath('lock-outline'),
      like_equal: this.newIconPath('like_equal'),
      dislike: this.newIconPath('like_no'),
      like: this.newIconPath('like_yes'),
      check_circle: this.newIconPath('checkmark-circle-outline'),
      alert_triangle: this.newIconPath('alert-triangle-outline'),
      paper_plane: this.newIconPath('paper-plane-outline'),
      sales: this.newIconPath('options-outline'),
      clipboard: this.newIconPath('clipboard-outline'),
      activity: this.newIconPath('activity-outline'),
      refresh: this.outlineIcon('refresh'),
      pie_chart: this.newIconPath('pie-chart-outline'),
      power: this.newIconPath('power-outline'),
      mastercard: this.newIconPath('mastercard'),
      visa: this.newIconPath('visa'),
      close_circle_outline: this.newIconPath('close-circle-outline'),
      alert_circle: this.newIconPath('alert-circle-outline'),
      duplicates: this.newIconPath('diff'),
      calculate: this.newIconPath('calculate')
    };
    Object.keys(iconDefinitions).forEach(key => {
      this.iconRegistry.addSvgIcon(
        key,
        this.sanitizer.bypassSecurityTrustResourceUrl(iconDefinitions[key])
      );
    });
  }
}
