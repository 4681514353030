import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestParamsService {
  public isOrganizationRequest$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  get isOrganizationRequestValue() {
    return this.isOrganizationRequest$.value;
  }
}
