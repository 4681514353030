import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'aid-select-all',
  template: `
    <mat-checkbox
      [checked]="isChecked"
      [indeterminate]="indeterminate"
      [disabled]="disabled"
      (change)="toggleAll()"
    ></mat-checkbox>
  `,
  styleUrls: ['./select-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectAllComponent implements OnInit {
  @Input() isChecked: boolean;
  @Input() indeterminate: boolean;
  @Input() disabled = false;

  @Output() selectAll = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  toggleAll() {
    this.selectAll.emit();
  }
}
