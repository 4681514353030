import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';

@Component({
  selector: 'aid-date-picker',
  template: `
    <mat-form-field color="primary" class="full-width">
      <mat-label *ngIf="label">
        {{ label | translate }}
      </mat-label>
      <ng-container *ngIf="!isRange">
        <input
          matInput
          [matDatepicker]="picker"
          [formControl]="control"
          [required]="isRequired"
          [min]="minDate"
          [max]="maxDate"
          [placeholder]="'date-placeholder' | translate"
          data-cy="reminder-date-picker2"
          data-cy="aid-date-picker"
        />
      </ng-container>

      <ng-container *ngIf="isRange">
        <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="control">
          <input
            matStartDate
            placeholder="{{ 'start-date-placeholder' | translate }}"
            formControlName="from"
          />
          <input
            matEndDate
            placeholder="{{ 'end-date-placeholder' | translate }}"
            formControlName="to"
          />
        </mat-date-range-input>
      </ng-container>

      <mat-datepicker-toggle
        *ngIf="!isRange"
        matSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker-toggle
        *ngIf="isRange"
        matSuffix
        [for]="rangePicker"
      ></mat-datepicker-toggle>

      <mat-date-range-picker #rangePicker></mat-date-range-picker>
      <mat-datepicker #picker></mat-datepicker>

      <mat-error *ngIf="control.hasError('required')">
        {{ 'date' | translate | titlecase }} {{ 'is-required' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('endDate')">
        {{ 'end-date-error' | translate | sentancecase }}
      </mat-error>
      <mat-error *ngIf="control.hasError('startDate')">
        {{ 'start-date-error' | translate | sentancecase }}
      </mat-error>

      <mat-error *ngIf="control.hasError('matDatepickerMin')">
        {{
          'date-min-error'
            | translate: { date: minDate | date: 'shortDate' }
            | sentancecase
        }}
      </mat-error>
      <mat-error *ngIf="control.hasError('matDatepickerParse')">
        {{ 'date-invalid' | translate | sentancecase }}
      </mat-error>

      <mat-error *ngIf="control.hasError('matDatepickerMax')">
        {{
          'date-max-error'
            | translate: { date: maxDate | date: 'shortDate' }
            | sentancecase
        }}
      </mat-error>
      <mat-error *ngFor="let error of control.errors | keyvalue">
        <ng-container *ngIf="error.value && displayError(error.key)">
          {{ error.value | translate }}
        </ng-container>
      </mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() control: UntypedFormControl | UntypedFormGroup;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() label: string;
  @Input() isRange: boolean = false;

  constructor() {}

  ngOnInit() {}

  get isRequired(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  /**
   * TODO Refactor all the validators to send the error message on control
   * Remove this function when all the custom errors are done
   */
  displayError(error): boolean {
    return !(
      error === 'required' ||
      error === 'endDate' ||
      error === 'startDate' ||
      error === 'matDatepickerMin' ||
      error === 'matDatepickerMax' ||
      error === 'matDatepickerParse'
    );
  }
}
