import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Reminder } from '@aid/reminders/types/classes';
import { OrganizationMember } from '@aid/core/types/classes/organization-member.class';
import { Member } from '@aid/members/types/classes';

@Component({
  selector: 'aid-reminder-item-option',
  template: `
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="8px"
      class="container"
      (click)="onSelect()"
    >
      <div fxLayout="column">
        <div class="mat-subtitle-2 text">
          {{ reminder.text }}
        </div>
        <div *ngIf="isAssigned" class="assigned">
          {{ 'assigned-by' | translate | sentancecase }}
          {{ reminder.owner.name }}
        </div>
      </div>
      <div fxLayout="column">
        <div class="due-date">{{ reminder.dueDate | date: 'shortDate' }}</div>
        <div class="due-time">{{ reminder.dueDate | date: 'shortTime' }}</div>
      </div>
    </div>
    <mat-divider *ngIf="!last"></mat-divider>
  `,
  styleUrls: ['./reminder-item-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReminderItemOptionComponent implements OnInit {
  @Input() reminder: Reminder;
  @Input() currentMember: OrganizationMember;
  @Input() last: boolean;
  @Output() select = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onSelect() {
    this.select.emit();
  }

  isAssigned() {
    return this.currentMember.member !== (this.reminder.owner as Member).id;
  }
}
