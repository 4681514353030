import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { TOAST_DATA } from '@aid/toast/utils/toast-config';
import { Notification } from '@aid/notifications/types/classes';
import { ToastRef } from '@aid/toast/utils/toast-ref';
import { SidenavService } from '@aid/core/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationIconService } from '@aid/notifications/services/notification-icon.service';

@Component({
  selector: 'aid-notification-item-toast',
  template: `
    <div
      fxLayout="row"
      fxLayoutAlign="space-between start"
      class="full-height pointer"
      (click)="onClick()"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="16px"
        class="full-height full-width"
      >
        <div
          class="notification__icon-container"
          [ngClass]="'notification__icon-container--' + data.type"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-icon
            class="notification__icon-container__size"
            [svgIcon]="icon"
          ></mat-icon>
        </div>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="mat-subtitle-2">{{ data.title }}</div>
          <div class="mat-subtitle-2 padding-bottom-8">{{ data.message }}</div>
          <div class="mat-small basic-light-color-600">
            {{ data.created | date: 'short' }}
          </div>
        </div>
      </div>
      <button mat-icon-button class="basic-light-color-600" (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./notification-item-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemToastComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  constructor(
    @Inject(TOAST_DATA) public data: Notification,
    private toastRef: ToastRef,
    private sidenavService: SidenavService,
    private notificationIconService: NotificationIconService
  ) {}

  ngOnInit() {
    this.sidenavService.isOpened$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if (value) {
          this.onClose();
        }
      });
  }

  get icon() {
    return this.notificationIconService.getIcon(this.data);
  }

  onClose() {
    this.toastRef.close();
  }

  onClick() {
    this.toastRef.click(this.data);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
