import { GcsFile } from '@aid/shared/types/classes';
import { MessageFileSign } from '@aid/chat/types/classes/message-file-sign.class';

export class MessageFile extends GcsFile {
  message: number;
  contract?: boolean;
  signed: boolean;
  signs: MessageFileSign[];
  url?: string;
}
