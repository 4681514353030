import { RemindersFiltersService } from './reminders-filters.service';
import { RemindersService } from '@aid/reminders/services/reminders.service';
import { ReminderAttachmentService } from '@aid/reminders/services/reminders-attachments.service';

export const services = [
  RemindersFiltersService,
  RemindersService,
  ReminderAttachmentService
];

export * from './reminders.service';
export * from './reminders-filters.service';
export * from './reminders-attachments.service';
