import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aid-warning',
  template: `
    <div fxLayout="row" fxLayoutGap="4px">
      <div>
        <mat-icon
          svgIcon="alert_triangle"
          class="warning__icon"
          color="warn"
        ></mat-icon>
      </div>
      <span class="mat-small warning">{{
        message | translate | sentancecase
      }}</span>
    </div>
  `,
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {
  @Input() message: string;

  constructor() {}

  ngOnInit() {}
}
