import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  merge,
  fromEvent,
  timer,
  Subscription
} from 'rxjs';
import { tap, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private timeOutMilliSeconds: number;
  private lastTimeChanged: number;

  private readonly CHECK_TIME = 60000;

  constructor() {}

  startWatching(seconds: number) {
    this.timeOutMilliSeconds = seconds * 1000;

    return merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize')
    ).pipe(tap(() => (this.lastTimeChanged = new Date().getTime())));
  }

  checkIdle() {
    return timer(this.CHECK_TIME, this.CHECK_TIME).pipe(
      map(
        () =>
          new Date().getTime() - this.lastTimeChanged > this.timeOutMilliSeconds
      )
    );
  }
}
