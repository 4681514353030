import { Injectable } from '@angular/core';
import { CrudService } from '@aid/shared/services';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Provider } from '@aid/administration/types/classes';
import { Params } from '@angular/router';
import { Partnership } from '@aid/partnerships/types/classes/partnership.class';

@Injectable({
  providedIn: 'root'
})
export class PartnershipsService extends CrudService<Partnership> {
  private _partnershipId = new BehaviorSubject<number>(null);

  get url() {
    return 'partnerships';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  getProviders(): Observable<Provider[]> {
    return this.http.get<Provider[]>(
      `${this.url}/${this.partnershipId}/providers`
    );
  }

  getInsuranceTypes(providerId: number) {
    const params: Params = {
      provider: providerId
    };

    return this.http.get(`${this.url}/${this.partnershipId}/insurance-types`, {
      params
    });
  }

  set partnershipId(id: number) {
    this._partnershipId.next(id);
  }

  get partnershipId() {
    return this._partnershipId.value;
  }

  get partnershipId$() {
    return this._partnershipId.asObservable();
  }

  updateAll(partnership: Partnership) {
    const params = {
      product_partner: partnership.productPartner as number
    } as Params;
    return this.http.patch(
      `${this.url}/${partnership.id}/update-all`,
      partnership,
      { params }
    );
  }
  getByDistributor(distributorId: number): Partnership {
    const partnerships = this.values$.value;

    return partnerships.find(
      (value: Partnership) => value.distributor === distributorId
    );
  }
}
