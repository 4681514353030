import { Injectable, ViewContainerRef } from '@angular/core';
import { SidenavOption } from '@aid/core/types/interfaces';
import { BehaviorSubject } from 'rxjs';
import { FeaturesService } from '@aid/core/services/features.service';
import { Features } from '@aid/core/types/enums';
import { Organization } from '@aid/core/types/classes';
import { UserService } from '@aid/core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  /**
   * View container ref of the content from the sidenav. Dynamic components
   * should be injected in this view container ref.
   */
  public sidenavContent: ViewContainerRef;

  private readonly DEFAULT_SIDENAV_MAX_WIDTH = 500;
  readonly SIDENAV_MAX_WIDTH = 600;

  constructor(
    private featuresService: FeaturesService,
    private userService: UserService
  ) {}

  public opened$ = new BehaviorSubject<boolean>(false);
  public width$ = new BehaviorSubject<number>(this.DEFAULT_SIDENAV_MAX_WIDTH);

  public buildSidenavOptions(organization: Organization): SidenavOption[] {
    const organizationSlug = organization.slug;
    const options: SidenavOption[] = [
      {
        name: 'dashboard',
        icon: 'new_grid',
        routerLink: `${organizationSlug}/`,
        permissions: []
      },
      {
        name: 'damage',
        icon: 'fire',
        routerLink: `${organizationSlug}/damages`,
        permissions: []
      },
      {
        name: 'reminders',
        icon: 'calendar',
        routerLink: `${organizationSlug}/reminders`,
        permissions: []
      },
      {
        name: 'customers',
        icon: 'people',
        routerLink: `${organizationSlug}/customers`,
        permissions: []
      },
      {
        name: 'insurances',
        icon: 'insurance',
        routerLink: `${organizationSlug}/insurances/list`,
        permissions: []
      },
      {
        name: 'users',
        icon: 'person',
        routerLink: `${organizationSlug}/management`,
        permissions: []
      },
      /*{
        name: 'information',
        icon: 'info',
        routerLink: `${organizationSlug}/information`,
        permissions: []
      },
      {
        name: 'contact',
        icon: 'email',
        routerLink: `${organizationSlug}/contact`,
        permissions: []
      },*/
      {
        name: 'partnerships',
        icon: 'new_keypad',
        routerLink: `${organizationSlug}/partnerships`,
        permissions: ['administrators']
      },
      {
        name: 'GDV',
        icon: 'file_text',
        routerLink: `${organizationSlug}/gdv`,
        permissions: ['administrators']
      },
      {
        name: 'accounting.label',
        icon: 'percent',
        routerLink: `${organizationSlug}/accounting`,
        permissions: ['administrators']
      },
      {
        name: 'reports',
        icon: 'pie_chart',
        routerLink: `${organizationSlug}/reports`,
        permissions: ['administrators']
      },
      {
        name: 'import',
        icon: 'upload',
        routerLink: `${organizationSlug}/import`,
        permissions: ['administrators']
      },
      {
        name: 'billing.label',
        icon: 'credit_card',
        routerLink: `${organizationSlug}/billing`,
        permissions: []
      },
      {
        name: 'administration',
        icon: 'settings',
        routerLink: `${organizationSlug}/administration`,
        permissions: ['administrators']
      }
    ];

    if (!this.featuresService.isFeatureAvailable(Features.GDV)) {
      const index = options.findIndex(value => value.name === 'GDV');
      options.splice(index, 1);
    }

    if (!this.featuresService.isFeatureAvailable(Features.ACCOUNTING)) {
      const index = options.findIndex(
        value => value.name === 'accounting.label'
      );
      options.splice(index, 1);
    }

    if (!this.featuresService.isFeatureAvailable(Features.REPORTS)) {
      const index = options.findIndex(value => value.name === 'reports');
      options.splice(index, 1);
    }

    if (organization.withoutSubscription || !this.userService.isOwner) {
      const index = options.findIndex(value => value.name === 'billing.label');
      options.splice(index, 1);
    }

    return options;
  }

  setWidth(size) {
    this.width$.next(size);
  }

  open(componentFactory, width?: number) {
    // this.sidenavContent.clear();
    this.sidenavContent.createComponent(componentFactory);
    this.opened$.next(true);
    if (width) {
      this.width$.next(width);
    }
  }

  close() {
    this.sidenavContent.clear();
    this.opened$.next(false);
    this.width$.next(this.DEFAULT_SIDENAV_MAX_WIDTH);
  }

  get isOpened$() {
    return this.opened$.asObservable();
  }

  get isOpenedValue() {
    return this.opened$.value;
  }
}
