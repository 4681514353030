import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'aid-textarea-input',
  template: `
    <div fxLayout="column" class="full-width full-height" fxLayoutGap="8px">
      <mat-form-field class="full-height">
        <textarea
          matInput
          [maxlength]="maxLength"
          class="flex textarea"
          placeholder="{{ placeholder | translate | sentancecase }}"
          [formControl]="control"
          data-cy="textarea-input"
        >
        </textarea>

        <mat-error *ngIf="control.hasError('required')">
          {{ 'this-field' | translate | titlecase }}
          {{ 'is-required' | translate }}
        </mat-error>

        <mat-error *ngFor="let error of control.errors | keyvalue">
          <ng-container *ngIf="displayError(error.key)">
            {{ error.value | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./textarea-input.component.scss']
})
export class TextareaInputComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() maxLength: number = null;

  @Output() write = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  displayError(error): boolean {
    return !(error === 'required');
  }
}
