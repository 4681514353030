import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SystemMessageType } from '@aid/core/types/enums/system-message-type.enum';
import { OrganizationService } from '@aid/core/services/organization.service';
import { SystemMessage } from '@aid/core/types/classes';
import { ChatSystemMessage } from '@aid/chat/types/classes';

@Injectable({
  providedIn: 'root'
})
export class SystemChannelService {
  constructor(
    private firestore: AngularFirestore,
    private organizationService: OrganizationService
  ) {}

  subscribe(
    type: SystemMessageType,
    resourceId?: number
  ): Observable<SystemMessage[]> {
    const currentDate = moment().toDate();
    const organization = this.organizationService.organization.slug;
    return this.firestore
      .collection<SystemMessage>(`system`, ref => {
        let query = ref
          .where('creationDate', '>=', currentDate)
          .where('organization', '==', organization)
          .where('type', '==', type);

        if (resourceId) {
          query = query.where('resourceId', '==', resourceId);
        }
        return query;
      })
      .valueChanges()
      .pipe(
        filter((value: any) => value.length > 0),
        debounceTime(200)
      );
  }
  subscribeChat(
    receiverId: number,
    senderId: number
  ): Observable<ChatSystemMessage[]> {
    const currentDate = moment().toDate();
    return this.firestore
      .collection<ChatSystemMessage>('system', ref => {
        const query = ref
          .where('creationDate', '>=', currentDate)
          .where('type', '==', SystemMessageType.CHAT)
          .where('receiverId', '==', receiverId)
          .where('senderId', '==', senderId);
        return query;
      })
      .valueChanges()
      .pipe(
        filter((value: any) => value.length > 0),
        debounceTime(200)
      );
  }
}
