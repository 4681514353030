import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { RemindersFilter } from '@aid/reminders/types/classes/reminders-filter';
import { CustomersService } from '@aid/customers/services';
import { InsurancesService } from '@aid/insurances/services';
import { RemindersParams } from '@aid/reminders/types/interfaces';
import { Customer } from '@aid/customers/types/classes';
import { Insurance } from '@aid/insurances/types/classes';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RemindersFiltersService {
  private filter = new BehaviorSubject<RemindersFilter>(null);
  private sidenavOpened = new BehaviorSubject<boolean>(false);

  constructor(
    private customersService: CustomersService,
    private insurancesService: InsurancesService
  ) {}

  applyFilter(filter: RemindersFilter) {
    this.filter.next(filter);
  }

  /**
   * Create Filter from Query Params
   * If the current filter has the same customer or insurance we take the customer and insurance
   * from current filter
   * If not we get the details from the API
   */
  createFilterFromParams(params: RemindersParams): Observable<RemindersFilter> {
    const currentFilter = this.filtersValue;
    const filter = RemindersFilter.createFromParams(params, currentFilter);

    const observables = [];

    if (params.customer && !filter.customer) {
      observables.push(this.customersService.getBasic(params.customer));
    }
    if (params.insurance && !filter.insurance) {
      this.insurancesService.setCustomer(params.customer);
      observables.push(this.insurancesService.getBasic(params.insurance));
    }

    if (observables.length > 0) {
      return combineLatest([observables]).pipe(
        tap(([values]) => {
          filter.customer = values[0] as Customer;
          filter.insurance =
            values.length > 1 ? (values[1] as Insurance) : null;
          this.applyFilter(filter);
        }),
        map(() => this.filtersValue)
      );
    } else {
      this.filter.next(filter);
      return of(filter);
    }
  }

  get filter$(): Observable<RemindersFilter> {
    return this.filter.asObservable();
  }

  get filtersValue(): RemindersFilter {
    return this.filter.value;
  }

  get sidenavOpened$(): Observable<boolean> {
    return this.sidenavOpened.asObservable();
  }

  toggleSidenav() {
    this.sidenavOpened.next(!this.sidenavOpened.value);
  }
}
