import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import { AuthService } from '@aid/auth/services';
import { User } from '@aid/core/types/classes';
import { catchError, first, map } from 'rxjs/operators';
import { OrganizationService } from '@aid/core/services/organization.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private organizationService: OrganizationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url = state.url.split('?')[0];
    if (!this.authService.hasTokens) {
      const queryParams: Params = {
        url
      };
      if (Object.keys(next.queryParams).length > 0) {
        queryParams.quaryParams = JSON.stringify(next.queryParams);
      }
      this.router.navigate(['/auth'], {
        queryParams
      });
      return false;
    }

    return this.userService.get().pipe(
      first(),
      map((user: User) => {
        if (user.defaultOrganization) {
          this.organizationService.setOrganizationMember(
            user.defaultOrganization
          );
          if (url.length > 1) {
            return true;
          }
          this.router.navigate([user.defaultOrganization.slug]);
        } else {
          this.router.navigate(['no-organization']);
        }
        return true;
      }),
      catchError(error => {
        this.userService.logOut();
        return this.router.navigate(['auth']);
      })
    );
  }
}
