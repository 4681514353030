import { PdfBoxNotificationPolicy } from '@aid/administration/modules/settings/types/enums/pdf-box-notification-policies.enum';
import { PdfBoxRetentionPolicy } from '@aid/administration/modules/settings/types/enums/pdf-box-retention-policies.enum';
import { ContactPerson } from '@aid/core/types/classes/contact-person.class';
import { OrganizationLogo } from '@aid/core/types/classes/organization-logo.class';
import { SubscriptionStatus } from '@aid/subscriptions/types/enums';

export class Organization {
  default: boolean;
  id: number;
  name: string;
  slug: string;
  productPartner: number;

  contactPerson?: ContactPerson;
  country: string;
  city: string;
  postalCode: string;
  address: string;
  description: string;
  trialEnd: string;

  created: string | Date;

  /**
   * Social details
   */
  website: string;
  facebook: string;
  twitter: string;
  linkedin: string;

  logo: OrganizationLogo;

  favicon: OrganizationLogo;

  multiplicationFactor: number;

  hasSubscription: boolean;
  billing: number;
  withoutSubscription: boolean;
  subscriptionStatus: SubscriptionStatus;
  suspended: boolean;

  mrMoneyToken: string;
  mrMoneyBrokerId: string;
  mrMoneyPartnerId: string;

  /* PDF Box */
  isPdfboxIntegrationActivated: boolean;
  pdfboxToken?: string;
  pdfboxNotificationPolicy: PdfBoxNotificationPolicy;
  pdfboxRetentionPolicy: PdfBoxRetentionPolicy;
  pdfboxNotifySupervisorOnTasks: boolean;
  pdfboxShareDocumentsWithCustomer: boolean;
  pdfboxAssigneeWhenNoMatch?: number;
  pdfboxAssigneeWhenError?: number;
  pdfboxRecurringDownloadAt?: string;
  pdfboxGeneralAssignee?: number;
  pdfboxInformProductPartner?: boolean;

  constructor(organization?: Organization) {
    Object.assign(this, organization);
  }
}
