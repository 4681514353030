export class ContactPerson {
  id: number;
  name: string;
  job: string;
  email: string;
  phoneNumber: string;

  get isEmpty(): boolean {
    return !(this.name || this.job || this.email || this.phoneNumber);
  }
}
