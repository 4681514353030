import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizationService } from '@aid/core/services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class DistributorGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const redirect: string[] = route.data.redirect;

    if (this.organizationService.organization.productPartner) {
      const redirectTo = [this.organizationService.organization.slug];
      if (redirect) {
        redirectTo.push(...redirect);
      }
      this.router.navigate(redirectTo);
      return false;
    }

    return true;
  }
}
