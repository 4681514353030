import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Feature } from '@aid/core/types/classes/feature.class';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap } from 'rxjs/operators';
import { Features } from '@aid/core/types/enums';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  private features$ = new BehaviorSubject<Feature[]>(null);

  private readonly url = 'feature-flags';

  constructor(private http: HttpClient) {}

  list(): Observable<Feature[]> {
    if (this.features$.value) {
      return this.features$.asObservable();
    }
    return this.http.get<Feature[]>(this.url).pipe(
      tap(features => this.features$.next(features)),
      switchMap(() => this.features$.asObservable())
    );
  }

  isFeatureAvailable(feature: Features): boolean {
    return !!this.features$.value.find(value => value.code === feature);
  }

  set features(features: Feature[]) {
    this.features$.next(features);
  }
}
