import { BankAccountType } from '@aid/customers/types/enums';

export class CustomerBankAccount {
  id: number;
  type: BankAccountType;
  name: string;
  iban: string;
  bic: string;
  bank: string;
  blz: string;
  number: string;
}
