import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'aid-input',
  template: `
    <mat-form-field class="full-width" color="primary">
      <mat-label *ngIf="label && applyPipes">{{
        label | translate | sentancecase
      }}</mat-label>
      <mat-label *ngIf="label && !applyPipes">{{ label }}</mat-label>
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon
          *ngIf="prefixIcon"
          [ngClass]="{ 'resize-icon': prefixIcon === 'euro' }"
          matPrefix
          [svgIcon]="prefixIcon"
        ></mat-icon>
        <input
          matInput
          [formControl]="control"
          autocomplete="off"
          placeholder="{{ placeholder | translate }}"
          [type]="type"
          [required]="isRequired"
          data-cy="text-input"
        />
        <mat-icon
          *ngIf="suffixIcon"
          matSuffix
          [svgIcon]="suffixIcon"
          [ngClass]="{ 'resize-icon': suffixIcon === 'euro' }"
        >
        </mat-icon>
        <mat-spinner *ngIf="loading" class="filter__loading" diameter="16">
        </mat-spinner>
      </div>
      <mat-error *ngIf="control.hasError('required')">
        {{ label || 'this-field' | translate | titlecase }}
        {{ 'is-required' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('email')">
        {{ 'email-invalid' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('timeFormat')">
        {{ 'time-format-error' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('phoneNumberFormat')">
        {{ 'phone-number-format-error' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('hexColor')">
        {{ 'hex-color-error' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('maxThreeCharacters')">
        {{ label || 'this-field' | translate | sentancecase }}
        {{ 'abbreviation-3-characters' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('pattern')">
        {{ 'invalid-format' | translate }}
      </mat-error>

      <mat-error *ngFor="let error of control.errors | keyvalue">
        <ng-container *ngIf="displayError(error.key)">
          {{ error.value | translate }}
        </ng-container>
      </mat-error>

      <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    </mat-form-field>
  `,
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() label: string;
  @Input() placeholder: string;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Input() applyPipes = true;
  @Input() type = 'text';
  @Input() loading = false;
  @Input() hint: string;
  @Output() onChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this.onChange.emit(value);
    });
  }

  get isRequired(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  get errors() {
    return this.control.errors ? this.control.errors : {};
  }

  /**
   * TODO Refactor all the validators to send the error message on control
   * Remove this function when all the custom errors are done
   */
  displayError(error): boolean {
    return !(
      error === 'required' ||
      error === 'email' ||
      error === 'timeFormat' ||
      error === 'phoneNumberFormat' ||
      error === 'hexColor' ||
      error === 'maxThreeCharacters' ||
      error === 'pattern'
    );
  }
}
