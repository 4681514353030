import { Injectable } from '@angular/core';
import { CustomerMessage } from '@aid/customers/types/classes';

@Injectable()
export class CustomerContractService {
  private message: CustomerMessage;
  private file: File;

  getMessage(): CustomerMessage {
    return this.message;
  }

  getFile(): File {
    return this.file;
  }

  setMessage(message: CustomerMessage, file: File) {
    this.message = message;
    this.file = file;
  }
}
