import { AuthGuard } from './auth.guard';
import { PermissionsGuard } from '@aid/core/guards/permissions.guard';

export const guards = [AuthGuard, PermissionsGuard];

export * from './auth.guard';
export * from './organization.guard';
export * from './permissions.guard';
export * from './product-partner.guard';
export * from './distributor.guard';
export * from './organization-suspended.guard';
export * from './organization-not-suspended.guard';
