import { Injectable } from '@angular/core';
import { CrudService } from '@aid/shared/services';
import { HttpClient } from '@angular/common/http';
import { OrganizationService } from '@aid/core/services/organization.service';
import { OrganizationProvider } from '@aid/administration/types/classes/organization-provider.class';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationProviderService extends CrudService<
  OrganizationProvider
> {
  constructor(
    private http: HttpClient,
    private organizationService: OrganizationService
  ) {
    super(http);
  }

  get url(): string {
    return `organizations/${
      this.organizationService.organization.id
    }/providers`;
  }

  get(providerId: number): Observable<OrganizationProvider> {
    return super.list().pipe(
      map(() => {
        return this.values$.value.find(
          value => value.provider.id === providerId
        );
      })
    );
  }

  productPartners(): Observable<OrganizationProvider[]> {
    return this.http.get<OrganizationProvider[]>(
      `${this.url}/product-partners`
    );
  }

  configureProvider(providerId: number): Observable<OrganizationProvider> {
    const body = {
      provider: providerId
    };

    return this._http.post<OrganizationProvider>(`${this.url}`, body).pipe(
      tap(value => {
        const values: OrganizationProvider[] = [...this.values$.value, value];
        this.values$.next(values);
      })
    );
  }

  search(search: string) {
    if (!search) {
      return this.values$.value;
    }
    if (!this.values$.value) {
      return [];
    }

    return this.values$.value.filter(
      organizationProvider =>
        organizationProvider.provider.name
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
    );
  }

  searchProductPartner(providers: OrganizationProvider[], search: string) {
    if (!search) {
      return providers;
    }
    if (!this.values$.value) {
      return [];
    }

    return providers.filter(
      organizationProvider =>
        organizationProvider.provider.name
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
    );
  }
}
