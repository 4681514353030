import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy
} from '@angular/core';

@Directive({
  selector: '[aidAutoScroll]'
})
export class AutoScrollDirective implements AfterContentInit, OnDestroy {
  /*
    Argument passed to lock-y-offset is bottom offset of scroll position
    in pixels after scroll container stops auto scroll. Default value is 10.
  */
  @Input() public lockYOffset = 10;

  /*
    observe-attributes (optional, default - false) enable listening on
    attributes changes for example detect changes in font size.
  */
  @Input() public observeAttributes = 'false';

  private nativeElement: HTMLElement;
  private _isLocked = false;
  private mutationObserver: MutationObserver;

  constructor(element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  public getObserveAttributes(): boolean {
    return (
      this.observeAttributes !== '' &&
      this.observeAttributes.toLowerCase() !== 'false'
    );
  }

  public ngAfterContentInit(): void {
    this.mutationObserver = new MutationObserver(() => {
      if (!this._isLocked) {
        this.scrollDown();
      }
    });
    this.mutationObserver.observe(this.nativeElement, {
      childList: true,
      subtree: true,
      attributes: this.getObserveAttributes()
    });
  }

  public ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  public forceScrollDown(): void {
    this.scrollDown();
  }

  public isLocked(): boolean {
    return this._isLocked;
  }

  private scrollDown(): void {
    this.nativeElement.scrollTop = this.nativeElement.scrollHeight;
  }

  @HostListener('scroll')
  private scrollHandler(): void {
    const scrollFromBottom =
      this.nativeElement.scrollHeight -
      this.nativeElement.scrollTop -
      this.nativeElement.clientHeight;
    this._isLocked = scrollFromBottom > this.lockYOffset;
  }
}
