import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import { tap } from 'rxjs/operators';
import { PaginationService } from '@aid/shared/services/pagination.service';

export abstract class CrudPaginationService<
  T extends { id?: number }
> extends PaginationService<T> {
  protected values = new BehaviorSubject<T[]>([]);
  protected queryParams = new BehaviorSubject<QueryParams>(
    this.getInitialQueryParams()
  );
  protected loading = new BehaviorSubject<boolean>(null);
  protected count = new BehaviorSubject<number>(null);
  protected hasNext = new BehaviorSubject<boolean>(null);
  protected loadMore = false;
  protected cursor = new BehaviorSubject<string>(null);

  protected constructor(protected _http: HttpClient, protected cache = false) {
    super(_http, cache);
  }

  get(valueId: number): Observable<T> {
    return this._http
      .get<T>(`${this.url}/${valueId}`)
      .pipe(tap(() => this.loading.next(false)));
  }

  add(value: T): Observable<T> {
    return this.addRequest(value).pipe(tap(result => this.addValue(result)));
  }

  edit(value: T): Observable<T> {
    return this._http
      .patch<T>(`${this.url}/${value.id}`, value)
      .pipe(tap(result => this.editValue(result)));
  }

  delete(id: number) {
    return this._http.delete(`${this.url}/${id}`).pipe(
      tap(() => this.deleteValue(id)),
      tap(() => this.getNextPageAfterDelete())
    );
  }

  protected addRequest(value: T): Observable<T> {
    return this._http.post<T>(`${this.url}`, value);
  }

  protected addValue(value: T) {
    if (!this.useSubscribe) {
      return;
    }
    this.values.next([value, ...this.values.value]);
  }

  protected editValue(value: T) {
    if (!this.useSubscribe) {
      return;
    }

    const index = this.values.value.findIndex(item => item.id === value.id);
    this.values.value[index] = value;

    this.values.next([...this.values.value]);
  }

  /**
   * Get next page after delete
   * This method is used only if the cursor pagination is used
   */
  protected getNextPageAfterDelete() {
    if (!this.useSubscribe || !this.hasNext.value || !this.cursor.value) {
      return;
    }
    if (this.values.value.length < this.queryParamsValue.pageSize) {
      this.loadNextPage();
    }
  }

  protected deleteValue(valueId: number) {
    if (!this.useSubscribe) {
      return;
    }

    const index = this.values.value.findIndex(item => item.id === valueId);
    this.values.value.splice(index, 1);
    this.values.next([...this.values.value]);
  }

  private get useSubscribe(): boolean {
    return !!this.values.value;
  }
}
