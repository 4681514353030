import { Component, OnInit } from '@angular/core';
import { Organization } from '@aid/core/types/classes';

@Component({
  selector: 'aid-not-found-page',
  template: `
    <div
      class="full-height full-width"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="16px"
    >
      <img
        src="assets/background/404_not_found.png"
        alt="no"
        class="not-found__image-404"
      />
      <div class="padding-8 mat-subtitle-2 not-found__message">
        {{ 'page-not-found' | translate }}
      </div>
    </div>
  `,
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  organization: Organization;

  constructor() {}

  ngOnInit() {}
}
