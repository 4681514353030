import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortTime'
})
export class ShortTimePipe implements PipeTransform {
  transform(value: string): string {
    const time: string[] = value.split(':');
    const hours = parseInt(time[0], 10);
    const minutes = time[1];

    switch (hours) {
      case 0: {
        return 12 + ':' + minutes + ' AM';
      }
      case 12: {
        return 12 + ':' + minutes + ' PM';
      }
      default: {
        if (hours > 12) {
          return hours - 12 + ':' + minutes + ' PM';
        }

        return hours + ':' + minutes + ' AM';
      }
    }
  }
}
