import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'aid-sidenav-footer-actions',
  template: `
    <div class="border actions full-width" fxLayout="row" fxFlex="100">
      <div *ngIf="canDelete" class="actions--border-right" fxFlex="12">
        <button
          mat-icon-button
          class="actions__delete-button actions--color"
          (click)="delete.emit()"
        >
          <mat-icon class="actions__delete-icon" svgIcon="delete"></mat-icon>
        </button>
      </div>
      <div class="actions--border-right" fxFlex="44">
        <button
          mat-flat-button
          class="full-height full-width actions--color"
          (click)="cancel.emit()"
        >
          {{ 'cancel' | translate }}
        </button>
      </div>
      <div fxFlex>
        <button
          mat-flat-button
          class="full-height full-width"
          color="primary"
          [disabled]="disabled"
          (click)="save.emit()"
        >
          <div fxLayoutAlign="center center">
            <mat-progress-spinner
              *ngIf="loading"
              mode="indeterminate"
              [diameter]="24"
              color="white"
              [strokeWidth]="3"
            >
            </mat-progress-spinner>
          </div>
          <span *ngIf="!loading">{{ 'save' | translate }}</span>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./sidenav-footer-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavFooterActionsComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() canDelete = false;
  @Input() loading: boolean;

  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() delete = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
