import { Injectable } from '@angular/core';
import { CrudService } from '@aid/shared/services/crud.service';
import { HttpClient } from '@angular/common/http';
import { Member } from '@aid/members/types/classes/member.class';
import { InviteUser } from '@aid/members/types/interfaces/invite-user.interface';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ADMINISTRATORS_GROUP } from '@aid/shared/constants/constants';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Permission } from '@aid/core/types/interfaces/permission.interface';
import { Params } from '@angular/router';
import { OrganizationService } from '@aid/core/services/organization.service';
import { CommissionLevel } from '@aid/members/types/classes';

@Injectable({
  providedIn: 'root'
})
export class MembersService extends CrudService<Member> {
  public sidenavOpened$ = new BehaviorSubject<boolean>(false);
  public inviteMemberSupervisor$ = new BehaviorSubject<Member>(null);

  private memberCommissionFactor$ = new BehaviorSubject<number>(null);
  private permissions$ = new BehaviorSubject<Permission[]>(null);

  constructor(
    private http: HttpClient,
    private organizationService: OrganizationService
  ) {
    super(http);
  }

  get url(): string {
    return `members`;
  }

  get permissionsValue() {
    return this.permissions$.value;
  }

  edit(member: Member): Observable<Member> {
    return this._http
      .patch(`${this.url}/${member.id}`, member)
      .pipe(tap((_value: Member) => this.editValue(member.id, _value)));
  }

  delete(memberId: number, substituteMember?: number) {
    const params = { member: substituteMember } as Params;
    return this._http.delete(`${this.url}/${memberId}`, { params }).pipe(
      tap(() => (this.refresh = true)),
      switchMap(() => this.list())
    );
  }

  permissions(memberId: number) {
    return this.http
      .get<Permission[]>(`${this.url}/${memberId}/permissions`)
      .pipe(
        tap((permissions: Permission[]) => this.permissions$.next(permissions))
      );
  }

  getMemberCommissionFactor(id: number): Observable<number> {
    return super.list().pipe(
      map((members: Member[]) => members.find(value => value.id === id)),
      map((member: Member) =>
        member.commissionLevel
          ? (member.commissionLevel as CommissionLevel).commission
          : null
      ),
      tap(factor => this.memberCommissionFactor$.next(factor))
    );
  }

  inviteMember(inviteUser: InviteUser) {
    return this.http.post(`invitations-users`, inviteUser);
  }

  openSidenav() {
    this.sidenavOpened$.next(true);
  }

  closeSidenav() {
    this.sidenavOpened$.next(false);
  }

  /**
   * Get current user descendants
   */
  get descendants$() {
    const members$ = this.list();
    const organizationMember$ = this.organizationService.organizationMember$;

    return combineLatest([members$, organizationMember$]).pipe(
      filter(
        ([_members, _organizationMember]) => !!_members && !!_organizationMember
      ),
      map(([_members, _organizationMember]) => {
        const _member = _members.find(
          value => value.id === _organizationMember.member
        );
        return [_members, _member];
      }),
      map(([_members, _member]: [Member[], Member]) => {
        if (this.isMemberAdmin(_member)) {
          return _members;
        }
        return this.getMemberDescendants(_members, _member);
      })
    );
  }

  /**
   * Get descendants for a specific member
   */
  getDescendants(memberId: number): Observable<Member[]> {
    return this.list().pipe(
      take(1),
      map(members => {
        const member = this.values$.value.find(value => value.id === memberId);
        if (this.isMemberAdmin(member)) {
          return members;
        }
        return this.getMemberDescendants(members, member);
      })
    );
  }

  isCurrentUserAdmin$(): Observable<boolean> {
    const members$ = this.list();
    const organizationMember$ = this.organizationService.organizationMember$;
    return combineLatest([members$, organizationMember$]).pipe(
      filter(
        ([_members, _organizationMember]) => !!_members && !!_organizationMember
      ),
      map(([_members, _organizationMember]) =>
        _members.find(value => value.id === _organizationMember.member)
      ),
      map((_member: Member) => {
        return !!(
          _member &&
          _member.groups.find(
            value => value.abbreviation === ADMINISTRATORS_GROUP
          )
        );
      })
    );
  }

  isCurrentUserAdmin() {
    const members = this.values$.value;
    if (!members) {
      return false;
    }
    const memberId = this.organizationService.organizationMember.member;
    const member = members.find(value => value.id === memberId);
    return this.isMemberAdmin(member);
  }

  filterMembers(
    members: Member[],
    search: string,
    viewAdmins: boolean = false
  ): Member[] {
    if (!search && viewAdmins) {
      return members.filter(
        member =>
          !!member.groups.find(
            value => value.abbreviation === ADMINISTRATORS_GROUP
          )
      );
    } else if (search && !viewAdmins) {
      return members.filter(
        member => member.name.toLowerCase().indexOf(search) > -1
      );
    } else if (search && viewAdmins) {
      return members.filter(
        member =>
          !!member.groups.find(
            value => value.abbreviation === ADMINISTRATORS_GROUP
          ) && member.name.toLowerCase().indexOf(search) > -1
      );
    }
    return members;
  }

  private isMemberAdmin(member: Member) {
    return (
      member &&
      !!member.groups.find(group => group.abbreviation === ADMINISTRATORS_GROUP)
    );
  }

  private getMemberDescendants(members: Member[], member: Member): Member[] {
    const descendants = members.filter(item => item.supervisor === member.id);
    /**
     * Stop Condition
     */
    if (descendants.length === 0) {
      return [member];
    }
    const values = [member];
    for (const descendant of descendants) {
      values.push(...this.getMemberDescendants(descendants, descendant));
    }
    return values;
  }
}
