import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SegmentJson } from '@aid/shared/types/interfaces';

@Component({
  selector: 'aid-json-viewer',
  template: `
    <section class="json-viewer">
      <section *ngFor="let segment of segments" [ngClass]="['segment']">
        <section
          (click)="toggle(segment)"
          class=" margin-bottom-8"
          [ngClass]="{
            'segment-main': true,
            expandable: isExpandable(segment),
            expanded: segment.expanded
          }"
        >
          <div
            *ngIf="isExpandable(segment) && !isElement(segment)"
            class="toggler"
          ></div>
          <span
            *ngIf="isExpandable(segment) && !isElement(segment)"
            class="mat-subtitle-2 basic-color-700"
            >{{ segment.key }}</span
          >
          <aid-info-display-data
            *ngIf="!isExpandable(segment)"
            [label]="segment.key"
            [value]="segment.description"
          ></aid-info-display-data>
        </section>
        <section
          *ngIf="segment.expanded && isExpandable(segment)"
          class="children"
        >
          <aid-json-viewer
            [json]="segment.value"
            [expanded]="expanded"
          ></aid-json-viewer>
        </section>
      </section>
    </section>
  `,
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent implements OnInit, OnChanges {
  @Input() json: any;
  @Input() expanded = true;
  @Input() cleanOnChange = true;

  segments: SegmentJson[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.cleanOnChange) {
      this.segments = [];
    }

    if (typeof this.json === 'object') {
      Object.keys(this.json).forEach(key => {
        this.segments.push(this.parseKeyValue(key, this.json[key]));
      });
    } else {
      this.segments.push(
        this.parseKeyValue(`(${typeof this.json})`, this.json)
      );
    }
  }

  isExpandable(segment: SegmentJson) {
    return segment.type === 'object' || segment.type === 'array';
  }

  toggle(segment: SegmentJson) {
    if (this.isExpandable(segment)) {
      segment.expanded = !segment.expanded;
    }
  }

  private parseKeyValue(key: any, value: any): SegmentJson {
    const segment: SegmentJson = {
      key,
      value,
      type: undefined,
      description: '' + value,
      expanded: this.expanded
    };

    switch (typeof segment.value) {
      case 'number': {
        segment.type = 'number';
        break;
      }
      case 'boolean': {
        segment.type = 'boolean';
        break;
      }
      case 'function': {
        segment.type = 'function';
        break;
      }
      case 'string': {
        segment.type = 'string';
        segment.description = segment.value;
        break;
      }
      case 'undefined': {
        segment.type = 'undefined';
        segment.description = 'undefined';
        break;
      }
      case 'object': {
        if (segment.value === null) {
          segment.type = 'null';
          segment.description = 'null';
        } else if (Array.isArray(segment.value)) {
          segment.type = 'array';
          segment.description = segment.value
            ? JSON.stringify(segment.value)
            : '-';
        } else if (segment.value instanceof Date) {
          segment.type = 'date';
        } else {
          segment.type = 'object';
          segment.description = '';
        }
        break;
      }
    }

    return segment;
  }

  isElement(segment: SegmentJson) {
    return typeof segment.key && +segment.key < 10;
  }
}
