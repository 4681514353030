import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UnredirectedRoutesService } from '@aid/core/services';
import { OrganizationService } from '@aid/core/services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class NotFoundInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private unredirectedRoutesService: UnredirectedRoutesService,
    private organizationService: OrganizationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        const notFoundRegistered = this.unredirectedRoutesService.isUrlRegistered(
          req.url
        );

        if (notFoundRegistered) {
          return of(error);
        }
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.router.navigate([
            this.organizationService.organization.slug,
            'not-found'
          ]);
        }
        return of(error);
      })
    );
  }
}
