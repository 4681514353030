import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { FileService } from '@aid/shared/services';
import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import {
  ChatSystemMessage,
  Message,
  MessageFile
} from '@aid/chat/types/classes';
import { Params } from '@angular/router';
import { SystemChannelService } from '@aid/core/services/system-channel.service';
import { SystemMessage, User } from '@aid/core/types/classes';
import { ProfilePicture } from '@aid/profile/types/classes';

export class ChatService extends CrudPaginationService<Message> {
  protected loadingSubmit = new BehaviorSubject<boolean>(null);
  protected empty = new BehaviorSubject<boolean>(null);

  constructor(
    protected http: HttpClient,
    protected fileUploadService: FileService<MessageFile>,
    protected systemChannelService: SystemChannelService
  ) {
    super(http, true);
  }

  get url(): string {
    return 'chats';
  }

  subscribeValues$(receiverId?: number) {
    const queryParams = this.getInitialQueryParams(receiverId);
    this.queryParams.next(queryParams);

    return super.subscribeValues$();
  }

  subscribeMessages$(
    receiverId: number,
    senderId: number
  ): Observable<Message> {
    return this.systemChannelService.subscribeChat(receiverId, senderId).pipe(
      map((systemMessages: ChatSystemMessage[]) => {
        const length = systemMessages.length;
        const systemMessage = systemMessages[length - 1];

        const message = this.createMessage(systemMessage);
        this.values.next([...this.values.value, message]);
        return message;
      })
    );
  }

  private createMessage(systemMessage: ChatSystemMessage): Message {
    const message = new Message();

    message.created = systemMessage.metadata.created;
    message.id = systemMessage.metadata.id;
    message.receiver = systemMessage.receiverId;

    message.sender = new User();
    message.sender.id = systemMessage.senderId;
    message.sender.profilePicture = new ProfilePicture();
    message.sender.profilePicture.url = systemMessage.metadata.sender.profile;
    message.sender.name = systemMessage.metadata.sender.name;

    if (systemMessage.metadata.message) {
      message.message = systemMessage.metadata.message;
      message.type = 'text';
    }

    if (!systemMessage.metadata.message && systemMessage.metadata.fileName) {
      message.file = new MessageFile();
      message.type = 'file';
      message.file.fileName = systemMessage.metadata.fileName;
      message.file.fileType = systemMessage.metadata.fileType;
      message.file.contract = systemMessage.metadata.contract;
    }
    return message;
  }

  refresh() {
    this.empty.next(null);
    super.refresh();
  }

  protected setValues(messages: Message[]) {
    const empty = messages.length === 0;
    if (this.empty.value === null) {
      this.empty.next(empty);
    }

    const values = this.values.value || [];
    this.values.next([...messages.reverse(), ...values]);
  }

  protected getInitialQueryParams(receiverId?: number): QueryParams {
    const queryParams = super.getInitialQueryParams();
    queryParams.receiver = receiverId;
    return queryParams;
  }

  get pageSize() {
    return this.getInitialQueryParams().pageSize;
  }

  add(value: Message, file?: File): Observable<Message> {
    this.loadingSubmit.next(true);
    return super.add(value).pipe(
      switchMap((_message: Message) => this.uploadMessageFile(_message, file)),
      tap(() => this.empty.next(false)),
      finalize(() => this.loadingSubmit.next(false))
    );
  }

  addContract(value: Message, file: File): Observable<Message> {
    this.loadingSubmit.next(true);
    return super.addRequest(value).pipe(
      switchMap((_message: Message) => this.uploadMessageFile(_message, file)),
      tap(() => this.empty.next(false)),
      finalize(() => this.loadingSubmit.next(false))
    );
  }
  getFile(messageId: number, receiver): Observable<MessageFile> {
    const url = `${this.url}/${messageId}/file`;
    const params: Params = {
      receiver
    };
    return this.http.get<MessageFile>(url, { params });
  }

  viewFile(message: Message, receiver) {
    const url = `${this.url}/${message.id}/signed-url`;
    const params: Params = {
      receiver
    };
    return this.http
      .get<string>(url, { params })
      .subscribe(value => window.open(value));
  }

  protected addValue(value: Message) {
    const values = this.values.value ? this.values.value : [];
    if (values.find(_value => _value.id === value.id)) {
      return;
    }
    values.push(value);
    this.values.next([...values]);
  }

  private uploadMessageFile(message: Message, file: File) {
    if (file && message.file) {
      return this.fileUploadService
        .fileUploadObject(message.file, file)
        .pipe(map(() => message));
    }
    return of(message);
  }

  get loadingSubmit$() {
    return this.loadingSubmit.asObservable();
  }

  get empty$() {
    return this.empty.asObservable();
  }
}
