/* eslint-disable */
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ToastModule } from './toast/toast.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

export function getCurrentLocale(): string {
  return localStorage.getItem(environment.localStorage.language) || 'en';
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    ToastModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, 'assets/i18n/', '-lang.json'),
        deps: [HttpClient]
      }
    }),
    HammerModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: getCurrentLocale()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
