import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { CustomersService } from '@aid/customers/services/customers.service';
import { OrganizationService } from '@aid/core/services/organization.service';
import { FileService } from '@aid/shared/services';
import { Observable } from 'rxjs';

@Injectable()
export class CustomerBrokerageContractService {
  constructor(
    private http: HttpClient,
    private customersService: CustomersService,
    private organizationService: OrganizationService,
    private fileUploadService: FileService<Blob>
  ) {}

  get url() {
    return `customers/${this.customersService.customerIdValue}`;
  }

  previewBrokerageContract(): Observable<File> {
    return this.http
      .get(`${this.url}/preview-contract`, {
        responseType: 'blob'
      })
      .pipe(
        map(
          response =>
            new File([response], 'contract_template.pdf', {
              type: 'application.pdf'
            })
        )
      );
  }

  generateBrokerageContract(): Observable<Blob> {
    return this.http
      .get(`${this.url}/generate-contract`, {
        responseType: 'blob'
      })
      .pipe(tap(value => this.downloadContractTemplate(value)));
  }

  downloadContractTemplate(pdf: Blob) {
    const blob = new Blob([pdf], { type: 'application/pdf' });

    const fileName = `${
      this.organizationService.organization.name
    }_brokerage_contract.pdf`;

    this.fileUploadService.download(blob, fileName);
  }
}
