import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { UserService } from '@aid/core/services';

@Directive({
  selector: '[aidHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input('aidHasPermission') checkPermissions: string[];

  constructor(
    private userService: UserService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {
    if (!this.checkPermissions) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

    if (
      this.checkPermissions &&
      this.userService.hasPermission(this.checkPermissions)
    ) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
