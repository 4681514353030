import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'aid-set-language',
  template: `
    <button mat-icon-button [matMenuTriggerFor]="languageMenu">
      <mat-icon svgIcon="{{ flag }}" data-cy="set-language"> </mat-icon>
    </button>

    <mat-menu #languageMenu="matMenu">
      <button mat-menu-item (click)="language.emit('en')">
        <mat-icon svgIcon="english_flag" data-cy="set-language-en"></mat-icon>
        English
      </button>
      <button mat-menu-item (click)="language.emit('de')">
        <mat-icon svgIcon="german_flag" data-cy="set-language-de"></mat-icon>
        German
      </button>
    </mat-menu>
  `,
  styleUrls: ['./set-language.component.scss']
})
export class SetLanguageComponent implements OnInit {
  @Output() language = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  get flag() {
    const language = localStorage.getItem(environment.localStorage.language);

    if (language === 'de') {
      return 'german_flag';
    }
    if (language === 'en') {
      return 'english_flag';
    }
  }
}
