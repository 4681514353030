import { Injectable } from '@angular/core';
import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { Reminder } from '../types/classes';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { RemindersFilter } from '@aid/reminders/types/classes';
import { Params } from '@angular/router';
import { PaginationResponse } from '@aid/shared/types/interfaces';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';

@Injectable({
  providedIn: 'root'
})
export class RemindersService extends CrudPaginationService<Reminder> {
  private reminder = new BehaviorSubject<Reminder>(null);
  private badge = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {
    super(http, true);
  }

  get url() {
    return 'reminders';
  }

  /**
   * Get the reminders for the current day
   */
  currentReminders(): Observable<Reminder[]> {
    const _filter = new RemindersFilter();
    _filter.startDate = moment().toDate();
    _filter.endDate = moment().toDate();
    const queryParams = _filter.queryParams;
    queryParams.pageSize = 10;
    queryParams.status = 'pending';

    return this.http
      .get<PaginationResponse<Reminder>>(this.url, {
        params: queryParams as Params
      })
      .pipe(map(response => response.results));
  }

  get reminder$() {
    return this.reminder.asObservable();
  }

  get reminderValue() {
    return this.reminder.value;
  }

  setReminder$(reminder: Reminder) {
    this.reminder.next(reminder);
  }

  edit(value): Observable<Reminder> {
    return this._http
      .patch<Reminder>(`${this.url}/${value.id}`, value)
      .pipe(tap(result => this.editValue(result)));
  }

  getInitialQueryParams() {
    const queryParams = new QueryParams();
    queryParams.pageSize = 20;
    return queryParams;
  }

  applyQueryParams(queryParams: QueryParams) {
    if (JSON.stringify(queryParams) !== JSON.stringify(this.queryParamsValue)) {
      this.changeParams(queryParams);
    }
  }

  getBadge$(): Observable<number> {
    return this.http.get<{ count: number }>(`${this.url}/today`).pipe(
      filter(value => !!value),
      tap((value: { count: number }) => this.badge.next(value.count)),
      switchMap(() => this.badge.asObservable())
    );
  }

  get badgeValue() {
    return this.badge.value;
  }
  setBadge(value: number) {
    this.badge.next(value);
  }
  incrementBadge() {
    const badge = this.badge.value + 1;
    this.badge.next(badge);
  }

  decrementBadge() {
    if (this.badgeValue === 0) {
      return;
    }
    const badge = this.badge.value - 1;
    this.badge.next(badge);
  }
}
