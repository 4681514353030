import { Observable } from 'rxjs';
import { Invitation, UserInvitation } from '../types/classes/invitation.class';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { InvitationFactory } from '@aid/auth/types/classes/invitation-factory.class';
import { InvitationType } from '@aid/auth/types/enums/invitation-type.enum';

@Injectable()
export class InvitationService {
  constructor(private http: HttpClient) {}

  verifyInviteCode(
    code: string,
    type = InvitationType.VERIFICATION
  ): Observable<Invitation> {
    let observable: Observable<Invitation>;
    if (type === 'user') {
      observable = this.http.get<UserInvitation>(
        `invitations-users/${code}?code=true`
      );
    } else {
      observable = this.http.get<Invitation>(`invitations/${code}?code=true`);
    }
    return observable.pipe(
      map(invitation => InvitationFactory.getInstance(type, invitation))
    );
  }
}
