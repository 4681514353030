import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'aid-decision-radio-buttons',
  template: `
    <div fxLayout="column" fxLayoutGap="16px">
      <mat-label *ngIf="label">
        {{ (label | translate | sentancecase) + '*' }}
      </mat-label>
      <mat-radio-group [formControl]="control" fxLayoutGap="16px">
        <mat-radio-button
          *ngFor="let option of options"
          [value]="option"
          data-cy="decision-radio-button"
        >
          {{ getOption(option) | translate | sentancecase }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  styleUrls: ['./decision-radio-buttons.component.scss']
})
export class DecisionRadioButtonsComponent implements OnInit {
  @Input() label: string;
  @Input() control: UntypedFormControl;

  options: boolean[] = [true, false];

  constructor() {}

  ngOnInit() {}

  getOption(option) {
    return option ? 'yes' : 'no';
  }
}
