import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBar as MatSnackBar
} from '@angular/material/legacy-snack-bar';
import { SnackBar } from '@aid/shared/types/classes/snack-bar.class';

@Component({
  selector: 'aid-snack-bar',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="16px"
        class="full-height full-width"
      >
        <div
          class="snackbar__icon-container"
          [ngClass]="'snackbar__icon-container--' + data.type"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-icon
            class="snackbar__icon-container__size"
            [svgIcon]="typeIconMap[data.type]"
          ></mat-icon>
        </div>
        <div
          fxLayout="column"
          fxLayoutGap="8px"
          class="snackbar__icon-container__text"
        >
          <div class="mat-subtitle-2">
            {{ data.type | translate | uppercase }}
          </div>
          <div class="mat-subtitle-2">
            {{ data.message | translate: data.translationData }}
          </div>
        </div>
      </div>
      <button
        mat-icon-button
        class="basic-light-color-600 snackbar__icon-container"
        (click)="onClose()"
      >
        <mat-icon class="snackbar__close" svgIcon="close"></mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  typeIconMap = {
    success: 'check',
    information: 'information',
    error: 'close'
  };

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: SnackBar
  ) {}

  ngOnInit() {}

  onClose() {
    this.snackBar.dismiss();
  }
}
