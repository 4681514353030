import { OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';

export class ToastRef {
  private object = new BehaviorSubject<any>(null);

  constructor(private overlay: OverlayRef) {}

  close() {
    this.overlay.dispose();
  }

  click(data) {
    this.object.next(data);
    this.close();
  }

  getPosition() {
    return this.overlay.overlayElement.getBoundingClientRect();
  }

  isVisible() {
    return this.overlay && this.overlay.overlayElement;
  }

  afterClicked() {
    return this.object.asObservable();
  }
}
