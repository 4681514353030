import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'aid-info-display-data',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between" class="data">
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <span class="data__label mat-subtitle-2">
          {{ label | translate | sentancecase }}
        </span>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <span *ngIf="!translate" class="data__value mat-subtitle-2">
          {{ value || '-' }}
        </span>
        <span *ngIf="translate" class="data__value mat-subtitle-2">
          {{ (value | translate) || '-' }}
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./info-display-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDisplayDataComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() translate: boolean;
  constructor() {}

  ngOnInit() {}
}
