import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import { ResourceSerializer } from '@aid/shared/types/interfaces/resource-serializer.interface';
import { AuthService } from '@aid/auth/services/auth.service';

export abstract class CrudService<T extends { id?: number }> {
  values$: BehaviorSubject<T[]> = new BehaviorSubject(null);
  refresh = false;
  private loading = new BehaviorSubject<boolean>(null);

  private currentUserToken: string;

  protected constructor(protected _http: HttpClient, protected cache = true) {
    this.currentUserToken = localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);
  }

  abstract get url(): string;
  protected serializer: ResourceSerializer<T>;

  get loading$(): Observable<boolean> {
    return this.loading.asObservable();
  }

  list(params?: Params): Observable<T[]> {
    if (this.returnCacheData()) {
      return this.values$.asObservable();
    }
    this.loading.next(true);

    return this.listRequest(params).pipe(
      switchMap((values: T[]) => {
        this.values$.next(this.serializer ? this.convertData(values) : values);
        this.refresh = false;
        return this.values$.asObservable();
      }),
      tap(() => this.afterList()),
      tap(() => this.loading.next(false))
    );
  }

  get(valueId: number, force?: boolean): Observable<T> {
    if (force) {
      return this.getResource(valueId);
    }
    const value = this.values$.value
      ? this.values$.value.find(item => item.id === valueId)
      : null;
    if (!value) {
      return this.getResource(valueId);
    }
    return of(value);
  }

  add(value: T): Observable<T> {
    return this._http
      .post<T>(
        `${this.url}`,
        this.serializer ? this.serializer.toJson(value) : value
      )
      .pipe(tap((_value: T) => this.addValue(_value)));
  }

  edit(value: T): Observable<T> {
    return this._http
      .patch(
        `${this.url}/${value.id}`,
        this.serializer ? this.serializer.toJson(value) : value
      )
      .pipe(tap((_value: T) => this.editValue(value.id, _value)));
  }

  delete(valueId: number | string) {
    return this._http
      .delete(`${this.url}/${valueId}`)
      .pipe(tap(() => this.deleteValue(valueId)));
  }

  protected returnCacheData() {
    return !this.refresh && this.values$.value && this.cache && !this.toRefresh;
  }

  protected listRequest(params: Params): Observable<T[]> {
    return this._http.get<T[]>(`${this.url}`, {
      params: params ? params : undefined
    });
  }

  protected getResource(id: number | string): Observable<T> {
    return this._http
      .get<T>(`${this.url}/${id}`)
      .pipe(
        map(data =>
          this.serializer ? (this.serializer.fromJson(data) as T) : data
        )
      );
  }

  /**
   * This method is called after we make the list() call
   * By default we do nothing
   */
  protected afterList() {
    return;
  }

  protected deleteValue(valueId: number | string) {
    if (!this.values$.value) {
      return;
    }
    const values: T[] = this.values$.value.filter(
      value => value.id !== valueId
    );
    this.values$.next(values);
  }

  protected addValue(_value: T) {
    const currentValues = this.values$.value ? this.values$.value : [];
    const values: T[] = [
      ...currentValues,
      this.serializer ? (this.serializer.fromJson(_value) as T) : _value
    ];
    this.values$.next(values);
    return _value;
  }

  protected editValue(id: number, _value: T) {
    const values: T[] = this.values$.value ? [...this.values$.value] : [];
    const valueIndex: number = values.findIndex((item: T) => item.id === id);
    values[valueIndex] = _value;
    this.values$.next(values);
    return _value;
  }

  onRefresh() {
    this.values$ = new BehaviorSubject<T[]>(null);
    this.refresh = true;
  }

  protected convertData(data: any): T[] {
    return data.map(item => this.serializer.fromJson(item));
  }

  private get toRefresh() {
    const currentToken = localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);
    const refresh = currentToken !== this.currentUserToken;
    this.currentUserToken = currentToken;
    return refresh;
  }
}
