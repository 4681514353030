import { Injectable } from '@angular/core';
import { Insurance, InsuranceMode } from '@aid/insurances/types/classes';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Damage } from '@aid/damages/types/classes';
import { Email } from '@aid/insurances/types/classes/email.class';
import { CrudService } from '@aid/shared/services';
import { Params } from '@angular/router';
import { InsuranceType } from '@aid/administration/types/classes';

@Injectable({
  providedIn: 'root'
})
export class InsurancesService extends CrudService<Insurance> {
  private insurance = new BehaviorSubject<Insurance>(null);
  private insuranceId = new BehaviorSubject<number>(null);
  private customerId: number;

  constructor(protected http: HttpClient) {
    super(http, false);
  }

  get url(): string {
    return `customers/${this.customerId}/insurances`;
  }

  get insuranceUrl(): string {
    return `customers/${this.customerId}/insurances/${this.getInsuranceId()}`;
  }

  list(params?: Params): Observable<Insurance[]> {
    return super
      .list(params)
      .pipe(
        tap(values =>
          values.sort((a, b) =>
            (a.insuranceType as InsuranceType).name >
            (b.insuranceType as InsuranceType).name
              ? 1
              : -1
          )
        )
      );
  }

  setCustomer(customerId: number) {
    this.customerId = customerId;
  }

  getCustomerId() {
    return this.customerId;
  }

  add(
    insurance: Insurance,
    policy?: File,
    application?: File
  ): Observable<Insurance> {
    return super.add(insurance).pipe(
      map(_insurance =>
        this.createFileObjects(_insurance, policy, application)
      ),
      switchMap(value =>
        this.uploadFiles(value.insurance, value.files).pipe(
          map(() => value.insurance)
        )
      )
    );
  }

  edit(
    insurance: Insurance,
    policy?: File,
    application?: File
  ): Observable<Insurance> {
    return super.edit(insurance).pipe(
      map(_insurance =>
        this.createFileObjects(_insurance, policy, application)
      ),
      switchMap(value =>
        this.uploadFiles(value.insurance, value.files).pipe(
          map(() => value.insurance)
        )
      )
    );
  }

  private createFileObjects(
    insurance: Insurance,
    policy?: File,
    application?: File
  ) {
    if (!policy && !application) {
      return { insurance, files: null };
    }

    const files: { [id: string]: File } = {};
    if (policy) {
      files.policy = policy;
    }

    if (application) {
      files.application = application;
    }

    return { insurance, files };
  }

  getBasic(valueId: number): Observable<Insurance> {
    return this.http.get<Insurance>(`${this.url}/${valueId}/basic`);
  }

  get(valueId: number): Observable<Insurance> {
    if (this.insurance.value && this.insurance.value.id === valueId) {
      return this.insurance.asObservable();
    }

    return super
      .get(valueId, true)
      .pipe(tap((insurance: Insurance) => this.insurance.next(insurance)));
  }

  setInsuranceId(insuranceId: number) {
    return this.insuranceId.next(insuranceId);
  }

  getInsuranceId$() {
    return this.insuranceId.asObservable();
  }

  getInsuranceId() {
    return this.insuranceId.value;
  }

  getInsurance$(): Observable<Insurance> {
    return this.insurance.asObservable();
  }

  getInsurance(): Insurance {
    return this.insurance.getValue();
  }

  setInsurance$(insurance: Insurance) {
    this.insurance.next(insurance);
  }

  viewFile(insurance: Insurance, type: string) {
    const url = `${this.url}/${insurance.id}/${type}`;
    return this.http.get<string>(url).subscribe(value => {
      window.open(value);
    });
  }

  uploadFiles(insurance: Insurance, files: { [id: string]: File }) {
    if (!files) {
      return of(null);
    }

    const observables = Object.entries(files).map((value: any) =>
      this.uploadFile(value[1], insurance[value[0]].uploadSignedUrl)
    );

    return observables.length === 0 ? of(null) : forkJoin(observables);
  }

  uploadFile(file: File, url: string) {
    return this.http.put(url, file);
  }

  /***
   *  Get insurance without specifying the customer
   *  */

  getInsuranceDetails(insuranceId: number) {
    return this.http.get(`insurances/${insuranceId}`);
  }

  getInsuranceDamages(insuranceId: number): Observable<Damage[]> {
    return this.http.get<Damage[]>(`${this.url}/${insuranceId}/damages`);
  }

  getEmailTemplate(insuranceId: number) {
    return this.http.get<string>(`${this.url}/${insuranceId}/email-template`);
  }

  sendEmail(email: Email) {
    return this.http.post('smtp/send', email);
  }

  getAvailableModes(insuranceId: number) {
    return this.http.get<InsuranceMode[]>(`${this.url}/${insuranceId}/modes`);
  }

  mrMoney(insurance: Insurance) {
    return this.http
      .get(`${this.url}/${insurance.id}/mrmoney`, { responseType: 'text' })
      .pipe(tap(url => open(url, '_blank')));
  }
}
