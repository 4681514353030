import { Injectable } from '@angular/core';
import {
  Partnership,
  PartnershipRequest
} from '@aid/partnerships/types/classes';
import { HttpClient } from '@angular/common/http';
import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { Observable, of } from 'rxjs';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import { Params } from '@angular/router';

@Injectable()
export class PartnershipRequestsService extends CrudPaginationService<
  PartnershipRequest
> {
  constructor(private http: HttpClient) {
    super(http);
  }

  get url(): string {
    return 'partnership-requests';
  }

  accept(
    request: PartnershipRequest,
    data: Partnership
  ): Observable<PartnershipRequest> {
    return this.http.post<PartnershipRequest>(
      `${this.url}/${request.id}/accept`,
      data
    );
  }

  decline(request: PartnershipRequest): Observable<PartnershipRequest> {
    return this.http.post<PartnershipRequest>(
      `${this.url}/${request.id}/decline`,
      {}
    );
  }

  acceptAll(partnership: Partnership, request: PartnershipRequest) {
    const params = { product_partner: request.productPartner } as Params;
    const body = {
      responsible: partnership.responsible,
      number: partnership.number
    };
    return this.http.post(`${this.url}/${request.id}/accept-all`, body, {
      params
    });
  }

  getInitialQueryParams() {
    const queryParams = new QueryParams();
    queryParams.pageSize = 15;
    return queryParams;
  }
}
