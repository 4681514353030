import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { OrganizationInterceptor } from './interceptors/organization.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';

import * as fromComponents from './components';
import * as fromEntryComponents from './components';
import * as fromContainers from './containers';
import * as fromService from './services';
import * as fromGuards from './guards';
import { ApiResponseInterceptor } from '@aid/core/interceptors/api-response.interceptor';
import { NotFoundInterceptor } from '@aid/core/interceptors/not-found.interceptor';
import { NotificationsModule } from '@aid/notifications/notifications.module';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { HelpscoutDialogComponent } from './components';

@NgModule({
  declarations: [...fromComponents.components, ...fromContainers.containers],
  imports: [SharedModule, NotificationsModule.forRoot()],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000 }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotFoundInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: fromService.CustomMatPaginatorIntl
    },
    ...fromService.services,
    ...fromGuards.guards
  ],
  exports: [HttpClientModule]
})
export class CoreModule {}
