import { InsuranceType } from '@aid/administration/types/classes/insurance-type.class';
import { Provider } from '@aid/administration/types/classes/provider.class';
import { ProductPartner } from '@aid/partnerships/types/classes/product-partner.class';
import { GcsFile } from '@aid/shared/types/classes';
import { Customer } from '@aid/customers/types/classes';
import { PaymentMethod } from '@aid/insurances/types/classes/payment-method.class';
import { InsuranceMode } from '@aid/insurances/types/classes/insurance-mode.class';

export type Insurance = {
  id: number;
  broker: number;
  customer: number | Customer;
  provider: Provider | number;
  insuranceType: InsuranceType | number;
  productPartner?: ProductPartner | number;

  mode: InsuranceMode | number;
  insuranceNumber: string;
  paymentMethod: number | PaymentMethod;
  price: number;
  startDate?: string;
  endDate?: string;
  missingInsurance?: number;
  applicationNumber: string;
  risk: string;

  /**
   * If the insurance is active or not
   */
  active?: boolean;
  /**
   * If the user uploads a file
   */
  policy: GcsFile;
  application: GcsFile;
} & (
  | {}
  | {
      /** insuranceType.id === 3 */
      insuranceSum?: number;
      postcode?: string;
      city?: string;
    }
  | {
      /** insuranceType.id === 8 */
      livingArea?: number;
      elemDamages: boolean;
      postcode?: string;
      city?: string;
      street?: string;
    }
  | {
      /** insuranceType.id === 10 */
      tariffPrivate: boolean;
      tariffProfessional: boolean;
      tariffTrafficFamily: boolean;
      tariffRent: boolean;
      tariffTraffic: boolean;
      tariffLandlord: boolean;
      family?: FamilyType;
      agePartner?: number;
      postcode?: string;
    }
  | {
      /** insuranceType.id === 11 */
      buildingType: BuildingType;
      constructionType: ConstructionType;
      yearOfConstruction?: number;
      yearOfLastCoreRenovation?: number;
      roofType: RoofType;
      units?: number;
      garages?: number;
      carports?: number;
      outbuildings?: number;
      groundType: BuildingValueType;
      value?: number;
      elemDamages: boolean;
      postcode?: string;
      city?: string;
      street?: string;
    }
);

export const buildingTypes = [
  'apartmentHouse',
  'singleFamilyHouse',
  'twoFamilyHouse',
  'duplexHouseHalf',
  'rowHouse'
] as const;
export const constructionTypes = [
  'BAK1',
  'BAK2',
  'BAK3',
  'BAK5',
  'FHG1',
  'FHG2',
  'FHG3'
] as const;
export const roofTypes = ['gableRoof', 'flatRoof', 'otherRoof'] as const;
export const buildingValueTypes = ['1914', '2022'] as const;
export const familyTypes = [
  'family',
  'single',
  'couple',
  'singleParent'
] as const;
export type BuildingType = typeof buildingTypes[number];
export type ConstructionType = typeof constructionTypes[number];
export type RoofType = typeof roofTypes[number];
export type BuildingValueType = typeof buildingValueTypes[number];
export type FamilyType = typeof familyTypes[number];

export type PersonalLiabilityInsurance = Extract<
  Insurance,
  { insuranceSum?: number }
>;
export type HouseholdContentsInsurance = Extract<
  Insurance,
  { livingArea?: number }
>;
export type LegalLiabilityInsurance = Extract<
  Insurance,
  { tariffPrivate: boolean }
>;
export type ResidenceInsurance = Extract<
  Insurance,
  { buildingType: BuildingType }
>;
