import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'aid-card-item',
  template: `
    <div fxLayout="row" fxLayoutAlign="start center">
      <div
        [ngClass]="{
          item__disabled: disabled
        }"
        class="item__element item__element--border full-width padding-vertical-16"
        fxLayout="row"
        fxLayoutAlign="start center"
        (click)="onSelect()"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          class="mat-subtitle-2 item__element--color"
          [ngClass]="{
            'item__element--color': !selected
          }"
        >
          <mat-icon
            *ngIf="icon"
            [svgIcon]="icon"
            [ngClass]="{ 'item__disabled--color': disabled }"
          >
          </mat-icon>
          <span [ngClass]="{ 'item__disabled--color': disabled }">
            {{ name | translate }}
          </span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./card-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardItemComponent implements OnInit {
  @Input() name: string;
  @Input() icon: string;
  @Input() selected = false;
  @Input() disabled;

  @Output() select = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onSelect() {
    if (this.disabled) {
      return;
    }
    this.select.emit();
  }
}
