import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GcsFile } from '@aid/shared/types/classes';

@Component({
  selector: 'aid-upload-multiple-files',
  template: `
    <div fxLayout="row wrap">
      <ng-container *ngIf="files.length > 0">
        <ng-container *ngFor="let file of files">
          <div
            fxLayout="column"
            fxLayoutAlign="space-between center"
            class="border border-radius-8 pointer damage__attach-file"
            (click)="onViewFile(file)"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="full-width full-height"
            >
              <mat-icon
                [svgIcon]="typeIconMap[file.fileType]"
                class="damage__type-icon"
              ></mat-icon>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="16px"
              class="damage--border-top padding-8 full-width"
            >
              <span class="mat-small damage--break-word">
                {{ file.fileName }}
              </span>
              <button
                *ngIf="!disabled"
                type="button"
                mat-icon-button
                class="damage__delete"
                (click)="onDelete(file); $event.stopPropagation()"
              >
                <mat-icon
                  svgIcon="delete"
                  class="damage__delete--icon"
                ></mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="disabled && files.length === 0">
        <span class="mat-small">{{ 'no-files-attached' | translate }}</span>
      </ng-container>
      <div
        *ngIf="viewAdd"
        fxLayout="column"
        fxLayoutAlign="center center"
        class="border-dashed border-radius-8 pointer damage__attach-file"
        (click)="onFile()"
        (drop)="onAddFileByDropping($event)"
      >
        <mat-icon
          svgIcon="add"
          color="primary"
          class="damage__type-icon"
        ></mat-icon>
      </div>
    </div>

    <mat-error *ngIf="control.hasError('required')">
      {{ 'upload-file-required' | translate | sentancecase }}
    </mat-error>

    <input
      type="file"
      #file
      style="display: none"
      (change)="onAddFileBySelecting($event)"
      [accept]="acceptFiles"
      multiple
    />
  `,
  styleUrls: ['./upload-multiple-files.component.scss']
})
export class UploadMultipleFilesComponent implements OnInit {
  @Input('control') set _control(control: UntypedFormControl) {
    this.control = control;
    if (this.control.value) {
      this.files = this.control.value;
    }
  }
  @Input() acceptFiles = '.jpg, .jpeg, .png';
  @Input() disabled = false;
  @Input() singleFile = false;

  @Output() viewFile = new EventEmitter<GcsFile>();

  files: GcsFile[] = [];
  localLinks: { [id: string]: string } = {};

  @ViewChild('file') file;

  typeIconMap = {
    'application/pdf': 'pdf_file',
    'image/jpeg': 'jpg_file',
    'image/jpg': 'jpg_file',
    'image/png': 'png_file'
  };
  control: UntypedFormControl = new UntypedFormControl();

  constructor() {}

  ngOnInit() {
    this.control.valueChanges.subscribe(files => {
      if (files.length > 0) {
        this.control.markAsDirty();
      }

      this.files = files ? files : [];
    });
  }

  onFile() {
    this.file.nativeElement.click();
  }

  onAddFileByDropping(event) {
    const filesOfCorrectType = [];

    const arrayOfAllowedTypes = this.acceptFiles.replace(/\s/g, '').split(',');

    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      const file = event.dataTransfer.files[i];
      if (arrayOfAllowedTypes.some(fileType => file.name.endsWith(fileType))) {
        filesOfCorrectType.push(file);
      }
    }

    if (filesOfCorrectType.length > 0) {
      this.processFiles(filesOfCorrectType);
    }
  }

  onAddFileBySelecting(event) {
    this.processFiles(event.target.files);
  }

  processFiles(filesList) {
    Array.from(filesList).map((value: File) => {
      const file = new GcsFile();
      file.fileName = value.name;
      file.fileType = value.type;
      file.file = value;
      this.files.push(file);

      this.localLinks[`${value.name}-${value.type}`] = URL.createObjectURL(
        value
      );
    });

    const files = [...this.files];
    this.control.patchValue(files);
    this.file.nativeElement.value = '';
  }

  onViewFile(value: GcsFile) {
    if (!value.id && value.file) {
      window.open(this.localLinks[`${value.fileName}-${value.fileType}`]);
      return;
    }
    this.viewFile.emit(value);
  }

  onDelete(file: GcsFile) {
    const index = this.files.findIndex(
      value =>
        value.fileType === file.fileType && value.fileName === file.fileName
    );

    const files = [...this.files];
    files.splice(index, 1);
    this.control.patchValue(files);
  }

  get viewAdd() {
    if (this.disabled) {
      return false;
    }

    return !(this.singleFile && this.files.length === 1);
  }
}
