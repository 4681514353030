import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '@aid/auth/services';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '@aid/core/services';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  private readonly baseUrl = environment.api;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.accessToken;
    let apiReq = request;

    if (!this.shouldAddBaseUrl(apiReq.url)) {
      return next.handle(request);
    }

    apiReq = request.clone({ url: `${this.baseUrl}/${request.url}` });
    apiReq = this.setLanguageHeader(apiReq);

    if (this.shouldRefreshtoken(token, apiReq.url)) {
      return this.handleRefreshToken(apiReq, next);
    } else if (this.authService.accessToken) {
      apiReq = this.setAccessToken(apiReq);
    }

    return next.handle(apiReq);
  }

  private shouldAddBaseUrl(url: string) {
    return !/^https?:\/\/(.*)/.test(url) && !/^assets\/(.*)/.test(url);
  }

  private setAccessToken(apiReq: HttpRequest<any>) {
    const accessToken: string = this.authService.accessToken;

    if (accessToken) {
      apiReq = apiReq.clone({
        headers: apiReq.headers.set('Authorization', `Bearer ${accessToken}`)
      });
    }

    return apiReq;
  }

  private setLanguageHeader(apiReq: HttpRequest<any>) {
    const languageHeader: string = localStorage.getItem('aid_language');
    if (languageHeader) {
      apiReq = apiReq.clone({
        headers: apiReq.headers.set('Accept-Language', languageHeader)
      });
    }
    return apiReq;
  }

  private handleRefreshToken(apiReq: HttpRequest<any>, next: HttpHandler) {
    return this.authService.onRefreshToken().pipe(
      switchMap(() => {
        apiReq = this.setAccessToken(apiReq);
        return next.handle(apiReq);
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.userService.logOut();
          this.router.navigate(['/auth'], {
            queryParams: {
              redirectTo: document.location.pathname
            }
          });
        }

        return EMPTY;
      })
    );
  }

  private shouldRefreshtoken(token: string, url: string) {
    return (
      token &&
      this.authService.isTokenExpired(token) &&
      this.authService.refreshToken &&
      !/^(.*)refresh-token/.test(url)
    );
  }
}
