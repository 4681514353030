import * as jwtDecode from 'jwt-decode';

export class JwtService {
  constructor() {}

  isTokenExpired(token: string): boolean {
    const decoded = jwtDecode(token);
    return Date.now() / 1000 - decoded.exp > 0;
  }
}
