import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aid-subscription-suspended',
  template: `
    <div
      class="full-height full-width"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="16px"
    >
      <img
        src="assets/background/warning.png"
        alt="no"
        class="subscription--image"
      />
      <div class="padding-8 mat-subtitle-2 basic-color-900">
        {{ 'subscriptions.suspended' | translate }}
      </div>
      <div class="padding-8 mat-subtitle-2 basic-color-900">
        {{ 'You can find more information in the Billing page.' | translate }}
      </div>
    </div>
  `,
  styleUrls: ['./subscription-suspended.component.scss']
})
export class SubscriptionSuspendedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
