import { CustomerCommentsService } from '@aid/customers/services/customer-comments.service';
import { CustomerBankAccountService } from '@aid/customers/services/customer-bank-account.service';
import { CustomerChatService } from '@aid/customers/services/customer-chat.service';
import { CustomerContractService } from '@aid/customers/services/customer-contract.service';
import { CustomersFilterService } from '@aid/customers/services/customers-filter.service';
import { CustomerBrokerageContractService } from '@aid/customers/services/customer-brokerage-contract.service';

export const services = [
  CustomerCommentsService,
  CustomerBankAccountService,
  CustomerChatService,
  CustomerContractService,
  CustomersFilterService,
  CustomerBrokerageContractService
];

export * from './customers.service';
export * from './customer-comments.service';
export * from './customer-bank-account.service';
export * from './customer-chat.service';
export * from './customer-contract.service';
export * from './customer-legal-forms.service';
