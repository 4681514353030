import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { FeaturesService } from '@aid/core/services/features.service';
import { OrganizationService } from '@aid/core/services/organization.service';
import { Features } from '@aid/core/types/enums';

@Injectable({
  providedIn: 'root'
})
export class ReportsFeatureGuard implements CanActivate {
  constructor(
    private featuresService: FeaturesService,
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.featuresService.isFeatureAvailable(Features.REPORTS)) {
      return true;
    }

    this.router.navigate([this.organizationService.organization.slug]);
    return false;
  }
}
