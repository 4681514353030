import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  constructor() {}

  downloadEncodedBase64(
    encodedFile: string,
    name: string,
    mediaType = 'application/pdf'
  ) {
    const linkSource = `data:${mediaType};base64,${encodedFile}`;
    const promise = fetch(linkSource).then(response => response.blob());

    return from(promise).pipe(
      map(blob => {
        const url = window.URL.createObjectURL(blob);
        const aTag = document.createElement('a');
        document.body.appendChild(aTag);
        aTag.setAttribute('style', 'display: none');
        aTag.href = url;
        aTag.download = name;
        aTag.click();

        window.URL.revokeObjectURL(url);
        aTag.remove();
      })
    );
  }
}
