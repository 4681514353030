import { Injectable } from '@angular/core';
import { OrganizationService } from '@aid/core/services/organization.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { OrganizationResourceService } from '@aid/shared/services/organization-resource.service';
import { Params } from '@angular/router';
import { PartnershipProductPartner } from '@aid/partnerships/types/classes/partnership-product-partner.class';

@Injectable({
  providedIn: 'root'
})
export class OrganizationProductPartnersService extends OrganizationResourceService {
  private values$ = new BehaviorSubject<PartnershipProductPartner[]>(null);

  constructor(
    protected http: HttpClient,
    protected organizationService: OrganizationService
  ) {
    super(http, organizationService);
  }

  get resource() {
    return `product-partners`;
  }

  list(): Observable<PartnershipProductPartner[]> {
    if (this.values$.value && !this.refresh) {
      return this.values$.asObservable();
    }
    return this.http.get<PartnershipProductPartner[]>(this.url).pipe(
      switchMap((values: PartnershipProductPartner[]) => {
        this.values$.next(values);
        this.clearRefresh();
        return this.values$;
      })
    );
  }

  delete(valueId: number) {
    return this.http.delete(`${this.url}/${valueId}`).pipe(
      tap(() => {
        const values: PartnershipProductPartner[] = this.values$.value.filter(
          value => value.id !== valueId
        );
        this.values$.next(values);
      })
    );
  }

  getByProvider(provider: number): Observable<PartnershipProductPartner[]> {
    const params: Params = { provider };
    return this.http.get<PartnershipProductPartner[]>(`${this.url}`, {
      params
    });
  }

  getByProviderAndInsuranceType(
    provider: number,
    insuranceType: number
  ): Observable<PartnershipProductPartner[]> {
    const params: Params = {
      provider,
      insurance_type: insuranceType
    };
    return this.http.get<PartnershipProductPartner[]>(`${this.url}`, {
      params
    });
  }

  getPartnershipId(
    productPartnerId: number
  ): Observable<PartnershipProductPartner> {
    return this.list().pipe(
      map(productPartners =>
        productPartners.find(value => value.id === productPartnerId)
      )
    );
  }
}
