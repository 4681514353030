import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InsuranceRequest } from '@aid/insurances/types/classes/insurance-request.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudService } from '@aid/shared/services';
import { InsurancesService } from '@aid/insurances/services/insurances.service';
import { Insurance } from '@aid/insurances/types/classes';

@Injectable()
export class InsuranceRequestsService extends CrudService<InsuranceRequest> {
  private insuranceRequest = new BehaviorSubject<InsuranceRequest>(null);

  constructor(
    protected http: HttpClient,
    private insurancesService: InsurancesService
  ) {
    super(http, false);
  }

  get url(): string {
    const customerId = this.insurancesService.getCustomerId();
    return `customers/${customerId}/missing-insurances`;
  }

  setInsuranceRequests(insuranceRequest: InsuranceRequest) {
    this.insuranceRequest.next(insuranceRequest);
  }

  deleteInsuranceRequest() {
    super.deleteValue(this.insuranceRequestValue.id);
  }

  get insuranceType() {
    return this.insuranceRequest
      ? this.insuranceRequest.value.insuranceType
      : null;
  }

  get insuranceRequestValue() {
    return this.insuranceRequest.value;
  }

  getInsuranceFromRequest(): Partial<Insurance> {
    if (!this.insuranceRequestValue) {
      return null;
    }
    const insurance: Partial<Insurance> = {};
    insurance.missingInsurance = this.insuranceRequestValue.id;
    insurance.insuranceType = this.insuranceRequestValue.insuranceType;
    insurance.policy = this.insuranceRequestValue.insuranceFile;

    return insurance;
  }

  viewFile(insuranceId: number): Observable<string> {
    return this.http.get<string>(`${this.url}/${insuranceId}/file`);
  }
}
