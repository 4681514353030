import { OrganizationProductPartnersService } from './organization-product-partners.service';
import { ProductPartnersService } from './product-partners.service';
import { PartnershipRequestsService } from './partnership-requests.service';
import { DistributorsService } from '@aid/partnerships/services/distributors.service';

export const services = [
  OrganizationProductPartnersService,
  DistributorsService,
  ProductPartnersService,
  PartnershipRequestsService
];

export * from './organization-product-partners.service';
export * from './product-partners.service';
export * from './partnership-requests.service';
export * from './distributors.service';
export * from './partnerships.service';
