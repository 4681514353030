import { OrganizationMember } from '@aid/core/types/classes/organization-member.class';
import { ProfilePicture, Signature } from '@aid/profile/types/classes';

export class User {
  id?: number;
  firstName: string;
  lastName: string;
  name: string;
  phoneNumber: string;
  username: string;
  email: string;
  memberOf: OrganizationMember[];
  isStaff: boolean;
  city?: string;
  country?: string;
  postalCode?: string;
  street?: string;
  houseNumber?: string;
  secondAddress?: string;
  profilePicture?: ProfilePicture;
  signature: number;
  emailNotification: boolean;
  mrMoneyToken: string;
  mrMoneyAgentId: string;

  constructor(user?: User) {
    Object.assign(this, user);
  }

  get defaultOrganization() {
    return this.memberOf.find(organization => organization.default);
  }
}
