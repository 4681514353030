import { Organization, User } from '@aid/core/types/classes';
import { GcsFile } from '@aid/shared/types/classes';

export class InsuranceLog {
  id: number;
  message: string;
  text?: string;
  created: string | Date;
  type: string;
  description: string;
  insurance: number;
  user: User;
  customerVisible: boolean;
  organization?: Organization;
  readonly title?: string;
  file: GcsFile;
}
