import { MainLayoutComponent } from './main-layout/main-layout.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { NoOrganizationComponent } from './no-organization/no-organization.component';
import { NotFoundPageComponent } from '@aid/core/containers/not-found-page/not-found-page.component';
import { SubscriptionSuspendedComponent } from '@aid/core/containers/subscription-suspended/subscription-suspended.component';

export const containers = [
  MainLayoutComponent,
  UnderConstructionComponent,
  NoOrganizationComponent,
  NotFoundPageComponent,
  SubscriptionSuspendedComponent
];

export * from './main-layout/main-layout.component';
export * from './under-construction/under-construction.component';
export * from './no-organization/no-organization.component';
export * from './not-found-page/not-found-page.component';
export * from './subscription-suspended/subscription-suspended.component';
