import { CommentsService } from '@aid/comments/services';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileService } from '@aid/shared/services';
import { Comment } from '@aid/comments/types/classes/comment.class';

@Injectable()
export class CustomerCommentsService extends CommentsService {
  private static readonly RESOURCE = 'customers';
  private comment: Comment;

  constructor(
    protected http: HttpClient,
    protected fileUploadService: FileService<Comment>
  ) {
    super(http, fileUploadService, CustomerCommentsService.RESOURCE);
  }

  get customerComment() {
    return this.comment;
  }

  set customerComment(comment: Comment) {
    this.comment = comment;
  }
}
