import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { HttpClient } from '@angular/common/http';
import { Comment } from '@aid/comments/types/classes/comment.class';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FileService } from '@aid/shared/services';

export class CommentsService extends CrudPaginationService<Comment> {
  resourceId: number;

  constructor(
    protected http: HttpClient,
    protected fileUploadService: FileService<Comment>,
    private resource: string
  ) {
    super(http, true);
  }

  setResourceId(resource: number) {
    this.resourceId = resource;
  }

  get url(): string {
    return `${this.resource}/${this.resourceId}/comments`;
  }

  delete(commentId: number) {
    return this.http
      .delete(`${this.url}/${commentId}`)
      .pipe(tap(() => super.deleteValue(commentId)));
  }

  add(comment: Comment, file?: File): Observable<Comment> {
    return super
      .add(comment)
      .pipe(
        switchMap((_comment: Comment) =>
          this.fileUploadService.fileUploadObject(_comment, file)
        )
      );
  }

  edit(comment: Comment, file?: File): Observable<Comment> {
    return this._http.patch<Comment>(`${this.url}/${comment.id}`, comment).pipe(
      tap(result => super.editValue(result)),
      switchMap((_comment: Comment) =>
        this.fileUploadService.fileUploadObject(_comment, file)
      )
    );
  }

  viewFile(comment: Comment) {
    const url = `${this.url}/${comment.id}/signed-url`;
    this.fileUploadService.viewFile(url);
  }
}
