import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aid-display-key-value',
  template: `
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span *ngIf="applyPipes" class="mat-subtitle-2 basic-color-900">
        {{ key | translate | sentancecase }}:
      </span>
      <span *ngIf="!applyPipes" class="mat-subtitle-2 basic-color-900">
        {{ key }}:
      </span>

      <span class="mat-subtitle-2 basic-color-900">
        <span *ngIf="!translate">{{ value }}</span>
        <span *ngIf="translate">{{ value | translate }}</span>
      </span>
    </div>
  `,
  styleUrls: ['./display-key-value.component.scss']
})
export class DisplayKeyValueComponent implements OnInit {
  @Input() key: string;
  @Input() value: string;
  @Input() applyPipes = true;
  @Input() translate = false;

  constructor() {}

  ngOnInit() {}
}
