import { InvitationType } from '@aid/auth/types/enums/invitation-type.enum';
import {
  Invitation,
  UserInvitation
} from '@aid/auth/types/classes/invitation.class';

export class InvitationFactory {
  public static getInstance(type: InvitationType, invitation: Invitation) {
    switch (type) {
      case InvitationType.USER:
        return new UserInvitation(invitation as UserInvitation);
      case InvitationType.VERIFICATION:
        return new Invitation(invitation);
      default:
        return new Invitation(invitation);
    }
  }
}
