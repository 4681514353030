import { Component, OnInit } from '@angular/core';
import { IconRegistryService } from './core/services/icon-registry.service';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import localeDe from '@angular/common/locales/de';
import { RouteService } from '@aid/shared/services/route.service';
import { DateAdapter } from '@angular/material/core';
import { IdleService } from '@aid/core/services';

@Component({
  selector: 'aid-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private iconRegistry: IconRegistryService,
    private translate: TranslateService,
    private routeService: RouteService,
    private dateAdapter: DateAdapter<any>
  ) {
    this.iconRegistry.addSvgIcons();
    this.setLanguage();

    /**
     * Subscribe to route changes
     */
    this.routeService.subscribeRouteParams();
  }

  ngOnInit(): void {
    // this prevents the default behaviour that the browsers open a file when drag and dropped
    // we need it for drag and drop functionallity in places where files are uploaded
    window.addEventListener(
      'dragover',
      e => {
        if (e) {
          e.preventDefault();
        }
      },
      false
    );
    window.addEventListener(
      'drop',
      e => {
        if (e) {
          e.preventDefault();
        }
      },
      false
    );
  }

  private setLanguage() {
    registerLocaleData(localeDe, 'de');
    this.translate.addLangs(['en', 'de']);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    const localStorageLanguage = localStorage.getItem(
      environment.localStorage.language
    );

    if (localStorageLanguage) {
      this.translate.use(localStorageLanguage);
      return;
    }

    const language = browserLang.match(/en|de/) ? browserLang : 'en';

    this.translate.use(language);
    this.dateAdapter.setLocale(language);

    localStorage.setItem(environment.localStorage.language, language);
  }
}
