import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aid-dialog',
  template: `
    <div class="flex overflow dialog" fxLayout="column">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="dialog__header"
      >
        <div class="mat-headline-6 dialog__header--color">
          {{ title | translate | sentancecase }}
        </div>
        <button
          *ngIf="closeButton"
          [autofocus]="false"
          mat-icon-button
          (click)="close.emit()"
        >
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>
      <mat-divider *ngIf="divider"></mat-divider>

      <div class="dialog__container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @Input() title: string;
  @Input() divider = true;
  @Input() closeButton = true;
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
