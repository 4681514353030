import { NotificationType } from '../enums';

export class Notification {
  id: number;
  title: string;
  message: string;
  read: boolean;
  designee: number;
  created: string | Date;
  modified: string;
  type: NotificationType;
  metadata: { [id: string]: string | number };
}
