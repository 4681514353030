import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'aid-avatar',
  template: `
    <div class="flex">
      <img
        *ngIf="image"
        class="avatar--border-radius"
        src="{{ image }}"
        [style.height]="sizeAsString"
        [style.width]="sizeAsString"
        [style.font-size]="sizeAsString"
        alt=" "
      />
      <mat-icon
        *ngIf="!image && !name"
        class="avatar--border-radius avatar--color"
        svgIcon="account"
        [style.height]="sizeAsString"
        [style.width]="sizeAsString"
        [style.font-size]="sizeAsString"
      ></mat-icon>

      <div
        *ngIf="name && !image"
        [style.height]="sizeAsString"
        [style.width]="sizeAsString"
        [style.font-size]="fontSizeAsString"
        class="mat-subtitle-2 name"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div>{{ name | initials }}</div>
      </div>
    </div>
  `,
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit {
  @Input() image: string;
  @Input() size = 32;
  @Input() name: string;

  constructor() {}

  ngOnInit() {}

  get sizeAsString() {
    return `${this.size}px`;
  }

  get fontSizeAsString() {
    if (this.size > 60) {
      return `32px`;
    }
    return `14px`;
  }
}
