import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AuthService } from '@aid/auth/services';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { User } from '@aid/core/types/classes';
import { SidenavOption } from '@aid/core/types/interfaces';
import {
  AppModeService,
  CoreSidenavService,
  IdleService,
  SidenavService,
  UserService
} from '../../services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OrganizationService } from '@aid/core/services/organization.service';
import { Organization } from '@aid/core/types/classes/organization.class';
import { filter, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Reminder } from '@aid/reminders/types/classes';
import { RemindersService } from '@aid/reminders/services';
import { OrganizationMember } from '@aid/core/types/classes/organization-member.class';
import { NotificationsService } from '@aid/notifications/services';
import { NotificationsComponent } from '@aid/notifications/containers';
import { MembersService } from '@aid/members/services';
import { SubscriptionStatus } from '@aid/subscriptions/types/enums';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SessionTimeoutComponent } from '@aid/core/components/session-timeout/session-timeout.component';

@Component({
  selector: 'aid-main-layout',
  template: `
    <aid-right-sidenav>
      <aid-app-bar
        [organization]="organization$ | async"
        [reminders]="currentReminders"
        [currentMember]="currentMember$ | async"
        [user]="user$ | async"
        [notificationBadge]="notificationBadge$ | async"
        [reminderBadge]="reminderBadge$ | async"
        [customerMode]="customerMode$ | async"
        (loadReminders)="onLoadReminders()"
        (viewNotifications)="onViewNotifications()"
        (viewProfile)="onViewProfile()"
        (toggleCustomerMode)="onToggleMode()"
      ></aid-app-bar>
      <div
        class="main-layout--color full-width full-height overflow"
        fxLayout="row"
      >
        <aid-sidenav
          [sidenavOptions]="sidenavOptions"
          [active]="active"
          [user]="user$ | async"
          [collapsed]="sidenavCollapsed$ | async"
          (collapse)="onCollapse()"
        ></aid-sidenav>

        <div
          fxLayout="column"
          class="main-layout__container full-width full-height flex overflow"
        >
          <div
            fxLayout="column"
            class="padding-horizontal-32 padding-top-16"
            *ngIf="subscriptionExpiring"
          >
            <aid-warning-toast
              [message]="'subscriptions.expiration-message'"
              [title]="'subscriptions.expiration-title'"
              (close)="onCloseWarning()"
            >
            </aid-warning-toast>
          </div>

          <section class="main-layout__content full-height flex">
            <div class="flex full-width full-height overflow">
              <div class="flex-1">
                <router-outlet></router-outlet>
              </div>
            </div>
          </section>
        </div>
      </div>
    </aid-right-sidenav>
  `,
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  currentRoute$: BehaviorSubject<ActivatedRouteSnapshot>;
  user$: Observable<User>;
  active: string;
  sidenavOptions: SidenavOption[] = [];
  tabBarTitle: string;
  organization$: Observable<Organization>;
  sidenavCollapsed$: Observable<boolean>;
  customerMode$: Observable<boolean>;
  subscriptionExpiring: boolean;

  currentMember$: Observable<OrganizationMember>;
  currentReminders: Reminder[];
  notificationBadge$: Observable<number>;
  reminderBadge$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private userService: UserService,
    private organizationService: OrganizationService,
    private membersService: MembersService,
    private authService: AuthService,
    private sidenavService: SidenavService,
    private router: Router,
    private remindersService: RemindersService,
    private coreSidenavService: CoreSidenavService,
    private notificationService: NotificationsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appModeService: AppModeService,
    private idleService: IdleService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.authService.setSharedLoading(false);

    this.membersService.onRefresh();
    this.coreSidenavService.initFromLocalStorage();
    this.sidenavCollapsed$ = this.coreSidenavService.collapsed$;

    this.appModeService.initFromLocalStorage();
    this.customerMode$ = this.appModeService.customerMode$;

    this.user$ = this.userService.user$;

    this.organization$ = this.organizationService.organization$;
    this.sidenavOptions = this.sidenavService.buildSidenavOptions(
      this.organizationService.organization
    );

    this.setAppBarTitle();

    this.currentRoute$ = new BehaviorSubject(
      this.router.routerState.root.snapshot
    );
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setAppBarTitle();
        this.currentRoute$.next(this.router.routerState.root.snapshot);
      }
    });

    this.currentMember$ = this.organizationService.organizationMember$;

    this.subscribeNotifications();
    this.notificationBadge$ = this.notificationService.subscribeBadge$();
    this.remindersService
      .getBadge$()
      .subscribe((badge: number) => this.reminderBadge$.next(badge));

    this.checkSubscriptionExpiring();
    this.checkUserInactivity();
  }

  onLoadReminders() {
    this.remindersService
      .currentReminders()
      .subscribe((reminders: Reminder[]) => {
        const badge = reminders.filter(
          reminder =>
            new Date(reminder.dueDate as Date).getDay() === new Date().getDay()
        ).length;
        if (badge >= 0) {
          this.reminderBadge$.next(badge);
          this.remindersService.setBadge(badge);
        }
        this.currentReminders = reminders;
      });
  }

  onCollapse() {
    this.coreSidenavService.toggle();
  }

  onToggleMode() {
    this.appModeService.toggle();
  }

  onViewNotifications() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      NotificationsComponent
    );
    this.sidenavService.open(componentFactory, 600);

    this.notificationService.seenAll().subscribe();
  }

  onViewProfile() {
    this.router.navigate([
      this.organizationService.organization.slug,
      'profile'
    ]);
  }

  private checkSubscriptionExpiring() {
    this.subscriptionExpiring =
      this.organizationService.organization.subscriptionStatus ===
      SubscriptionStatus.EXPIRING;
  }

  onCloseWarning() {
    this.subscriptionExpiring = false;
  }

  private subscribeNotifications() {
    this.notificationService
      .subscribeLatest$()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(() => !this.sidenavService.isOpenedValue)
      )
      .subscribe(value => this.notificationService.show(value));
  }

  private setAppBarTitle() {
    const url = this.router.url;
    this.active = `${this.organizationService.organization.slug}/${url.split(
      '/'
    )[2] || ''}`.split('?')[0];
    const option = this.sidenavOptions.find(
      value => value.routerLink === this.active
    );
    this.tabBarTitle = option ? option.name : 'dashboard';
  }

  private checkUserInactivity() {
    this.idleService
      .startWatching(1200)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();

    this.idleService
      .checkIdle()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        filter(value => !!value)
      )
      .subscribe(() => this.logOut());
  }

  private logOut() {
    this.userService.logOut();
    this.matDialog.closeAll();
    this.sidenavService.close();

    this.router.navigate(['auth'], {
      queryParams: {
        redirectTo: document.location.pathname
      }
    });

    this.openSessionTimeout();
  }

  private openSessionTimeout() {
    this.matDialog.open(SessionTimeoutComponent, {
      width: '500px'
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
