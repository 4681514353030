import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileService } from '@aid/shared/services';
import { GcsFile } from '@aid/shared/types/classes';
import { Email } from '@aid/insurances/types/classes/email.class';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

@Injectable()
export class EmailService extends FileService<GcsFile> {
  get url(): string {
    return 'emails';
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  add(email: Email) {
    const files: { [id: string]: File } = this.createFileObjects(email);

    return this.http.post(this.url, email).pipe(
      switchMap((_email: Email) =>
        this.uploadFiles(_email.attachments, files).pipe(map(() => _email))
      ),
      switchMap((response: Email) => this.send(response))
    );
  }

  private send(email: Email) {
    return this.http
      .post(`${this.url}/${email.id}/send`, {})
      .pipe(map(() => email));
  }

  private createFileObjects(email: Email) {
    const files: { [id: string]: File } = {};

    if (email.attachments && email.attachments.length !== 0) {
      email.attachments.map(value => {
        if (!value.file) {
          return;
        }
        files[`${value.fileName}-${value.fileType}`] = value.file;
        delete value.file;
      });
    }

    return files;
  }

  private uploadFiles(values: GcsFile[], files: { [id: string]: File }) {
    const observables = values.map((file: GcsFile) =>
      this.fileUploadObject(file, files[`${file.fileName}-${file.fileType}`])
    );

    return observables.length === 0 ? of(null) : forkJoin(observables);
  }
}
