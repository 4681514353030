import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Organization } from '@aid/core/types/classes/organization.class';
import { map, switchMap, tap } from 'rxjs/operators';
import { OrganizationMember } from '@aid/core/types/classes/organization-member.class';
import { Theme } from '@aid/settings/types/classes/theme.class';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private url = 'organizations';

  private _organizationMember$ = new BehaviorSubject<OrganizationMember>(null);
  private _organization$ = new BehaviorSubject<Organization>(null);
  private _organizationId$ = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient) {}

  get(organizationId: number): Observable<Organization> {
    return this.http.get<Organization>(`${this.url}/${organizationId}`).pipe(
      tap(organization => {
        this._organization$.next(new Organization(organization));
      }),
      switchMap(() => this._organization$.asObservable())
    );
  }

  getDetails(organizationId: number): Observable<Organization> {
    return this.http.get<Organization>(`${this.url}/${organizationId}`);
  }

  get organization$(): Observable<Organization> {
    return this._organization$.asObservable();
  }

  get organization(): Organization {
    return this._organization$.value;
  }

  get organizationMember(): OrganizationMember {
    return this._organizationMember$.value;
  }

  get organizationMember$(): Observable<OrganizationMember> {
    return this._organizationMember$.asObservable();
  }

  get organizationId(): number {
    return this._organizationId$.value;
  }

  setOrganizationMember(organization: OrganizationMember) {
    this._organizationId$.next(organization.id);
    this._organizationMember$.next(new OrganizationMember(organization));
  }

  update(organization: Partial<Organization>) {
    return this.http
      .patch<Organization>(`${this.url}/${organization.id}`, organization)
      .pipe(
        tap(_organization =>
          this._organization$.next(new Organization(_organization))
        )
      );
  }

  updateTheme(theme: Theme, logo?: File, favIcon?: File) {
    const url = `${this.url}/${this.organizationId}`;
    return this.http.patch<Organization>(url, theme).pipe(
      switchMap(response => this.uploadLogo(response, logo, 'logo')),
      switchMap(response => this.uploadLogo(response, favIcon, 'favicon'))
    );
  }

  private uploadLogo(
    organization: Organization,
    file: File,
    displayName: string
  ) {
    if (!file) {
      return of(organization);
    }

    return this.http.put(organization[displayName].uploadSignedUrl, file).pipe(
      tap(() => this._organization$.next(new Organization(organization))),
      map(() => organization)
    );
  }

  private updateOrganizationTheme(organization: Organization) {
    this._organization$.next(organization);
  }

  getUrl() {
    return `${environment.api}/templates/upload-file-credentials?organizationId=${this.organizationId}`;
  }

  startImportingDocuments() {
    const url = `${this.url}/${this.organizationId}/start_document_import`;
    return this.http.post<Organization>(url, undefined);
  }
}
