import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService<T> {
  protected constructor(protected http: HttpClient) {}

  viewFile(url: string) {
    return this.http.get<string>(url).subscribe(value => window.open(value));
  }

  /*fileUploadObject(object: T, file?: File): Observable<T> {
    if (file && object.uploadSignedUrl) {
      return this.upload(file, object.uploadSignedUrl).pipe(map(() => object));
    }
    return of(object);
  }*/

  fileUploadObject(object: T, file?: File, signedUrl?: string): Observable<T> {
    if (!file) {
      return of(object);
    }

    if (!signedUrl) {
      if (object.hasOwnProperty('uploadSignedUrl')) {
        // eslint-disable-next-line
        return this.uploadFile(object, file, object['uploadSignedUrl']);
      }
    }

    return this.uploadFile(object, file, signedUrl);
  }

  private uploadFile(object, file, signedUrl) {
    return this.upload(file, signedUrl).pipe(map(() => object));
  }

  private upload(file: File, url: string) {
    const fileParts = file.name.split('.');
    const fileType =
      fileParts.length > 1 ? fileParts[fileParts.length - 1] : '';

    if (fileType == 'msg') {
      return this.http.put(url, file, {
        headers: {
          'Content-Type': 'application/vnd.ms-outlook'
        }
      });
    } else {
      return this.http.put(url, file);
    }
  }

  downloadFile(baseUrl: string, fileName: string) {
    return this.http.get<any>(baseUrl).pipe(
      map(value => value),
      switchMap(url => this.http.get(url, { responseType: 'blob' })),
      tap(blob => {
        this.download(blob, fileName);
      })
    );
  }

  download(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);

    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
