import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'aid-search',
  template: `
    <mat-form-field
      class="search-box"
      [ngClass]="{ 'search-border-radius': !canFilter }"
    >
      <input
        matInput
        autocomplete="off"
        placeholder="{{ searchPlaceholder | translate }}"
        [formControl]="searchControl"
      />

      <button
        *ngIf="searchControl.value"
        matSuffix
        mat-icon-button
        (click)="clearSearch()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>

      <mat-icon
        *ngIf="!searchControl.value"
        matSuffix
        svgIcon="search"
      ></mat-icon>
    </mat-form-field>
  `,
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy {
  searchControl = new UntypedFormControl('');
  @Input() canFilter: boolean;
  @Input() searchPlaceholder = 'search';

  @Output() search = new EventEmitter<string>();

  private ngUnsubscribe = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(500))
      .subscribe(value => {
        const search = value ? value.toLowerCase() : value;
        this.search.emit(search);
      });
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
