import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DeliveryDate } from '@aid/product-contract/types/classes';

@Component({
  selector: 'aid-product-contract-delivery-date-dialog',
  template: `
    <aid-dialog [title]="'delivery-date'" (close)="onClose()">
      <div fxLayout="column" fxLayoutGap="32px">
        <span class="mat-subtitle-2 basic-color-900">{{
          'change-delivery-date' | translate | sentancecase
        }}</span>
        <aid-product-contract-delivery-date-form
          (cancel)="onClose()"
          (submit)="onSubmit($event)"
        >
        </aid-product-contract-delivery-date-form>
      </div>
    </aid-dialog>
  `,
  styleUrls: ['./product-contract-delivery-date-dialog.component.scss']
})
export class ProductContractDeliveryDateDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ProductContractDeliveryDateDialogComponent>
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  onSubmit(deliveryDate: DeliveryDate) {
    this.dialogRef.close(deliveryDate);
  }
}
