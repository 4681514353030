import { Member } from '@aid/members/types/classes/member.class';
import { Gender } from '@aid/shared/types/enums/gender.enum';
import { Marital } from '@aid/customers/types/enums/marital.enum';
import { CustomerSpecification } from '@aid/customers/types/enums/customer-specification.enum';
import { TypeOfCustomer } from '@aid/customers/types/enums/type-of-customer.enum';
import { CustomerContract } from '@aid/customers/types/classes/customer-contract.class';
import { CustomerLegalForm } from '@aid/customers/types/classes/customer-legal-form.class';
import {
  CustomerAdditionalInformation,
  CustomerContactInformation
} from '@aid/customers/types/interfaces';

export class Client {
  id: number;
  profilePicture?: string;
  firstName: string;
  lastName: string;
  name?: string;
  type: TypeOfCustomer;
  companyName?: string;
  legalForm?: number | CustomerLegalForm;
  street: string;
  houseNumber: string;
  country: string;
  city: string;
  postcode: string;
  gender: Gender;
  birthday: string;
  email: string;
  phoneNumber: string;
  phoneNumberSecondary: string;
  taxId: string;
  maritalStatus: Marital;
  publicService: boolean;
  selfEmployed: boolean;
  selfEmployedCompanyName: string;
  job: string;

  /**
   * Optional fields
   */
  title?: string;
  nameSuffix?: string;
  nationality?: string;
  placeOfBirth?: string;
  birthName?: string;
  idCardType?: string;
  idNumber?: string;
  idStartDate?: Date | string;
  idEndDate?: Date | string;
  idCardAuthority?: string;
  remarks?: string;
  vat?: number;
  registryNumber?: number;
  registrationDate?: string;
  activityDomain?: string;

  /**
   * If the client has an user, then it has an account
   */
  user?: number;
}

export class Customer {
  id: number;

  /**
   * A customer is a relationship between a client and a Broker
   */
  supervisor: Member | number;
  partnershipResponsible?: Member;
  client: Client;

  immediateAccess: boolean;
  customerSpecifications: CustomerSpecification | string;
  customerNumber?: string;
  invitation: boolean;

  /**
   * Optional fields
   */
  contract?: CustomerContract;

  set contactInformation(information: CustomerContactInformation) {
    this.client.firstName = information.firstName;
    this.client.lastName = information.lastName;
    this.client.email = information.email;
    this.client.gender = information.gender;
    this.client.birthday = information.birthday;
    this.client.phoneNumber = information.phoneNumber;
    this.client.phoneNumberSecondary = information.phoneNumberSecondary;
    this.client.maritalStatus = information.maritalStatus;
    this.client.job = information.job;
  }

  set additionalInformation(information: CustomerAdditionalInformation) {
    this.customerSpecifications = information.customerSpecifications;
    this.immediateAccess = information.immediateAccess;
    this.client.taxId = information.taxId;
    this.client.publicService = information.publicService;
    this.client.selfEmployed = information.selfEmployed;
    this.client.selfEmployedCompanyName = information.selfEmployedCompanyName;
  }
}
