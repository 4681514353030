import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'aid-empty-table',
  template: `
    <div
      *ngIf="!loading && data && data.length === 0"
      class="mat-subtitle-2 empty-list padding-16"
    >
      {{ message | translate }}
    </div>
  `,
  styleUrls: ['./empty-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyTableComponent implements OnInit {
  @Input() loading: boolean;
  @Input() data: any[];
  @Input() message = 'no-message';

  constructor() {}

  ngOnInit() {}
}
