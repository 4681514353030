import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'aid-upload-file-simple',
  template: `
    <div
      *ngIf="fileControl.invalid"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="border-dashed border-radius-8 pointer padding-16"
      (click)="onFile()"
    >
      <mat-icon
        svgIcon="upload"
        color="primary"
        class="container__icon"
      ></mat-icon>
      <span class="mat-subtitle-2"> {{ title | translate }}</span>
      <span class="mat-small basic-color-600">
        {{ description | translate }}
      </span>
    </div>

    <div
      *ngIf="fileControl.valid"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="mat-subtitle-2">{{ fileName }}</div>
      <button mat-icon-button (click)="onRemoveFile()">
        <mat-icon svgIcon="delete"></mat-icon>
      </button>
    </div>

    <input
      style="display: none"
      type="file"
      #file
      (change)="onAddFile($event)"
      [accept]="accept"
    />
  `,
  styleUrls: ['./upload-file-simple.component.scss']
})
export class UploadFileSimpleComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() accept = 'text/csv';
  @Input() fileControl: UntypedFormControl;

  @Output() uploaded = new EventEmitter();
  @Output() removed = new EventEmitter();

  form: UntypedFormGroup;
  @ViewChild('file') file: ElementRef;

  constructor() {}

  ngOnInit() {}

  get fileName() {
    const file: File = this.fileControl.value;
    return file.name;
  }

  onAddFile(event) {
    const file = event.target.files[0];
    this.fileControl.setValue(file);
    this.uploaded.emit();
  }

  onRemoveFile() {
    this.file.nativeElement.value = '';
    this.fileControl.setValue(null);

    this.removed.emit();
  }

  onFile() {
    this.file.nativeElement.click();
  }
}
