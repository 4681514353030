import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from '@aid/core/services';

declare const Beacon: any;

@Component({
  selector: 'aid-helpscout-dialog',
  template: `
    <div class="helpscout">
      <button [autofocus]="false" mat-icon-button (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./helpscout-dialog.component.scss']
})
export class HelpscoutDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<HelpscoutDialogComponent>,
    private userService: UserService
  ) {}

  ngOnInit() {
    const currentUser = this.userService.userValue;

    Beacon('identify', {
      name: currentUser.name,
      email: currentUser.email
    });

    Beacon('toggle');
  }

  onClose() {
    Beacon('close');
    this.dialogRef.close();
  }
}
