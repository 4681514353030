import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  MatAutocompleteTrigger,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { debounceTime, takeUntil } from 'rxjs/operators';

/**
 * This component allows you to search and call the api
 * for values
 */

interface Option {
  id: number;
  name?: string;
}

@Component({
  selector: 'aid-autocomplete-select',
  template: `
    <mat-form-field class="full-width" color="accent">
      <mat-label *ngIf="label && applyPipes">{{
        label | translate | titlecase
      }}</mat-label>
      <mat-label *ngIf="label && !applyPipes">{{ label }}</mat-label>

      <input
        matInput
        #input
        [matAutocomplete]="auto"
        (input)="onInputChanged($event.target.value)"
        [required]="isRequired"
        [formControl]="control"
      />
      <mat-spinner
        *ngIf="loading"
        class="mat-select-search-spinner"
        diameter="16"
      >
      </mat-spinner>
      <button
        mat-button
        *ngIf="filterControl.value && !loading"
        mat-icon-button
        (click)="onReset()"
        [disabled]="control.disabled"
        class="mat-select-search-clear"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>

      <mat-autocomplete
        #auto="matAutocomplete"
        (opened)="onOpen()"
        (optionSelected)="handler($event)"
        [displayWith]="displayFn"
      >
        <mat-option *ngIf="!options || options.length === 0">
          {{ 'no-items-found' | translate | sentancecase }}
        </mat-option>
        <mat-option
          *ngFor="let option of options"
          [value]="option"
          data-cy="autocomplete-select"
        >
          <ng-container *ngIf="useNameForOptionsInsteadOfDisplayFn">
            {{ option.name }}
          </ng-container>
          <ng-container *ngIf="!useNameForOptionsInsteadOfDisplayFn">
            {{ displayFn(option) }}
          </ng-container>
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="control.hasError('required')">
        {{ label || 'this-field' | translate | titlecase }}
        {{ 'is-required' | translate }}
      </mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./autocomplete-select.component.scss']
})
export class AutocompleteSelectComponent
  implements OnInit, AfterViewInit, OnDestroy {
  constructor() {}
  /**
   * Input label
   */
  @Input() label: string;
  @Input() applyPipes = true;
  @Input() useNameForOptionsInsteadOfDisplayFn = false;

  @Input() options: Option[];
  @Input('autofocus') set _autofocus(autofocus: boolean) {
    if (autofocus) {
      this.focus = true;
      this.focusInput();
    }
  }

  /**
   * Parent Form control passed
   */
  @Input() control: UntypedFormControl;

  @Input() loading: boolean;

  @Output() search = new EventEmitter<string>();
  @Output() open = new EventEmitter();

  @ViewChild(MatAutocompleteTrigger)
  trigger: MatAutocompleteTrigger;

  @ViewChild('input') input: ElementRef;

  filterControl: UntypedFormControl;
  value: string;
  focus: boolean;

  private ngUnsubscribe = new Subject<void>();
  private lastSearch: string = null;

  @Input() displayFn = item => (item ? item.name : '');

  ngOnInit() {
    this.value = this.control.value ? this.control.value : null;
    this.filterControl = new UntypedFormControl();

    this.filterControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(400))
      .subscribe(value => {
        const search = value ? value.toLowerCase() : value;
        if (this.lastSearch !== search) {
          this.lastSearch = search;
          this.search.emit(search);
        }
      });

    this.control.valueChanges.subscribe(value => {
      if (this.useNameForOptionsInsteadOfDisplayFn) {
        // quick fix, used only in raw-data-table
        this.input.nativeElement.focus();
      }
      this.value = value ? this.displayFn(value) : null;
      this.focusInput();
      if (value === null) {
        this.input.nativeElement.value = '';
      }
    });
  }

  ngAfterViewInit() {
    this._subscribeToClosingActions();
  }

  onOpen() {
    this.open.emit();
  }

  private focusInput() {
    if (!this.focus) {
      return;
    }
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 100);
  }

  onInputChanged(search: string) {
    this.filterControl.setValue(search);
  }

  onReset() {
    this.input.nativeElement.value = '';
    this.onInputChanged('');
  }

  handler(event: MatAutocompleteSelectedEvent): void {
    this.value = this.displayFn(event.option.value);
    this.control.setValue(event.option.value);
  }

  get isRequired(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  private _subscribeToClosingActions(): void {
    this.trigger.panelClosingActions
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(e => {
        if (!e || !e.source) {
          this.filterControl.setValue(null);
          this.value = null;
          this.control.setValue(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closePanel() {
    this.trigger.closePanel();
  }
}
