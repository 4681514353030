import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

interface Item {
  id: number | string;
  name?: string;
  disabled?: boolean;
}

@Component({
  selector: 'aid-select',
  template: `
    <mat-form-field class="full-width" [appearance]="appearance">
      <mat-label>
        {{ label | translate | sentancecase }}
      </mat-label>

      <mat-spinner *ngIf="loading" class="filter__loading" diameter="16">
      </mat-spinner>
      <mat-select
        [formControl]="control"
        [multiple]="multiple"
        [required]="isRequired"
      >
        <div *ngIf="filterable" class="filter filter--sticky" fxLayout="row">
          <mat-icon class="filter--margin" svgIcon="search"></mat-icon>
          <input
            matInput
            placeholder="{{ 'filter' | translate | titlecase }}"
            type="text"
            autocomplete="off"
            [formControl]="filterControl"
            (keydown.Space)="$event.stopPropagation()"
            (blur)="onBlur()"
          />
        </div>
        <button
          *ngIf="hasValue && clear"
          (click)="onClear()"
          mat-button
          class="filter__clear"
        >
          {{ 'clear' | translate | uppercase }}
        </button>
        <mat-option *ngIf="!options || options.length === 0">
          {{ 'no-items-found' | translate | sentancecase }}
        </mat-option>
        <ng-container *ngIf="filteredItems$ | async as filteredItems">
          <mat-option
            *ngIf="options.length !== 0 && filteredItems.length === 0"
          >
            {{ 'no-results-clear-filter' | translate | sentancecase }}
          </mat-option>
          <mat-option
            *ngFor="let item of filteredItems"
            [value]="item.id"
            [disabled]="item.disabled"
            data-cy="option-select"
          >
            <span *ngIf="translate">
              {{ displayFn(item) | translate | sentancecase }}
            </span>
            <span *ngIf="!translate"> {{ displayFn(item) }} </span>
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="control.hasError('required')">
        {{ label || 'this-field' | translate | titlecase }}
        {{ 'is-required' | translate }}
      </mat-error>

      <mat-error *ngFor="let error of control.errors | keyvalue">
        <ng-container *ngIf="error.key !== 'required'">
          {{ error.value | translate }}
        </ng-container>
      </mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() selectFirst = false;
  @Input() control: UntypedFormControl;
  @Input('options') set items(options: Item[]) {
    if (options) {
      this.initFilter();
      this.options = options;
      this.selectFirstOption();
    }
  }

  filteredItemsLength: number;

  constructor() {}

  get isRequired(): boolean {
    if (!this.label) {
      return false;
    }

    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
  filterControl: UntypedFormControl = new UntypedFormControl();
  filteredItems$: Observable<Item[]>;
  options: Item[];
  @ViewChild(MatSelect, { static: true }) matSelect: MatSelect;

  @Output() select = new EventEmitter();

  @Input() label: string;
  @Input() translate: boolean;
  @Input() filterable = true;
  @Input() clear = true;
  @Input() multiple: boolean;
  @Input() appearance = 'outline';
  @Input() loading = false;
  @Input() displayFn = item => item.name;

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this.select.emit(value);
    });
  }

  onClear() {
    this.control.setValue(null);
    this.matSelect.close();
  }

  initFilter() {
    this.filteredItems$ = this.filterControl.valueChanges.pipe(
      startWith(''),
      map(filterBy =>
        filterBy ? this.filteredBy(filterBy) : this.options.slice()
      ),
      tap(filteredItems => (this.filteredItemsLength = filteredItems.length))
    );
  }

  filteredBy(filterBy: string) {
    if (!this.options) {
      return [];
    }

    return this.options.filter(
      item =>
        this.displayFn(item)
          .toLowerCase()
          .indexOf(filterBy.toLowerCase()) > -1
    );
  }

  private selectFirstOption() {
    if (!this.selectFirst) {
      return;
    }

    if (this.options.length === 1) {
      this.control.setValue(this.options[0].id);
    }
  }

  get hasValue() {
    return this.control.value !== null && this.control.value !== undefined;
  }

  onBlur() {
    if (this.filteredItemsLength > 0) {
      return;
    }
    this.filterControl.setValue('');
  }
}
