import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unsortedKeys' })
export class UnsortedKeysPipe implements PipeTransform {
  transform(keys: { [id: string]: string }): string[] {
    const arr = [];
    for (const key of Object.keys(keys)) {
      arr.push(key);
    }
    return arr;
  }
}
