import { InjectionToken, Type } from '@angular/core';

export interface ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 96,
    right: 32
  },
  animation: {
    fadeOut: 500,
    fadeIn: 300
  }
};

export const TOAST_CONFIGURATION = new InjectionToken('TOAST_CONFIGURATION');

export class ToastTemplate {
  template: Type<any>;
  data?: any;
}

export const TOAST_TEMPLATE: InjectionToken<ToastTemplate> = new InjectionToken<
  ToastTemplate
>('TOAST_TEMPLATE_CONFIGURATION');

export const TOAST_DATA: InjectionToken<any> = new InjectionToken<any>(
  'TOAST_DATA'
);
