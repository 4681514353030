import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'aid-display-data',
  template: `
    <div
      *ngIf="!isInput"
      fxLayoutGap="8px"
      fxLayout="{{ labelFlex }}"
      fxLayoutAlign="{{ labelFlexAlign }}"
    >
      <div
        [ngClass]="{
          floating__label: type === 'floating',
          label: type === 'inline'
        }"
      >
        {{ label | translate }}{{ type === 'inline' ? ':' : '' }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div *ngIf="!values" class="label mat-subtitle-2">
          <ng-container *ngIf="translate">
            {{ (value | translate) || '-' }}
          </ng-container>
          <ng-container *ngIf="!translate">
            {{ value || '-' }}
          </ng-container>
        </div>
        <aid-avatar *ngIf="image" [image]="image"></aid-avatar>
      </div>

      <div *ngIf="values">
        <div *ngFor="let value of values" class="label mat-subtitle-2">
          {{ value }}
        </div>
        <div *ngIf="values.length === 0">
          -
        </div>
      </div>
    </div>
    <mat-form-field *ngIf="isInput" class="full-width">
      <mat-label> {{ label | translate }}</mat-label>
      <input matInput disabled [value]="value" />
    </mat-form-field>
  `,
  styleUrls: ['./display-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayDataComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() image: string;
  @Input() values: string[];
  @Input() translate: boolean;
  @Input() type: 'inline' | 'floating' | 'input' = 'inline';

  constructor() {}

  ngOnInit() {}

  get labelFlex() {
    return this.type === 'inline' ? 'row' : 'column';
  }

  get labelFlexAlign() {
    return this.type === 'inline' ? 'start center' : 'start';
  }

  get isInput() {
    return this.type === 'input';
  }
}
