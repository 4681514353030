import { Injectable } from '@angular/core';
import { CrudService } from '@aid/shared/services';
import { Provider } from '../types/classes/provider.class';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService extends CrudService<Provider> {
  get url() {
    return 'providers';
  }
  constructor(private http: HttpClient) {
    super(http);
  }

  protected listRequest(params: Params): Observable<Provider[]> {
    return super
      .listRequest(params)
      .pipe(map(values => values.sort((a, b) => (a.name > b.name ? 1 : -1))));
  }

  search(search: string) {
    if (!search) {
      return this.values$.value;
    }
    if (!this.values$.value) {
      return [];
    }

    return this.values$.value.filter(
      provider => provider.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }
}
