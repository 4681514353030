import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { MembersService } from '@aid/members/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationService } from '@aid/core/services/organization.service';

@Injectable()
export class CommissionAdminGuard implements CanActivate {
  constructor(
    private membersService: MembersService,
    private router: Router,
    private route: ActivatedRoute,
    private organizationService: OrganizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.membersService.isCurrentUserAdmin$().pipe(
      map(value => {
        const organizationSlug = this.organizationService.organization.slug;
        if (!value) {
          this.router.navigate([organizationSlug, 'commissions']);
        }
        return value;
      })
    );
  }
}
