import { Injectable } from '@angular/core';
import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { Customer } from '@aid/customers/types/classes/customer.class';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { InsuranceType } from '@aid/administration/types/classes';
import { CustomerContract } from '@aid/customers/types/classes/customer-contract.class';
import { FileService } from '@aid/shared/services';
import { Insurance } from '@aid/insurances/types/classes';
import { InsurancesService } from '@aid/insurances/services';
import { Organization } from '@aid/core/types/classes';
import { OrganizationService } from '@aid/core/services/organization.service';
import { CustomerInvitation } from '@aid/customers/types/classes';
import { MembersService } from '@aid/members/services';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import { Partnership } from '@aid/partnerships/types/classes';
import { Member } from '@aid/members/types/classes';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends CrudPaginationService<Customer> {
  private customer = new BehaviorSubject<Customer>(null);
  private customerId = new BehaviorSubject<number>(null);
  private distributor = new BehaviorSubject<Organization>(undefined);
  private editable = new BehaviorSubject<boolean>(null);

  public readonly SIDENAV_WIDTH = 800;

  constructor(
    private http: HttpClient,
    private fileUploadService: FileService<CustomerContract>,
    private insurancesService: InsurancesService,
    private organizationService: OrganizationService,
    private membersService: MembersService
  ) {
    super(http, true);
  }

  get url(): string {
    return 'customers';
  }

  get(valueId: number): Observable<Customer> {
    this.customerId.next(valueId);

    return super.get(valueId).pipe(
      tap((customer: Customer) => this.customer.next(customer)),
      switchMap(() => this.customer.asObservable())
    );
  }

  getBasic(value: number): Observable<Customer> {
    return this.http.get<Customer>(`${this.url}/${value}/basic`);
  }

  getActiveInsuranceTypes(customerId: number): Observable<InsuranceType[]> {
    return this.http.get<InsuranceType[]>(
      `${this.url}/${customerId}/active-insurance-types`
    );
  }

  uploadContract(customerContract: CustomerContract, file: File) {
    return this.http
      .post<CustomerContract>(
        `${this.url}/${customerContract.customer}/contracts`,
        customerContract
      )
      .pipe(
        switchMap(value =>
          this.fileUploadService.fileUploadObject(value, file)
        ),
        tap(value => {
          this.customer.value.contract = value;
          this.customer.next(this.customer.value);
        })
      );
  }

  viewContract(customerContract: CustomerContract) {
    const url = `${this.url}/${customerContract.customer}/contracts/${customerContract.id}/signed-url`;
    this.fileUploadService.viewFile(url);
  }

  removeContract(customerContract: CustomerContract) {
    const url = `${this.url}/${customerContract.customer}/contracts/${customerContract.id}`;
    return this.http.delete(url).pipe(
      tap(() => {
        this.customer.value.contract = null;
        this.customer.next(this.customer.value);
      })
    );
  }

  getActiveInsurances(customerId: number): Observable<Insurance[]> {
    if (!customerId) {
      return of([]);
    }

    this.insurancesService.setCustomer(customerId);
    return this.insurancesService.list();
  }

  inviteCustomer(customerInvitation: CustomerInvitation) {
    const url = `customer-invitations`;
    return this.http.post(url, customerInvitation);
  }

  get customerValue() {
    return this.customer.value;
  }

  get customer$(): Observable<Customer> {
    return this.customer.asObservable();
  }

  get customerId$(): Observable<number> {
    return this.customerId.asObservable();
  }

  get customerIdValue(): number {
    return this.customerId.value;
  }

  setCustomerId(customerId: number) {
    this.customerId.next(customerId);
  }

  setCustomer(customer: Customer) {
    this.customer.next(customer);
  }

  get editable$() {
    return this.editable.asObservable();
  }

  isEditable$(): Observable<boolean> {
    return combineLatest([
      this.membersService.isCurrentUserAdmin$(),
      this.customer
    ]).pipe(
      filter(([isAdmin, customer]) => !!customer),
      map(([isAdmin, customer]) => [
        isAdmin,
        (customer.supervisor as Member).id,
        this.organizationService.organizationMember.member
      ]),
      map(
        ([isAdmin, supervisorId, loggedInMemberId]) =>
          !!isAdmin || loggedInMemberId === supervisorId
      ),
      tap(editable => this.editable.next(editable))
    );
  }

  getCustomerDistributor(customer: Customer): Observable<Organization> {
    if (
      (customer.supervisor as Member).organization ===
      this.organizationService.organizationId
    ) {
      this.distributor.next(null);
      return of(null);
    }

    const organizationId = (customer.supervisor as Member)
      .organization as number;

    if (
      this.distributor.value &&
      this.distributor.value.id === organizationId
    ) {
      return this.distributor$;
    }

    return this.organizationService.getDetails(organizationId).pipe(
      tap(value => {
        this.distributor.next(value);
      })
    );
  }

  get distributor$() {
    return this.distributor.asObservable();
  }

  getDistributor() {
    return this.distributor.getValue();
  }
  setDistributor(distributor: Organization) {
    this.distributor.next(distributor);
  }

  /**
   * Return True if the current customer belong to current organization
   * Return False if the current customer belong to a distributor
   */
  get organizationCustomer$() {
    return this.customer$.pipe(
      filter(value => !!value),
      map(
        value =>
          (value.supervisor as Member).organization ===
          this.organizationService.organizationId
      )
    );
  }

  checkDuplicate(customer: Customer): Observable<Customer> {
    const customerDetails = {
      firstName: customer.client.firstName,
      lastName: customer.client.lastName,
      email: customer.client.email
    };
    return this.http.post<Customer>(`${this.url}/duplicate`, customerDetails);
  }

  applyQueryParams(queryParams: QueryParams) {
    if (JSON.stringify(queryParams) !== JSON.stringify(this.queryParamsValue)) {
      this.changeParams(queryParams);
    }
  }

  setPartnershipResponsible(
    customers: Customer[],
    partnerships: Partnership[]
  ): Customer[] {
    if (!partnerships) {
      return customers;
    }

    return customers.map(customer => {
      const supervisorOrganization = (customer.supervisor as Member)
        .organization;

      if (supervisorOrganization === this.organizationService.organizationId) {
        return customer;
      }

      const partnership = partnerships.find(
        (value: Partnership) => value.distributor === supervisorOrganization
      );

      if (partnership) {
        customer.partnershipResponsible = partnership.responsible;
      }

      return customer;
    });
  }
}
