import { Component, OnDestroy, OnInit } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { NotificationsService } from '@aid/notifications/services';
import { SidenavService } from '@aid/core/services';
import { Notification } from '@aid/notifications/types/classes';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '@aid/core/services/organization.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'aid-notifications',
  template: `
    <div fxLayout="column" class="full-height">
      <aid-sidenav-header
        [title]="'notifications'"
        (close)="onClose()"
      ></aid-sidenav-header>
      <aid-notifications-list
        [notifications]="notifications$ | async"
        [loading]="loading$ | async"
        (loadMore)="onLoadMore()"
        (remove)="onRemove($event)"
        (read)="onRead($event)"
        (unread)="onUnread($event)"
        (readAll)="onReadAll()"
        (select)="onSelect($event)"
      >
      </aid-notifications-list>
    </div>
  `,
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();

  notifications$: Observable<Notification[]>;
  loading$: Observable<boolean>;

  constructor(
    private notificationsService: NotificationsService,
    private organizationService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private sidenavService: SidenavService
  ) {}

  ngOnInit() {
    this.notificationsService.refresh();

    this.notifications$ = this.notificationsService.subscribeValues$();
    this.loading$ = this.notificationsService.loading$;
  }

  onRemove(notification: Notification) {
    this.notificationsService.delete(notification.id).subscribe();
  }

  onRead(notification: Notification) {
    notification.read = true;
    this.notificationsService.edit(notification).subscribe();
  }

  onUnread(notification: Notification) {
    notification.read = false;
    this.notificationsService.edit(notification).subscribe();
  }

  onReadAll() {
    this.notificationsService.readAll().subscribe();
  }

  onLoadMore() {
    this.notificationsService.loadNextPage();
  }

  onClose() {
    this.sidenavService.close();
  }

  onSelect(notification: Notification) {
    this.notificationsService
      .read(notification)
      .pipe(
        switchMap(() => from(this.notificationsService.navigate(notification)))
      )
      .subscribe(() => this.onClose());
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
