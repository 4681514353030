import { BehaviorSubject, Observable } from 'rxjs';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PaginationResponse } from '@aid/shared/types/interfaces';
import { HttpClient } from '@angular/common/http';

export abstract class PaginationService<T> {
  protected values = new BehaviorSubject<T[]>([]);
  protected queryParams = new BehaviorSubject<QueryParams>(
    this.getInitialQueryParams()
  );
  protected loading = new BehaviorSubject<boolean>(null);
  protected count = new BehaviorSubject<number>(null);
  protected hasNext = new BehaviorSubject<boolean>(null);
  protected loadMore = false;
  protected cursor = new BehaviorSubject<string>(null);

  protected constructor(protected _http: HttpClient, protected cache = false) {}

  abstract get url(): string;

  protected getInitialQueryParams() {
    return new QueryParams();
  }

  refresh() {
    this.values = new BehaviorSubject<T[]>(null);
    this.queryParams = new BehaviorSubject<QueryParams>(
      this.getInitialQueryParams()
    );
    this.loading = new BehaviorSubject<boolean>(null);
    this.count = new BehaviorSubject<number>(null);
  }

  search(search: string) {
    const queryParams = {
      ...this.queryParams.value,
      ...new QueryParams()
    };

    if (search) {
      queryParams.search = search;
    } else {
      delete queryParams.search;
    }

    this.queryParams.next(queryParams);
  }

  loadNextPage() {
    if (!this.hasNext.value) {
      return;
    }
    if (this.loading.value) {
      return;
    }
    const queryParams: QueryParams = {
      ...this.queryParamsValue
    };

    if (this.cursor.value) {
      queryParams.cursor = this.cursor.value;
      delete queryParams.page;
    } else {
      delete queryParams.cursor;
      queryParams.page = this.queryParamsValue.page + 1;
    }

    this.loadMore = true;
    this.queryParams.next(queryParams);
  }

  changePage(pageNumber: number, pageSize: number) {
    const queryParams = new QueryParams();
    queryParams.page = pageNumber;
    queryParams.pageSize = pageSize;
    this.queryParams.next({ ...this.queryParams.value, ...queryParams });
  }

  changeParams(queryParams: QueryParams) {
    this.queryParams.next({ ...queryParams });
  }

  subscribeValues$(): Observable<T[]> {
    return this.queryParams.pipe(
      filter(value => !!value && this.filterSubscribe()),
      tap(() => this.loading.next(true)),
      switchMap(params => this.list(params)),
      tap((response: PaginationResponse<T>) => this.count.next(response.count)),
      tap((response: PaginationResponse<T>) =>
        this.hasNext.next(!!response.next)
      ),
      tap((response: PaginationResponse<T>) => this.setCursor(response)),
      map((response: PaginationResponse<T>) => response.results),
      switchMap(results => {
        this.setValues(results);
        this.loadMore = false;
        return this.values.asObservable();
      }),
      tap(() => this.loading.next(false))
    );
  }

  list(queryParams: QueryParams): Observable<PaginationResponse<T>> {
    const params = this.getParams(queryParams);
    return this._http.get<PaginationResponse<T>>(`${this.url}`, { params });
  }

  get loading$() {
    return this.loading.asObservable();
  }

  get count$() {
    return this.count.asObservable();
  }

  get hasNext$() {
    return this.hasNext.asObservable();
  }

  get queryParams$() {
    return this.queryParams.asObservable();
  }

  get queryParamsValue() {
    return this.queryParams.getValue();
  }

  get pageSize$(): Observable<number> {
    return this.queryParams$.pipe(map(params => params.pageSize));
  }

  get pageIndex$(): Observable<number> {
    return this.queryParams$.pipe(map(params => params.page));
  }

  getParams(queryParams: QueryParams) {
    const pageSize = queryParams ? queryParams.pageSize.toString() : '20';
    const params: any = {
      ...queryParams,
      pageSize
    };
    if (queryParams.page) {
      params.page = queryParams.page.toString();
    } else {
      delete params.page;
    }
    if (queryParams.cursor) {
      params.cursor = queryParams.cursor;
    }
    return params;
  }

  /**
   * Method used for subclasses to stop the observable chain
   * For example if the route is a nested one and we need to wait for a
   * specific resource
   */
  protected filterSubscribe(): boolean {
    return true;
  }

  protected setValues(values: T[]) {
    if (!(this.cache && this.loadMore)) {
      this.values.next(values);
    } else {
      this.values.next([...this.values.value, ...values]);
    }
  }

  private setCursor(response: PaginationResponse<T>) {
    const cursor = this.getCursorFromURL(response.next as string);
    this.cursor.next(cursor);
  }

  private getCursorFromURL(url: string) {
    const name = 'cursor';
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(url);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}
