import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Notification } from '@aid/notifications/types/classes';
import { NotificationIconService } from '@aid/notifications/services';

@Component({
  selector: 'aid-notification-item',
  template: `
    <div
      fxLayout="row"
      fxLayoutGap="12px"
      fxLayoutAlign="start center"
      class="notification-item"
      (click)="onSelect()"
    >
      <div *ngIf="!notification.read" class="not-read"></div>

      <div class="icon-container" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon-container__size" [svgIcon]="icon"></mat-icon>
      </div>

      <div>
        <div class="mat-subtitle-2">
          {{ notification.title | translate }}
        </div>
        <div class="mat-subtitle-2">
          {{ notification.message }}
        </div>
        <div class="mat-caption date">
          {{ notification.created | date: 'short' }}
        </div>
      </div>

      <div class="actions" fxLayoutGap="4px">
        <button
          class="actions__button"
          mat-icon-button
          (click)="onRemove(); $event.stopPropagation()"
        >
          <mat-icon class="actions__icon" svgIcon="delete"></mat-icon>
        </button>

        <button
          fxLayout="row"
          fxLayoutAlign="center center"
          class="actions__button"
          mat-icon-button
          (click)="onRead(); $event.stopPropagation()"
        >
          <div
            class="actions__read"
            [ngClass]="{ 'not-read-color': !notification.read }"
          ></div>
        </button>
      </div>
    </div>
    <mat-divider *ngIf="!last"></mat-divider>
  `,
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: Notification;
  @Input() last: boolean;

  @Output() select = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() read = new EventEmitter();
  @Output() unread = new EventEmitter();

  constructor(private notificationIconService: NotificationIconService) {}

  ngOnInit() {}

  get icon() {
    return this.notificationIconService.getIcon(this.notification);
  }

  onSelect() {
    this.select.emit(this.notification);
  }

  onRemove() {
    this.remove.emit();
  }

  onRead() {
    if (this.notification.read) {
      return this.unread.emit();
    }
    return this.read.emit();
  }
}
