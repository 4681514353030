import { Injectable } from '@angular/core';
import { CompanyPhotoType } from '@aid/auth/types/enums';
import { UploadPhotoDialogComponent } from '@aid/shared/components/upload-photo-dialog/upload-photo-dialog.component';
import { UploadPhotoDetails } from '@aid/shared/types/classes';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root'
})
export class UploadPhotoService {
  constructor(private dialog: MatDialog) {}

  onOpenUploadModal(type: CompanyPhotoType) {
    const uploadPhotoDetails = this.uploadPhotoDetails(type);
    return this.dialog
      .open(UploadPhotoDialogComponent, { data: uploadPhotoDetails })
      .afterClosed();
  }
  private uploadPhotoDetails(type: CompanyPhotoType) {
    const uploadPhotoDetails = new UploadPhotoDetails();
    if (type === this.logoType) {
      uploadPhotoDetails.details = 'png-details';
      uploadPhotoDetails.acceptedFile = '.png';
      uploadPhotoDetails.roundCropper = false;
      uploadPhotoDetails.title = 'add-logo-photo';
      uploadPhotoDetails.aspectRatio = 4;
      uploadPhotoDetails.horizontalResize = 250;
      uploadPhotoDetails.verticalResize = 80;

      return uploadPhotoDetails;
    }

    if (type === this.faviconType) {
      uploadPhotoDetails.details = 'png-details';
      uploadPhotoDetails.acceptedFile = '.png';
      uploadPhotoDetails.roundCropper = false;
      uploadPhotoDetails.title = 'add-favicon-photo';
      uploadPhotoDetails.aspectRatio = 1;
      uploadPhotoDetails.horizontalResize = 50;
      uploadPhotoDetails.verticalResize = 50;

      return uploadPhotoDetails;
    }

    uploadPhotoDetails.details = 'photo-format-accepted';
    uploadPhotoDetails.roundCropper = true;
    uploadPhotoDetails.title = 'add-profile-photo';
    uploadPhotoDetails.aspectRatio = 1;
    uploadPhotoDetails.horizontalResize = 125;
    uploadPhotoDetails.verticalResize = 125;

    return uploadPhotoDetails;
  }
  get logoType() {
    return CompanyPhotoType.LOGO;
  }
  get faviconType() {
    return CompanyPhotoType.FAVICON;
  }
}
