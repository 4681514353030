import { NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
  parse(value: any): Date | null {
    const language = localStorage.getItem(environment.localStorage.language);
    const date =
      language === 'en'
        ? moment(value, 'DD/MM/YYYY', true)
        : moment(value, 'DD.MM.YYYY', true);
    return date.toDate();
  }

  format(date: Date, displayFormat: any): string {
    const language = localStorage.getItem(environment.localStorage.language);
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return language === 'en'
        ? `${this.parseDigit(day)}/${this.parseDigit(month)}/${year}`
        : `${this.parseDigit(day)}.${this.parseDigit(month)}.${year}`;
    }
    return super.format(date, displayFormat);
  }
  private parseDigit(n: number) {
    return ('00' + n).slice(-2);
  }
}
