import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'aid-sidenav-header',
  template: `
    <div
      class="sidenav-header"
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayoutAlign="start center"
    >
      <button mat-icon-button (click)="close.emit()">
        <mat-icon svgIcon="new_close"></mat-icon>
      </button>
      <div class="mat-headline-6  basic-color-900">{{ title | translate }}</div>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./sidenav-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavHeaderComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() title: string;

  constructor() {}

  ngOnInit() {}
}
