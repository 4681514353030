import { Injectable } from '@angular/core';
import { CrudService } from '@aid/shared/services';
import { HttpClient } from '@angular/common/http';
import { ProductPartner } from '@aid/partnerships/types/classes/product-partner.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProductPartnersService extends CrudService<ProductPartner> {
  private _productPartner$ = new BehaviorSubject<ProductPartner>(null);

  get url() {
    return 'product-partners';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  get(valueId: number, force?: boolean): Observable<ProductPartner> {
    if (!force && this._productPartner$.value) {
      return this._productPartner$.asObservable();
    }

    return super
      .get(valueId, force)
      .pipe(tap(productPartner => this._productPartner$.next(productPartner)));
  }

  get productPartner$(): Observable<ProductPartner> {
    return this._productPartner$.asObservable();
  }

  getProductPartner(id: number): Observable<ProductPartner> {
    return this.http.get<ProductPartner>(`${this.url}/${id}`);
  }
}
