import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@aid/auth/services';
import { UserService } from '@aid/core/services';
import { first, map } from 'rxjs/operators';
import { User } from '@aid/core/types/classes/user.class';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const accessToken = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);

    if (accessToken && refreshToken) {
      return this.userService.get().pipe(
        first(),
        map((user: User) => {
          if (user.defaultOrganization) {
            this.router.navigate(['']);
            return false;
          }
          return true;
        })
      );
    }

    this.router.navigate(['']);
    return false;
  }
}
