import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyRegistration } from '@aid/auth/types/classes/company-registration.class';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class RegisterCompanyService {
  get url(): string {
    return `verifications`;
  }

  constructor(private http: HttpClient) {}

  register(company: CompanyRegistration) {
    const logo = company.logo ? company.logo.file : null;
    const favicon = company.favicon ? company.favicon.file : null;
    if (company.logo) {
      delete company.logo.file;
    }
    if (company.favicon) {
      delete company.favicon.file;
    }

    return this.http.post<CompanyRegistration>(this.url, company).pipe(
      switchMap(response => this.uploadLogo(response, logo, 'logo')),
      switchMap(response => this.uploadLogo(response, favicon, 'favicon'))
    );
  }

  private uploadLogo(
    company: CompanyRegistration,
    file: File,
    displayName: string
  ) {
    if (!file) {
      return of(company);
    }

    return this.http
      .put(company[displayName].uploadSignedUrl, file)
      .pipe(map(() => company));
  }
}
