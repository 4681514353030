import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ContractSignType } from '@aid/customers/types/enums';
import { User } from '@aid/core/types/classes';

@Component({
  selector: 'aid-brokerage-contract-sign-area',
  template: `
    <mat-card class="no-padding">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="mat-headline-6 container"
      >
        <div>{{ 'sign' | translate | titlecase }} {{ index }}</div>
        <button
          *ngIf="!descriptionControl.disabled"
          class="container__button"
          mat-icon-button
          (click)="onDelete()"
        >
          <mat-icon class="container__button__icon" svgIcon="delete"></mat-icon>
        </button>
      </div>

      <mat-divider></mat-divider>

      <div class="container__input" fxLayout="column" fxLayoutGap="16px">
        <aid-input
          [label]="'description'"
          [control]="descriptionControl"
        ></aid-input>

        <aid-select
          [label]="'signature-owner'"
          [control]="signatureControl"
          [options]="contractSignTypes"
          [translate]="true"
          [clear]="false"
          [filterable]="false"
        >
        </aid-select>
      </div>
    </mat-card>
  `,
  styleUrls: ['./brokerage-contract-sign-area.component.scss']
})
export class BrokerageContractSignAreaComponent implements OnInit {
  @Input() control: UntypedFormGroup;
  @Input() assignee: UntypedFormControl;
  @Input() index: number;
  @Input() user: User;
  @Input() checkBrokerSignature = true;
  @Output() remove = new EventEmitter();

  contractSignTypes;

  constructor() {}

  ngOnInit() {
    this.contractSignTypes = Object.values(ContractSignType)
      .filter(value =>
        this.user.signature || !this.checkBrokerSignature
          ? true
          : ContractSignType.BROKER !== value
      )
      .map(value => ({
        id: value,
        name: value
      }));
  }

  onDelete() {
    this.remove.emit();
  }

  get descriptionControl() {
    return this.control.get('description') as UntypedFormControl;
  }
  get signatureControl() {
    return this.control.get('contractSignType') as UntypedFormControl;
  }
}
