import { Directive } from '@angular/core';

/**
 * Directive used for the left buttons in the Table Header
 */
@Directive({
  selector: '[aidTableHeaderButtons]',
  exportAs: 'aidTableHeaderButtons'
})
export class TableHeaderButtonsDirective {}
