import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      const namesArray = value.split(' ');
      if (namesArray.length === 1) {
        return `${namesArray[0].charAt(0)}`.toUpperCase();
      }
      return `${namesArray[0].charAt(0)}${namesArray[
        namesArray.length - 1
      ].charAt(0)}`.toUpperCase();
    }

    return null;
  }
}
