import { ContractSignType } from '@aid/customers/types/enums';

export class MessageFileSign {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  description: string;
  type: ContractSignType;
  index: number;
  page: number;
  scale: number;
  pageHeight: number;
}
