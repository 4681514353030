export class OrganizationMember {
  /**
   * Organization id
   */
  id: number;
  /**
   * Member id
   */
  member: number;
  default: boolean;
  name: string;
  logo: string;
  slug: string;
  owner: boolean;

  constructor(organization: OrganizationMember) {
    Object.assign(this, organization);
  }
}
