import {
  Component,
  ComponentFactoryResolver,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ToastRef } from '@aid/toast/services';
import {
  toastAnimations,
  ToastAnimationState
} from '@aid/toast/animations/toast.animation';
import { AnimationEvent } from '@angular/animations';
import {
  TOAST_CONFIGURATION,
  TOAST_TEMPLATE,
  ToastConfig,
  ToastTemplate
} from '@aid/toast/utils/toast-config';

@Component({
  selector: 'aid-toast',
  template: `
    <div
      class="toast border-radius-8"
      matRipple
      [@fadeAnimation]="{
        value: animationState,
        params: {
          fadeIn: toastConfig.animation.fadeIn,
          fadeOut: toastConfig.animation.fadeOut
        }
      }"
      (@fadeAnimation.done)="onFadeFinished($event)"
    >
      <ng-template #container></ng-template>
    </div>
  `,
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast]
})
export class ToastComponent implements OnInit, OnDestroy {
  animationState: ToastAnimationState = 'default';

  private intervalId;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  entry: ViewContainerRef;

  constructor(
    private ref: ToastRef,
    @Inject(TOAST_TEMPLATE) private readonly toastTemplate: ToastTemplate,
    @Inject(TOAST_CONFIGURATION) public toastConfig: ToastConfig,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const component = this.toastTemplate.template;
    const factory = this.resolver.resolveComponentFactory(component);
    this.entry.clear();
    this.entry.createComponent(factory);

    this.intervalId = setTimeout(() => (this.animationState = 'closing'), 5000);
  }

  onClose() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const isFinished = this.animationState === 'closing';

    if (isFadeOut && isFinished) {
      this.onClose();
    }
  }
  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }
}
