import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aid-infinite-scroll',
  template: `
    <div
      fxLayout="column"
      class="overflow full-height"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="1000"
      [infiniteScrollThrottle]="300"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./infinite-scroll.component.scss']
})
export class InfiniteScrollComponent implements OnInit {
  @Output() scroll = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onScroll() {
    this.scroll.emit();
  }
}
