import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'aid-table-header',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div
          *ngIf="!!goBackText"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="header__back"
          (click)="back.emit()"
        >
          <mat-icon
            class="header__back--color"
            svgIcon="new_arrow_ios_back"
          ></mat-icon>
          <span class="mat-small"> {{ goBackText | translate }}</span>
        </div>
        <div class="mat-headline-6">{{ title | translate }}</div>
        <ng-content></ng-content>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="end end"
        fxLayoutGap="16px"
        class="header__search-filter full-height"
      >
        <aid-search
          *ngIf="canSearch"
          [canFilter]="canFilter"
          [searchPlaceholder]="searchPlaceholder"
          (search)="search.emit($event)"
          class="header__search-filter__search-box"
        ></aid-search>

        <mat-chip-list *ngIf="hasFilters" class="full-height">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="header__search-filter__chips full-height"
          >
            <mat-chip
              *ngFor="let filter of filters | unsortedKeys"
              (click)="onRemoveFilter(filter)"
              [disableRipple]="true"
              class="full-height header__chip-color"
            >
              <span class="mat-button">{{ filters[filter] | translate }}</span>
              <mat-icon
                class="header__chip-icon"
                matChipRemove
                (click)="onRemoveFilter(filter)"
                svgIcon="close"
              ></mat-icon>
            </mat-chip>
          </div>
        </mat-chip-list>

        <ng-content select="[aidTableHeaderButtons]"></ng-content>

        <button
          type="button"
          *ngIf="canFilter"
          mat-stroked-button
          color="white"
          onclick="blur()"
          (click)="onFilter()"
        >
          <mat-icon class="icon" svgIcon="filter"></mat-icon>
        </button>
        <ng-container *aidHasPermission="permissions">
          <button
            *ngIf="canAdd"
            mat-raised-button
            color="primary"
            class="plus-button"
            (click)="add.emit()"
          >
            <mat-icon data-cy="add-project-btn" svgIcon="add"></mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() canAdd = true;
  @Input() canFilter = false;
  @Input() permissions: string[];
  @Input() canSearch = true;
  @Input() goBackText: string;
  @Input() searchPlaceholder = 'search';

  @Input() filters: { [id: string]: string };

  @Output() add = new EventEmitter();
  @Output() search = new EventEmitter<string>();
  @Output() back = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() removeFilter = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  get hasFilters() {
    return this.filters && Object.keys(this.filters).length > 0;
  }

  onFilter() {
    this.filter.emit();
  }

  onRemoveFilter(filterId: string) {
    this.removeFilter.emit(filterId);
  }
}
