import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Organization } from '@aid/core/types/classes/organization.class';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Reminder } from '@aid/reminders/types/classes';
import { OrganizationMember } from '@aid/core/types/classes/organization-member.class';
import { Router } from '@angular/router';
import { User } from '@aid/core/types/classes';
import { UserService } from '@aid/core/services';
import { SubscriptionStatus } from '@aid/subscriptions/types/enums';
import { DateAdapter } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HelpscoutDialogComponent } from '@aid/core/components/helpscout-dialog/helpscout-dialog.component';

@Component({
  selector: 'aid-app-bar',
  template: `
    <mat-toolbar class="toolbar--color">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="toolbar__left-item"
        >
          <div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <img class="toolbar__logo" [src]="organization.logo?.url" />
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <button
            mat-icon-button
            [matTooltip]="
              (customerMode ? 'customer-mode' : 'broker-mode') | translate
            "
          >
            <mat-icon
              (click)="toggleCustomerMode.emit()"
              class="toolbar__icon"
              [svgIcon]="!customerMode ? 'eye' : 'eye_off'"
            ></mat-icon>
          </button>
          <mat-divider [vertical]="true"></mat-divider>

          <button
            mat-icon-button
            [matTooltip]="'send-us-message' | translate"
            (click)="onShowBeacon()"
          >
            <mat-icon svgIcon="question_mark"></mat-icon>
          </button>
          <mat-divider [vertical]="true"></mat-divider>
          <button
            mat-icon-button
            class="toolbar__icon"
            [matTooltip]="'today-reminders' | translate"
            [disabled]="organizationSuspended"
            [matMenuTriggerFor]="remindersMenu"
            (click)="onLoadReminders()"
          >
            <mat-icon [matBadge]="badgeReminders" svgIcon="calendar"></mat-icon>
          </button>
          <mat-divider [vertical]="true"></mat-divider>
          <button
            mat-icon-button
            [matTooltip]="'notifications' | translate"
            [disabled]="organizationSuspended"
            (click)="onNotifications()"
          >
            <mat-icon
              [matBadge]="badgeNotifications"
              svgIcon="notification"
            ></mat-icon>
          </button>
          <mat-divider [vertical]="true"></mat-divider>

          <button
            mat-icon-button
            [matTooltip]="'change-language' | translate"
            [matMenuTriggerFor]="languageMenu"
            class="toolbar__flag"
          >
            <mat-icon svgIcon="{{ flag }}"></mat-icon>
          </button>

          <mat-menu #languageMenu="matMenu">
            <button mat-menu-item (click)="onChangeLanguage('en')">
              <mat-icon svgIcon="english_flag"></mat-icon>
              English
            </button>
            <button mat-menu-item (click)="onChangeLanguage('de')">
              <mat-icon svgIcon="german_flag"></mat-icon>
              German
            </button>
          </mat-menu>

          <mat-divider [vertical]="true"></mat-divider>

          <div
            fxLayout="row"
            fxLayoutGap="8px"
            fxLayoutAlign="center center"
            class="full-width padding-left-8"
          >
            <button
              mat-icon-button
              [matTooltip]="'profile' | translate"
              (click)="onProfile()"
            >
              <aid-avatar
                [image]="profilePicture"
                [name]="userName"
                [size]="40"
              >
              </aid-avatar>
            </button>
          </div>

          <mat-menu #remindersMenu="matMenu" [class]="'panel'">
            <div class="toolbar__reminders">
              <div class="mat-headline-5 no-margin">
                {{ 'today-reminders' | translate | sentancecase }}
              </div>
              <aid-reminder-item-option
                *ngFor="let reminder of reminders; let l = last"
                [reminder]="reminder"
                [currentMember]="currentMember"
                [last]="l"
                (select)="onSeeReminder(reminder)"
              ></aid-reminder-item-option>
              <div
                *ngIf="reminders && reminders.length === 0"
                class="mat-subtitle-2 text-center padding-vertical-32"
              >
                {{ 'no-reminders-for-today' | translate }}
              </div>
            </div>
            <button
              mat-button
              class="toolbar__see-all"
              color="primary"
              (click)="onSeeReminders()"
            >
              {{ 'see-all' | translate | lowercase }}
            </button>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  `,
  styleUrls: ['./app-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBarComponent implements OnInit {
  @Input() organization: Organization;
  @Input() user: User;
  @Input() reminders: Reminder[];
  @Input() currentMember: OrganizationMember;
  @Input() notificationBadge: number;
  @Input() reminderBadge: number;
  @Input() customerMode: boolean;

  @Output() logout = new EventEmitter();
  @Output() loadReminders = new EventEmitter();
  @Output() viewProfile = new EventEmitter();
  @Output() viewNotifications = new EventEmitter();
  @Output() toggleCustomerMode = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private dateAdapter: DateAdapter<any>,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {}

  onShowBeacon() {
    this.matDialog.open(HelpscoutDialogComponent, {
      autoFocus: false,
      disableClose: true
    });
  }

  onLoadReminders() {
    this.loadReminders.emit();
  }

  onSeeReminders() {
    this.router.navigate([this.currentMember.slug, 'reminders']);
  }

  onSeeReminder(reminder: Reminder) {
    const queryParams = { reminder: reminder.id };
    this.router.navigate([this.currentMember.slug, 'reminders'], {
      queryParams
    });
  }

  onProfile() {
    this.viewProfile.emit();
  }

  get flag() {
    const language = localStorage.getItem(environment.localStorage.language);

    if (language === 'de') {
      return 'german_flag';
    }
    if (language === 'en') {
      return 'english_flag';
    }
  }

  get organizationSuspended() {
    return this.organization.subscriptionStatus === SubscriptionStatus.CANCELED;
  }

  onChangeLanguage(language: string) {
    localStorage.setItem(environment.localStorage.language, language);
    this.translate.use(language);
    this.dateAdapter.setLocale(language);

    location.reload();
  }

  onNotifications() {
    this.viewNotifications.emit();
  }

  get badgeNotifications() {
    return this.notificationBadge ? this.notificationBadge.toString() : null;
  }

  get badgeReminders() {
    return this.reminderBadge ? this.reminderBadge.toString() : null;
  }

  get userName() {
    if (!this.userService.userValue) {
      return;
    }
    return this.userService.userValue.name;
  }

  get profilePicture() {
    if (this.user && this.user.profilePicture) {
      return this.user.profilePicture.url;
    }
    return null;
  }
}
