import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Notification } from '@aid/notifications/types/classes';

@Component({
  selector: 'aid-notifications-list',
  template: `
    <div
      fxLayout="column"
      class="overflow full-height"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="1000"
      [infiniteScrollThrottle]="300"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <ng-container *ngIf="notifications">
        <ng-container *ngIf="notifications.length !== 0; else noNotifications">
          <div class="padding-horizontal-32">
            <div class="full-width" fxLayoutAlign="end">
              <span class="mat-subtitle-2 pointer" (click)="onReadAll()">
                {{ 'mark-all-read' | translate | sentancecase }}
              </span>
            </div>
            <aid-notification-item
              *ngFor="let notification of notifications; let l = last"
              [notification]="notification"
              [last]="l"
              (remove)="onRemove(notification)"
              (select)="onSelect(notification)"
              (read)="onRead(notification)"
              (unread)="onUnread(notification)"
            ></aid-notification-item>
          </div>
        </ng-container>
        <ng-template #noNotifications>
          <div
            class="text-center mat-subtitle-1 full-height"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <mat-icon
              svgIcon="bell_off"
              class="notification__icon-size basic-light-color-300"
            ></mat-icon>
            <span class="mat-headline-5">{{
              'no-notifications' | translate | sentancecase
            }}</span>
          </div>
        </ng-template>
      </ng-container>
      <div *ngIf="loading" class="loading" fxLayoutAlign="center">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
    </div>
  `,
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnInit {
  @Input() notifications: Notification[];
  @Input() loading: boolean;

  @Output() loadMore = new EventEmitter();
  @Output() remove = new EventEmitter<Notification>();
  @Output() select = new EventEmitter<Notification>();
  @Output() read = new EventEmitter<Notification>();
  @Output() unread = new EventEmitter<Notification>();
  @Output() readAll = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onScroll() {
    this.loadMore.emit();
  }

  onReadAll() {
    this.readAll.emit();
  }

  onRead(notification: Notification) {
    this.read.emit(notification);
  }

  onUnread(notification: Notification) {
    this.unread.emit(notification);
  }

  onRemove(notification: Notification) {
    this.remove.emit(notification);
  }

  onSelect(notification: Notification) {
    this.select.emit(notification);
  }
}
