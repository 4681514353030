import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'aid-form-actions',
  template: `
    <div fxFlex fxLayoutAlign="end" fxLayoutGap="8px" class="container">
      <button
        mat-flat-button
        class="action__cancel"
        (click)="onClickLeft()"
        type="button"
      >
        {{ buttonLeft | translate | sentancecase }}
      </button>
      <button
        mat-flat-button
        [color]="buttonRightColor"
        class="action__active"
        [disabled]="disable"
        [ngClass]="{ action__disabled: disable }"
        type="button"
        (click)="onClickRight()"
        data-cy="customer-save"
      >
        <div fxLayoutAlign="center center">
          <mat-progress-spinner
            *ngIf="loading"
            mode="indeterminate"
            [diameter]="24"
            [strokeWidth]="3"
          >
          </mat-progress-spinner>
        </div>

        <span *ngIf="!loading">
          {{ buttonRight | translate | sentancecase }}
        </span>
      </button>
    </div>
  `,
  styleUrls: ['./form-actions.component.scss']
})
export class FormActionsComponent implements OnInit {
  @Input() buttonLeft = 'cancel';
  @Input() buttonRight = 'save';
  @Input() disable = false;
  @Input() buttonRightColor: ThemePalette = 'primary';

  @Input() loading = false;

  @Output() clickLeft = new EventEmitter();
  @Output() clickRight = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClickLeft() {
    if (!this.loading) {
      this.clickLeft.emit();
    }
  }

  onClickRight() {
    if (!this.loading) {
      this.clickRight.emit();
    }
  }
}
