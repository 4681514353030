import { Organization } from '@aid/core/types/classes/organization.class';

export class Invitation {
  id: number;
  email: string;
  expireDate: string;
  firstName: string;
  lastName: string;

  constructor(invitation: Invitation) {
    Object.assign(this, invitation);
  }

  getTitle() {
    return '';
  }
}

export class UserInvitation extends Invitation {
  organization: Organization;
  role: string;

  constructor(invitation: UserInvitation) {
    super(invitation);
    this.organization = invitation.organization;
  }

  getTitle() {
    return `You have been invited to ${this.organization.name}`;
  }
}
