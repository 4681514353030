import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@aid/shared/shared.module';

import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromService from './services';

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [SharedModule],
  providers: [...fromService.services]
})
export class NotificationsModule {
  public static forRoot(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [fromService.NotificationsService]
    };
  }
}
