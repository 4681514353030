import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@aid/core/services';

@Component({
  selector: 'aid-no-organization',
  template: `
    <div
      class="flex full-height full-width page--color"
      fxLayoutAlign="center center"
      fxLayout="column"
    >
      <div
        class="mat-headline-4 page__text--color"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="32px"
      >
        <span>
          You don't belong to any organization!
        </span>
        <span>
          Please contact your organization admin!
        </span>
      </div>
      <div>
        <button
          mat-raised-button
          (click)="onLogOut()"
          class="page__button--text-color"
        >
          {{ 'logout' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./no-organization.component.scss']
})
export class NoOrganizationComponent implements OnInit {
  constructor(private userService: UserService, private router: Router) {}

  ngOnInit() {}

  onLogOut() {
    this.userService.logOut();
    this.router.navigate(['auth']);
  }
}
