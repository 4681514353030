import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aid-display-upload-file',
  template: `
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutGap="32px"
      class="full-width full-height"
    >
      <div
        *ngIf="
          (!displayedUrl && !showDefault) || (!defaultUrl && !displayedUrl)
        "
        fxLayout="column"
        fxLayoutAlign="center center"
        class="border-dashed border-radius-8 pointer upload__attach padding-16"
        (click)="onFile()"
      >
        <mat-icon
          svgIcon="upload"
          color="accent"
          class="upload__icon"
        ></mat-icon>
        <span class="mat-subtitle-2 upload__text-color">
          {{ title | translate | sentancecase }}</span
        >

        <span
          *ngIf="verticalSize && horizontalSize"
          class="mat-small upload__text-color"
        >
          {{ 'size' | translate | titlecase }} {{ horizontalSize }}x{{
            verticalSize
          }}
          px</span
        >
        <span
          *ngIf="!willCropPhoto && !circle"
          class="mat-small upload__text-color"
        >
          {{ 'recommended-size' | translate | titlecase }} 2 Mb</span
        >

        <span class="mat-small upload__text-color" *ngIf="details">
          {{ details | translate }}
        </span>
      </div>
      <div *ngIf="displayedUrl || (defaultUrl && showDefault)">
        <div class="upload__attach" fxLayoutAlign="center center">
          <div class="upload__preview  ">
            <img
              [src]="displayedUrl || defaultUrl"
              alt=""
              [height]="heightImage"
              [width]="widthImage"
              [ngClass]="{
                'upload__preview--circle': circle
              }"
            />
            <mat-icon
              class="upload__preview__clear pointer"
              svgIcon="close"
              (click)="onCancel()"
            ></mat-icon>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./display-upload-file.component.scss']
})
export class DisplayUploadFileComponent implements OnInit {
  @Input() horizontalSize: number;
  @Input() verticalSize: number;
  @Input() title = 'upload-photo';
  @Input() details: string;

  @Input() circle = false;
  @Input() defaultUrl: string;
  @Input() displayedUrl: string;
  @Input() willCropPhoto: boolean;
  @Input() showDefault: boolean;

  @Input() width: number;
  @Input() height: number;

  @Output() upload: EventEmitter<void> = new EventEmitter();
  @Output() cancelPhoto: EventEmitter<void> = new EventEmitter<void>();

  widthImage: number;
  heightImage: number;
  constructor() {}

  ngOnInit() {
    if (this.circle) {
      this.widthImage = 150;
      this.heightImage = 150;
    } else {
      this.widthImage = this.horizontalSize;
      this.heightImage = this.verticalSize;
    }
  }

  onFile() {
    this.upload.emit();
  }

  onCancel() {
    this.cancelPhoto.emit();
  }
}
