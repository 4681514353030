import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '@aid/shared/components/snack-bar/snack-bar.component';
import { SnackBar } from '@aid/shared/types/classes/snack-bar.class';
import { SnackBarType } from '@aid/shared/types/enums/snack-bar-type.enum';
import { ErrorResponse } from '@aid/core/types/interfaces';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  success(
    message: string,
    duration: number = 2000,
    translationData?: { [key: string]: string | number }
  ) {
    const snackBar: SnackBar = {
      message,
      type: SnackBarType.SUCCESS,
      translationData
    };
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration,
      panelClass: ['snackbar'],
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      data: snackBar
    });
  }

  info(message: string, duration: number = 2000) {
    const snackBar: SnackBar = {
      message,
      type: SnackBarType.INFORMATION
    };
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration,
      panelClass: ['snackbar'],
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      data: snackBar
    });
  }

  error(message: string, duration: number = 2000) {
    const snackBar: SnackBar = {
      message,
      type: SnackBarType.ERROR
    };
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration,
      panelClass: ['snackbar'],
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      data: snackBar
    });
  }

  errorApi(error: HttpErrorResponse, duration: number = 6000) {
    const message = error.error.detail || 'error-went-wrong';
    this.error(message, duration);
  }
}
