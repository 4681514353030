import { Injectable } from '@angular/core';
import { InsuranceType } from '../types/classes/insurance-type.class';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '@aid/shared/services';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsuranceTypeService extends CrudService<InsuranceType> {
  private insuranceTypesObject = new BehaviorSubject<{
    [id: number]: InsuranceType;
  }>(null);

  get url() {
    return 'insurance-types';
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  afterList() {
    const object: { [id: string]: InsuranceType } = {};
    const insurancesTypes = this.values$.value || [];
    insurancesTypes.forEach(type => {
      object[type.id] = type;
    });
    this.insuranceTypesObject.next(object);
  }

  getInsurancesTypesObject(): { [id: string]: InsuranceType } {
    return this.insuranceTypesObject.getValue();
  }

  getInsurancesTypesObject$(): Observable<{ [id: number]: InsuranceType }> {
    return this.insuranceTypesObject.asObservable();
  }

  filterInsuranceTypes(
    insuranceTypes: InsuranceType[],
    organizationInsuranceTypes,
    found: boolean
  ) {
    return insuranceTypes.filter(insuranceType => {
      const value = organizationInsuranceTypes.find(
        organizationInsuranceType => {
          return organizationInsuranceType.insuranceType === insuranceType.id;
        }
      );
      return found ? !!value : !value;
    });
  }

  search(search: string): InsuranceType[] {
    if (!search) {
      return this.values$.value;
    }

    return this.values$.value.filter(
      insuranceType =>
        insuranceType.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }
}
