import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'aid-session-timeout',
  template: `
    <aid-dialog [title]="'session-timeout'" (close)="onClose()">
      <div fxLayout="column" fxLayoutGap="32px">
        <div class="mat-subtitle-2">
          {{ 'session-timeout-message' | translate | sentancecase }}
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <aid-loading-button [label]="'login'" (press)="onClose()">
          </aid-loading-button>
        </div>
      </div>
    </aid-dialog>
  `,
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SessionTimeoutComponent>) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
