import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppModeService {
  private readonly CUSTOMER_MODE_STATE = 'aid_customer_mode';
  private customerMode = new BehaviorSubject<boolean>(null);

  constructor() {}

  initFromLocalStorage() {
    if (!localStorage.getItem(this.CUSTOMER_MODE_STATE)) {
      localStorage.setItem(this.CUSTOMER_MODE_STATE, 'false');
    }
    const customerMode =
      localStorage.getItem(this.CUSTOMER_MODE_STATE) === 'true';
    this.customerMode.next(customerMode);
  }

  get customerMode$() {
    return this.customerMode.asObservable();
  }

  toggle() {
    this.customerMode.next(!this.customerMode.getValue());
    localStorage.setItem(
      this.CUSTOMER_MODE_STATE,
      this.customerMode.getValue() ? 'true' : 'false'
    );
  }
}
