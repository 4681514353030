import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationService } from '@aid/core/services/organization.service';
import { SubscriptionStatus } from '@aid/subscriptions/types/enums';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSuspendedGuard implements CanActivate {
  constructor(
    private router: Router,
    private organizationService: OrganizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const organization = this.organizationService.organization;

    if (
      organization.subscriptionStatus === SubscriptionStatus.CANCELED ||
      organization.suspended
    ) {
      this.router.navigate([
        this.organizationService.organization.slug,
        'suspended'
      ]);
      return false;
    }

    return true;
  }
}
