import { Injectable } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  constructor() {}

  getLastChildRoute(route: ActivatedRoute): ActivatedRoute {
    if (route.children.length !== 0) {
      if (route.children[0].children.length !== 0) {
        return route.children[0];
      }
      return this.getLastChildRoute(route.children[0]);
    }

    return route;
  }

  getPath(segments: UrlSegment[]) {
    return segments.map(segment => segment.path);
  }
}
