import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'aid-options-menu-title',
  template: `
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="mat-headline-6 padding-horizontal-32 padding-vertical-16"
    >
      <ng-content select="[aidGoBack]"></ng-content>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="full-width"
      >
        <span *ngIf="sentencecase">
          {{ title | translate | sentancecase }}
        </span>
        <span *ngIf="!sentencecase">
          {{ title | translate }}
        </span>
        <ng-content select="[aidViewButtons]"></ng-content>
      </div>
    </div>
    <mat-divider></mat-divider>
  `,
  styleUrls: ['./options-menu-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionsMenuTitleComponent implements OnInit {
  @Input() title: string;
  @Input() sentencecase = true;

  constructor() {}

  ngOnInit() {}
}
