import { Injectable } from '@angular/core';
import { CrudService } from '@aid/shared/services';
import { CustomerBankAccount } from '@aid/customers/types/classes';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Params } from '@angular/router';

@Injectable()
export class CustomerBankAccountService extends CrudService<
  CustomerBankAccount
> {
  private clientId: number;
  private clientChanged: boolean;
  private addPanelOpened = new BehaviorSubject<boolean>(null);

  constructor(protected http: HttpClient) {
    super(http, false);
  }

  setClientId(clientId: number) {
    if (this.clientId !== clientId) {
      this.clientChanged = true;
    }
    this.clientId = clientId;
  }

  get url(): string {
    return `clients/${this.clientId}/bank-accounts`;
  }

  list(params?: Params): Observable<CustomerBankAccount[]> {
    if (this.clientChanged) {
      return super.list(params);
    }
    this.clientChanged = false;
    return this.values$.asObservable();
  }

  get addPanel$() {
    return this.addPanelOpened.asObservable();
  }

  openAddPanel() {
    this.addPanelOpened.next(true);
  }

  closeAddPanel() {
    this.addPanelOpened.next(false);
  }
}
