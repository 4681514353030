import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longTime'
})
export class LongTimePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return null;
    }
    const time: string[] = value.replace(' ', '').split(':');
    const hours = parseInt(time[0], 10);
    let minutes = parseInt(time[1].slice(0, 2), 10).toString();
    const meridian = time[1].slice(2, 4);

    if (meridian.toLowerCase() === 'pm') {
      switch (hours) {
        case 12: {
          return 12 + ':' + minutes;
        }
        default: {
          return hours + 12 + ':' + minutes;
        }
      }
    }
    if (parseInt(minutes, 10) < 10) {
      minutes = '0' + minutes;
    }
    if (meridian.toLowerCase() === 'am' && hours === 12) {
      return '00:' + minutes;
    }
    return hours + ':' + minutes;
  }
}
