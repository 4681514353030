import { Customer } from '@aid/customers/types/classes';
import * as moment from 'moment';
import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import { RemindersParams } from '@aid/reminders/types/interfaces';
import { Insurance } from '@aid/insurances/types/classes';
import { InsuranceType } from '@aid/administration/types/classes';
import { environment } from '../../../../environments/environment';

const LANGUAGE = localStorage.getItem(environment.localStorage.language);

export class RemindersFilter {
  startDate: Date;
  endDate: Date;
  customer: Customer;
  insurance: Insurance;

  public static get formatDate() {
    return 'YYYY-MM-DD';
  }

  private get startDateAsLocaleDateString() {
    return this.startDate.toLocaleDateString(LANGUAGE);
  }

  private get endDateAsLocaleDareString() {
    return this.endDate.toLocaleDateString(LANGUAGE);
  }

  get params(): RemindersParams {
    const params: RemindersParams = {};
    if (this.startDate) {
      params.startDate = moment(this.startDate).format(
        RemindersFilter.formatDate
      );
    }
    if (this.endDate) {
      params.endDate = moment(this.endDate).format(RemindersFilter.formatDate);
    }
    if (this.customer) {
      params.customer = this.customer.id;
    }
    if (this.insurance) {
      params.insurance = this.insurance.id;
    }
    return params;
  }

  get queryParams(): QueryParams {
    const queryParams: QueryParams = new QueryParams();
    queryParams.pageSize = 20;

    if (this.startDate) {
      queryParams.from_date = moment(this.startDate).toISOString();
    }
    if (this.endDate) {
      queryParams.to_date = moment(this.endDate)
        .set('hour', 23)
        .set('minutes', 59)
        .toISOString();
    }
    if (this.customer) {
      queryParams.customer = this.customer.id;
    }
    if (this.insurance) {
      queryParams.insurance = this.insurance.id;
    }

    return queryParams;
  }

  get filters(): { [id: string]: string } {
    const filters: { [id: string]: string } = {};
    if (this.startDate) {
      filters.startDate = this.startDateAsLocaleDateString;
    }
    if (this.endDate) {
      filters.endDate = this.endDateAsLocaleDareString;
    }
    if (this.customer) {
      filters.customer = this.customer.client.name;
    }
    if (this.insurance) {
      filters.insurance = `${
        (this.insurance.insuranceType as InsuranceType).abbreviation
      } - ${this.insurance.insuranceNumber}`;
    }
    return filters;
  }

  static createFromParams(
    params: RemindersParams,
    currentFilter: RemindersFilter
  ): RemindersFilter {
    const filter = new RemindersFilter();
    if (params.startDate) {
      filter.startDate = moment(
        params.startDate,
        RemindersFilter.formatDate
      ).toDate();
    }
    if (params.endDate) {
      filter.endDate = moment(
        params.endDate,
        RemindersFilter.formatDate
      ).toDate();
    }

    if (
      currentFilter &&
      currentFilter.customer &&
      currentFilter.customer.id === +params.customer
    ) {
      filter.customer = currentFilter.customer;
    }

    if (
      currentFilter &&
      currentFilter.insurance &&
      currentFilter.insurance.id === +params.insurance
    ) {
      filter.insurance = currentFilter.insurance;
    }

    return filter;
  }

  equals(filter: RemindersFilter) {
    if (!filter) {
      return false;
    }
    return (
      JSON.stringify(this.queryParams) === JSON.stringify(filter.queryParams)
    );
  }

  removeFilter(filterId: string): RemindersFilter {
    const filter = new RemindersFilter();

    if (filterId !== 'startDate') {
      filter.startDate = this.startDate;
    }
    if (filterId !== 'endDate') {
      filter.endDate = this.endDate;
    }
    if (filterId !== 'customer') {
      filter.customer = this.customer;
    }
    if (filterId !== 'insurance' && filterId !== 'customer') {
      filter.insurance = this.insurance;
    }
    return filter;
  }
}
