import { ProfilePicture } from '@aid/profile/types/classes/profile-picture.class';
import { DialogData } from '@aid/profile/types/classes/dialog-data.class';
import { Signature } from '@aid/profile/types/classes/signature.class';
import { EmailConfiguration } from '@aid/profile/types/classes/email-configuration.class';

export const components = [
  ProfilePicture,
  DialogData,
  Signature,
  EmailConfiguration
];
export * from '@aid/profile/types/classes/profile-picture.class';
export * from '@aid/profile/types/classes/dialog-data.class';
export * from '@aid/profile/types/classes/signature.class';
export * from '@aid/profile/types/classes/email-configuration.class';
export * from '@aid/profile/types/classes/email-signature.class';
