import { Rectangle } from '@aid/customers/types/classes';
import { ContractSignType } from '@aid/customers/types/enums';
import { MessageFileSign } from '@aid/chat/types/classes';

export class ContractSignArea {
  id: number;
  index: number;
  type: ContractSignType;
  description: string;
  area: Rectangle;
  page: number;
  scale: number;
  pageHeight: number;

  getMessageFileSign(): MessageFileSign {
    const sign = new MessageFileSign();
    sign.index = this.index;
    sign.description = this.description;
    sign.x = this.area.x;
    sign.y = this.area.y;
    sign.type = this.type;
    sign.height = this.area.height;
    sign.width = this.area.width;
    sign.page = this.page;
    sign.scale = this.scale;
    sign.pageHeight = this.pageHeight;

    return sign;
  }
}
