import { Inject, Injectable, Injector, Type } from '@angular/core';
import { ToastComponent } from '../components';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import {
  TOAST_CONFIGURATION,
  TOAST_TEMPLATE,
  TOAST_DATA,
  ToastConfig,
  ToastTemplate
} from '@aid/toast/utils/toast-config';
import { ToastRef } from '@aid/toast/utils/toast-ref';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private lastToast: ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIGURATION) private toastConfig: ToastConfig
  ) {}

  show(component: Type<any>, data?: any) {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({
      positionStrategy
    });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;
    const toastTemplate: ToastTemplate = {
      template: component,
      data
    };

    const injector = this.getInjector(
      toastTemplate,
      toastRef,
      this.parentInjector
    );
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef.attach(toastPortal);

    return toastRef;
  }

  getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top(this.getPosition())
      .right(this.toastConfig.position.right + 'px');
  }

  getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : this.toastConfig.position.top;

    return position + 'px';
  }

  getInjector(
    data: ToastTemplate,
    toastRef: ToastRef,
    parentInjector: Injector
  ) {
    const tokens = new WeakMap();
    tokens.set(TOAST_TEMPLATE, data);
    tokens.set(TOAST_DATA, data.data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
