import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { TabHeaderItem } from '@aid/shared/types/classes/tab-header-item.class';
import { TabsService } from '@aid/shared/services';

@Component({
  selector: 'aid-tabs-header',
  template: `
    <div class="relative container">
      <mat-tab-group
        [disableRipple]="true"
        color="primary"
        class="tab-group"
        (click)="onSelect()"
      >
        <ng-container *ngFor="let item of items">
          <mat-tab
            class="tab"
            aria-label="{{ item.baseRoute }}"
            label="{{ item.label | translate | sentancecase }}"
            [disabled]="item.disabled"
          ></mat-tab>
        </ng-container>
      </mat-tab-group>

      <div class="right">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./tabs-header.component.scss']
})
export class TabsHeaderComponent implements OnInit, AfterViewInit {
  @Input() items: TabHeaderItem[];

  @Input('route') set _route(route: string[]) {
    this.route = route;
    if (!this.tabGroup || !this.tabGroup._tabs || !route) {
      return;
    }
    this.navigateToTab();
  }
  @Output() navigateTo: EventEmitter<string> = new EventEmitter();

  route: string[];
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.navigateToTab();
  }
  routesMatch(tabRoute: string[]) {
    for (let i = 0; i < tabRoute.length; i++) {
      if (tabRoute[i] !== this.route[i]) {
        return false;
      }
    }

    return true;
  }

  onSelect() {
    this.navigateTo.emit(
      this.tabGroup._tabs.toArray()[this.tabGroup.selectedIndex].ariaLabel
    );
  }
  private navigateToTab() {
    this.tabGroup._tabs.forEach((item: MatTab, index: number) => {
      const tabRoute: string[] = item.ariaLabel.split('/');
      if (this.routesMatch(tabRoute)) {
        this.tabGroup.selectedIndex = index;
      }
    });
  }
}
