import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import { OrganizationService } from '../services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const permissions = route.data.permissions;
    const noPermission = permissions.some(
      permission => !this.userService.hasPermission(permission)
    );

    if (noPermission) {
      this.router.navigate([this.organizationService.organization.slug]);
      return false;
    }
    return true;
  }
}
