import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { OrganizationService } from '@aid/core/services/organization.service';
import { AuthService } from '@aid/auth/services';
import { UserService } from '@aid/core/services';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FeaturesService } from '@aid/core/services/features.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private featuresService: FeaturesService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const organizationSlug = route.params.organizationSlug;
    const organization = this.organizationService.organizationMember;

    if (organization.slug !== organizationSlug) {
      this.router.navigate([organization.slug]);
      return true;
    }

    return combineLatest([
      this.organizationService.get(organization.id),
      this.userService.getPermissions(),
      this.featuresService.list(),
      this.userService.authenticateFirebase()
    ]).pipe(
      map(
        ([_organization, permissions, features, _]) =>
          !!permissions && !!features
      ),
      catchError(() => {
        this.logout();
        return of(false);
      })
    );
  }

  private logout() {
    this.userService.logOut();
    this.authService.setSharedLoading(false);
    this.router.navigate(['auth']);
  }
}
