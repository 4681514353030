import { InsuranceCommentsService } from '@aid/insurances/services/insurance-comments.service';
import { InsuranceLogsService } from '@aid/insurances/services/insurance-logs.service';
import { InsuranceRequestsService } from '@aid/insurances/services/insurance-requests.service';
import { EmailService } from '@aid/insurances/services/email.service';
import { InsurancesFilterService } from '@aid/insurances/services/insurances-filter.service';

export const services = [
  InsuranceCommentsService,
  InsuranceLogsService,
  InsuranceRequestsService,
  EmailService,
  InsurancesFilterService
];

export * from './insurances.service';
export * from './insurance-logs.service';
export * from './insurance-comments.service';
export * from './insurance-requests.service';
export * from './email.service';
