import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationInsurance } from '@aid/administration/types/classes/organization-insurance.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { InsuranceType } from '@aid/administration/types/classes';
import { Params } from '@angular/router';
import { UnredirectedRoutesService } from '@aid/core/services';

@Injectable({
  providedIn: 'root'
})
export class OrganizationInsuranceService {
  private provider$ = new BehaviorSubject<number>(null);

  get url(): string {
    return `providers/${this.provider$.value}/insurance-types`;
  }

  constructor(
    private http: HttpClient,
    private unredirectedRoutesService: UnredirectedRoutesService
  ) {
    this.unredirectedRoutesService.subscribeRoute('insurance-types');
  }

  setProvider(provider: number) {
    this.provider$.next(provider);
  }

  getProvider$() {
    return this.provider$.asObservable();
  }

  getProviderValue() {
    return this.provider$.value;
  }

  setProductPartner(productPartnerId: number) {
    const body = {
      productPartner: productPartnerId
    };
    return this.http.patch<OrganizationInsurance[]>(
      `${this.url}/product-partners`,
      body
    );
  }

  list(): Observable<OrganizationInsurance[]> {
    return this.http.get<OrganizationInsurance[]>(`${this.url}`);
  }

  get(insuranceTypeId: number): Observable<OrganizationInsurance> {
    return this.http.get<OrganizationInsurance>(
      `${this.url}/${insuranceTypeId}`
    );
  }

  getDetails(
    insuranceTypeId: number,
    providerId: number
  ): Observable<OrganizationInsurance> {
    this.setProvider(providerId);
    return this.http.get<OrganizationInsurance>(
      `${this.url}/${insuranceTypeId}/details`
    );
  }

  add(insuranceTypeId: number): Observable<OrganizationInsurance> {
    const body = {
      insuranceType: insuranceTypeId
    };
    return this.http.post<OrganizationInsurance>(this.url, body);
  }

  delete(insuranceTypeId: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/${insuranceTypeId}`);
  }

  addAll(): Observable<OrganizationInsurance[]> {
    return this.http.post<OrganizationInsurance[]>(`${this.url}/add`, {});
  }

  deleteAll(): Observable<void> {
    return this.http.delete<void>(`${this.url}/remove`, {});
  }

  edit(insuranceTypeId: number, body) {
    return this.http.patch(`${this.url}/${insuranceTypeId}`, body);
  }

  providerInsuranceTypes(providerId: number): Observable<InsuranceType[]> {
    const url = `insurance-types`;
    const params = { provider: providerId } as Params;
    return this.http.get<InsuranceType[]>(url, { params });
  }
}
