import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationService } from '@aid/core/services/organization.service';
import { UserService } from '@aid/core/services';

@Injectable({
  providedIn: 'root'
})
export class BillingGuard implements CanActivate {
  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (
      !this.userService.isOwner ||
      this.organizationService.organization.withoutSubscription
    ) {
      this.router.navigate([this.organizationService.organization.slug]);
      return false;
    }

    return true;
  }
}
