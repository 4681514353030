export enum NotificationType {
  REMINDER = 'reminder',
  DAMAGE = 'damage',
  CUSTOMER = 'customer',
  CUSTOMER_COMMENT = 'customer-comment',
  INSURANCE = 'insurance',
  INSURANCE_COMMENT = 'insurance-comment',
  MISSING_INSURANCE = 'missing-insurance',
  GDV_FILE_FINISHED = 'gdv-file-finished',
  COMMISSION_FILE_FINISHED = 'commission-file-finished',
  DOCUMENT_IMPORT_INSURANCE = 'document-import-insurance',
  DOCUMENT_IMPORT_CUSTOMER = 'document-import-customer',
  DOCUMENT_IMPORT_FILE = 'document-import-file'
}
