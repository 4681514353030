import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ContractSignArea, Rectangle } from '../../../customers/types/classes';
import { PDFDocumentProxy, PDFSource } from 'pdfjs-dist';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { User } from '@aid/core/types/classes';
import { ContractSignType } from '@aid/customers/types/enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aid-brokerage-contract-sign-configuration-pdf',
  template: `
    <div class="container full-width full-height" fxLayout="row">
      <div
        fxLayout
        fxLayoutAlign="center center"
        class="loading-shade"
        *ngIf="loading"
      >
        <mat-spinner diameter="48"></mat-spinner>
      </div>
      <div class="container__pdf" #pdf>
        <div #containerPdf>
          <canvas #canvas [width]="600" height="0"></canvas>
          <pdf-viewer
            #pdfViewer
            style="display: block; width: 600px;"
            [src]="pdfFile"
            [fit-to-page]="true"
            [show-all]="true"
            [autoresize]="false"
            (after-load-complete)="onAfterLoadPdf($event)"
            (page-rendered)="pageRendered($event)"
          ></pdf-viewer>
        </div>
      </div>

      <div *ngIf="!landscape" class="flex">
        <div
          class="container__signs full-width "
          fxLayout="column"
          fxLayoutGap="16px"
        >
          <mat-card
            *ngIf="checkBrokerSignature && !user.signature"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
          >
            <div>
              <mat-icon svgIcon="alert_triangle"></mat-icon>
            </div>
            <span class="mat-subtitle-2 basic-color-900">{{
              'no-broker-signature' | translate | sentancecase
            }}</span>
          </mat-card>

          <ng-container *ngIf="form.length > 0">
            <aid-brokerage-contract-sign-area
              [user]="user"
              *ngFor="let signForm of form.controls; let i = index"
              [control]="signForm"
              [checkBrokerSignature]="checkBrokerSignature"
              [index]="i + 1"
              (remove)="onRemoveSign(i)"
            ></aid-brokerage-contract-sign-area>
            <div fxLayout="row" fxLayoutAlign="end">
              <aid-loading-button
                [label]="'save'"
                [loading]="saving"
                [disabled]="form.invalid"
                (press)="onSave()"
              ></aid-loading-button>
            </div>
          </ng-container>
          <div
            *ngIf="form.length === 0"
            class="text-center full-height"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="8px"
          >
            <div class="mat-headline-6">{{ 'no-signs' | translate }}</div>
            <div class="mat-subheading-1-light">
              {{ 'no-signs-description' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="landscape"
        class="full-height full-width"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="16px"
      >
        <img
          src="assets/background/warning.png"
          alt="no"
          class="container__warning"
        />
        <div class="padding-8 mat-subtitle-2 basic-color-900">
          {{ 'accept-only-A4' | translate }}
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./brokerage-contract-sign-configuration-pdf.component.scss']
})
export class BrokerageContractSignConfigurationPdfComponent implements OnInit {
  @Input() src: string;
  @Input() file: File;
  @Input() saving: boolean;
  @Input() user: User;
  @Input() signs: ContractSignArea[];
  @Input() checkBrokerSignature = true;
  @Output() save = new EventEmitter<ContractSignArea[]>();

  @ViewChild('containerPdf', { static: true })
  containerPdf: ElementRef;
  @ViewChild('pdf', { static: true })
  pdf: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;

  loading: boolean;
  form: UntypedFormArray;
  pdfFile: PDFSource;
  landscape = false;

  private mouseDown: boolean;
  private lastMouseX: number;
  private lastMouseY: number;

  /**
   * The width and height for the current rectangle
   */
  private width: number;
  private height: number;

  private pdfHeight: number;
  private numberOfPages: number;

  private pageHeight: number;
  private pdfScale: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService
  ) {
    this.initForm();
  }

  private initializeCtx() {
    if (this.ctx) {
      return;
    }

    this.ctx = this.canvas.nativeElement.getContext('2d');
  }
  ngOnInit() {
    this.setPdfFile();
    this.loading = true;

    this.setSignatures();
    this.initializeCtx();

    this.canvas.nativeElement.addEventListener('mousedown', (ev: any) => {
      this.lastMouseX = ev.layerX;
      this.lastMouseY = ev.layerY;
      this.mouseDown = true;
    });

    this.canvas.nativeElement.addEventListener('mouseup', ev => {
      this.mouseDown = false;
      this.addRectangle();
      this.drawSignAreas();
    });

    this.canvas.nativeElement.addEventListener('mousemove', (ev: any) => {
      const mouseX = ev.layerX;
      const mouseY = ev.layerY;
      if (!this.mouseDown) {
        return;
      }

      this.drawCurrentRectangle(mouseX, mouseY);
    });
  }

  onRemoveSign(index: number) {
    this.form.removeAt(index);
    this.drawSignAreas();
  }

  pageRendered(e) {
    const source = e.source;

    if (
      this.checkBrokerSignature &&
      source.viewport.width > source.viewport.height
    ) {
      this.landscape = true;
      this.loading = false;
      this.pdf.nativeElement.remove();
      return;
    }
    const pageIndex = source.pdfPage.pageNumber;

    this.pdfScale = source.viewport.scale;

    if (pageIndex === this.numberOfPages) {
      // calculate the height
      this.pdfHeight = this.containerPdf.nativeElement.offsetHeight;
      this.pageHeight = this.pdfHeight / this.numberOfPages;
      this.canvas.nativeElement.height = this.pdfHeight;
      this.loading = false;
      this.drawSignAreas();
    }
  }

  onAfterLoadPdf(event: PDFDocumentProxy) {
    this.numberOfPages = event.numPages;
  }

  onSave() {
    const signs: ContractSignArea[] = [];
    this.form.controls.forEach((control, index) => {
      const sign = new ContractSignArea();
      sign.description = control.get('description').value;
      sign.index = index + 1;
      sign.area = { ...control.get('area').value };
      sign.scale = this.pdfScale;
      sign.page = control.get('page').value;
      sign.type = control.get('contractSignType').value;
      sign.area.y = Math.round(sign.area.y - (sign.page - 1) * this.pageHeight);
      sign.pageHeight = this.pageHeight;

      signs.push(sign);
    });

    this.save.emit(signs);
  }

  hasChanges() {
    return this.form.controls.length > 0;
  }

  initForm() {
    this.form = this.formBuilder.array([]);
  }

  setSignatures() {
    if (!this.signs || this.signs.length === 0) {
      return;
    }

    this.signs.forEach(sign => {
      const _form = this.formBuilder.group({
        area: sign.area,
        page: sign.page,
        description: [sign.description, Validators.required],
        contractSignType: [sign.type, Validators.required]
      });

      this.form.push(_form);
    });
  }

  private drawCurrentRectangle(mouseX: number, mouseY: number) {
    this.drawSignAreas();

    // draw the current rectangle
    this.width = mouseX - this.lastMouseX;
    this.height = mouseY - this.lastMouseY;

    const rectangle: Rectangle = {
      x: this.lastMouseX,
      y: this.lastMouseY,
      width: this.width,
      height: this.height
    };

    this.drawRectangle(rectangle);
  }

  private drawSignAreas() {
    this.ctx.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );

    // draw the previous rectangles
    for (let i = 0; i < this.form.length; i++) {
      const item: Rectangle = this.form.at(i).value.area;
      const title = `${this.translateService.instant('sign')} ${i + 1}`;
      this.drawRectangle(item, title);
    }
  }

  private drawRectangle(item: Rectangle, title?: string) {
    this.ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
    this.ctx.lineWidth = 1;

    this.ctx.beginPath();
    this.ctx.rect(item.x, item.y, item.width, item.height);
    this.ctx.fill();
    if (title) {
      this.ctx.font = '14px sans-serif';
      this.ctx.fillStyle = 'black';
      this.ctx.fillText(title, item.x + 5, item.y + 15);
    }

    this.ctx.stroke();
  }

  private addRectangle() {
    const page = Math.ceil(this.lastMouseY / this.pageHeight);
    const rectangle = new Rectangle();
    rectangle.x = this.lastMouseX;
    rectangle.y = this.lastMouseY;

    if (this.width < 80) {
      this.width = 80;
    }
    if (this.height < 30) {
      this.height = 30;
    }

    rectangle.width = this.width;
    rectangle.height = this.height;

    if (this.width && this.height && this.width !== 0 && this.height !== 0) {
      const form = this.formBuilder.group({
        area: rectangle,
        page,
        description: ['', Validators.required],
        contractSignType: [ContractSignType.CUSTOMER, Validators.required]
      });
      this.form.push(form);
    }
  }

  private setPdfFile() {
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.pdfFile = e.target.result;
      };

      reader.readAsArrayBuffer(this.file);
    }
  }
}
