import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RegisterGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const inviteCode = next.queryParams.code;
    if (!inviteCode) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
