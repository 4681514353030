export const environment = {
  production: true,
  api: 'https://aidorando-development-395612.appspot.com',
  localStorage: {
    language: 'aid_language'
  },
  adminHost: 'https://admin-aidorando-development-395612.firebaseapp.com',
  GOOGLE_MAP_API_KEY: 'AIzaSyB7IwrzVOv6jXia62vQqU7tBss6QTt8v6o',
  firebase: {
    apiKey: 'AIzaSyDUTt74aOnZucYARVfaFhgdf9AaJFtB3Fk',
    authDomain: 'aidorando-development-395612.firebaseapp.com',
    databaseURL: 'https://aidorando-development-395612.firebaseio.com',
    projectId: 'aidorando-development-395612'
  },
  STRIPE_API_KEY: 'pk_test_CbiPsGXqWk3lL1E6Lqmfaoqg00vlH7Amdk',
  TINY_MCE: 'oiwgz5ctxov97cb6s7c56lk5qeiepp4qydv8ejv0nd36uj44'
};
