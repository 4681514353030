import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'aid-insurance-type-item',
  template: `
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-checkbox
        class="padding-right-32"
        *ngIf="canCheck"
        [checked]="checked"
        [disabled]="disabled"
        color="primary"
        [indeterminate]="indeterminate"
        (change)="onChange()"
      >
      </mat-checkbox>
      <div
        class="element full-width"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="32px"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="pointer element--name"
          (click)="onSelect()"
        >
          <div class="mat-subtitle-2 element--color">
            {{ name }}
          </div>
          <div *ngIf="abbreviation" class="mat-subtitle-2 padding-left-4">
            ({{ abbreviation }})
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="64px"
          class="padding-right-32"
        >
          <ng-content select="[aidInsuranceTypeItemCenter]"></ng-content>
          <ng-content select="[aidInsuranceTypeItemRight]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./insurance-type-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceTypeItemComponent implements OnInit {
  @Input() name: string;
  @Input() abbreviation: string;
  @Input() canCheck = false;
  @Input() checked;
  @Input() indeterminate = false;
  @Input() selected = false;
  @Input() disabled = false;

  @Output() change = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onChange() {
    this.change.emit();
  }

  onSelect() {
    if (this.disabled) {
      return;
    }
    this.change.emit();
  }
}
