import { RegisterGuard } from './register.guard';
import { ResetPasswordGuard } from '@aid/auth/guards/reset-password.guard';
import { LoginAsUserGuard } from '@aid/auth/guards/login-as-user.guard';

export const guards = [RegisterGuard, ResetPasswordGuard, LoginAsUserGuard];

export * from './not-logged-in.guard';
export * from './register.guard';
export * from './reset-password.guard';
export * from './logged-in.guard';
export * from './login-as-user.guard';
