import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '@aid/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'aid-right-sidenav',
  template: `
    <mat-sidenav-container
      fxLayout="column"
      class="sidenav full-height full-width"
      (backdropClick)="onClose()"
    >
      <mat-sidenav
        [style.max-width.px]="width$ | async"
        #sidenav
        mode="over"
        position="end"
        autoFocus="false"
        [opened]="opened$ | async"
        (closed)="onClose()"
      >
        <ng-template #sidenavContent></ng-template>
      </mat-sidenav>

      <mat-sidenav-content fxLayout="column" class="full-height">
        <ng-content></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styleUrls: ['./right-sidenav.component.scss']
})
export class RightSidenavComponent implements OnInit {
  width$: Observable<number>;
  sidenavContent;
  opened$: Observable<boolean>;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('sidenavContent', { static: true, read: ViewContainerRef })
  set _sidenavContent(value: ViewContainerRef) {
    this.sidenavService.sidenavContent = value;
  }

  constructor(private sidenavService: SidenavService) {}

  ngOnInit() {
    this.opened$ = this.sidenavService.opened$;
    this.width$ = this.sidenavService.width$.pipe(map(value => value));
  }

  onClose() {
    this.sidenavService.close();
  }
}
