import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '@aid/shared/components/confirm-dialog/confirm-dialog.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  constructor(private matDialog: MatDialog) {}

  open(title: string, message: string): Observable<boolean> {
    return this.matDialog
      .open(ConfirmDialogComponent, {
        data: {
          title,
          message
        }
      })
      .afterClosed()
      .pipe(map(value => !!value));
  }
}
