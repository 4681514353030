import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationService } from '@aid/core/services/organization.service';
import { RequestParamsService } from '@aid/core/services';

@Injectable({
  providedIn: 'root'
})
export class OrganizationInterceptor implements HttpInterceptor {
  constructor(
    private organizationService: OrganizationService,
    private requestParamsService: RequestParamsService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const organizationId = this.organizationService.organizationId;
    if (!organizationId) {
      return next.handle(req);
    }

    if (
      this.requestParamsService.isOrganizationRequestValue ||
      req.url.indexOf('organizations') > 0
    ) {
      this.requestParamsService.isOrganizationRequest$.next(false);
      return next.handle(req);
    }

    if (req.url.indexOf('storage.googleapis.com') >= 0) {
      return next.handle(req);
    }
    const apiReq = req.clone({
      setParams: {
        organizationId: '' + organizationId
      }
    });
    return next.handle(apiReq);
  }
}
