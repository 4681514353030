import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Notification } from '@aid/notifications/types/classes';
import { Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

@Injectable()
export class NotificationsChannelService {
  constructor(private firestore: AngularFirestore) {}

  subscribe(memberId: number): Observable<Notification> {
    const currentDate = moment().toDate();
    return this.firestore
      .collection<Notification>(`notifications`, ref =>
        ref
          .where('memberId', '==', memberId)
          .where('creationDate', '>=', currentDate)
          .orderBy('creationDate', 'desc')
          .limit(1)
      )
      .valueChanges()
      .pipe(
        map(values => values[0]),
        filter(value => !!value),
        debounceTime(200)
      );
  }
}
