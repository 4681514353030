import { Injectable } from '@angular/core';
import { OrganizationService } from '@aid/core/services/organization.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Distributor } from '@aid/partnerships/types/classes';
import { OrganizationResourceService } from '@aid/shared/services/organization-resource.service';

@Injectable()
export class DistributorsService extends OrganizationResourceService {
  values$ = new BehaviorSubject<Distributor[]>(null);
  private _distributor$ = new BehaviorSubject<Distributor>(null);

  constructor(
    protected http: HttpClient,
    protected organizationService: OrganizationService
  ) {
    super(http, organizationService);
  }

  get resource() {
    return `distributors`;
  }

  list(): Observable<Distributor[]> {
    if (this.values$.value && !this.refresh) {
      return this.values$.asObservable();
    }
    return this.http.get<Distributor[]>(this.url).pipe(
      switchMap((values: Distributor[]) => {
        this.values$.next(values);
        this.clearRefresh();
        return this.values$;
      })
    );
  }

  get(id: number, force?: boolean): Observable<Distributor> {
    if (!force && this._distributor$.value) {
      return this._distributor$.asObservable();
    }

    return this.http
      .get<Distributor>(`${this.url}/${id}`)
      .pipe(
        tap((distributor: Distributor) => this._distributor$.next(distributor))
      );
  }

  get distributor$(): Observable<Distributor> {
    return this._distributor$.asObservable();
  }

  delete(valueId: number) {
    return this.http.delete(`${this.url}/${valueId}`).pipe(
      tap(() => {
        const values: Distributor[] = this.values$.value.filter(
          value => value.id !== valueId
        );
        this.values$.next(values);
      })
    );
  }
}
