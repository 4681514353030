import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aid-title',
  template: `
    <div class="mat-subtitle-2 padding-bottom-16" fxLayout="row">
      <span class="basic-color-900">{{
        title | translate | sentancecase
      }}</span>
      <span *ngIf="isRequired">*</span>
    </div>
  `,
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() title;
  @Input() isRequired = false;
  constructor() {}

  ngOnInit() {}
}
