import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Token } from '../types/interfaces/token.interface';
import { HttpClient } from '@angular/common/http';
import { LoginCredentials } from '../types/classes/login-credentials.class';
import { RegisterCredentials } from '../types/classes/register-credentials.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtService } from '@aid/auth/services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends JwtService {
  public static readonly ACCESS_TOKEN_KEY = 'aid_access_token';
  public static readonly REFRESH_TOKEN_KEY = 'aid_refresh_token';
  public static readonly ADMIN_KEY = 'aid_is_admin';

  private isLoggedIn = new BehaviorSubject<boolean>(null);
  private isAdminConnected = new BehaviorSubject<boolean>(null);

  private sharedLoading = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    super();
    this.isAdminConnected.next(!!localStorage.getItem(AuthService.ADMIN_KEY));
  }

  private connectAsAdmin() {
    localStorage.setItem(AuthService.ADMIN_KEY, 'true');
    this.isAdminConnected.next(true);
  }

  private disconnectAsAdmin() {
    localStorage.removeItem(AuthService.ADMIN_KEY);
    this.isAdminConnected.next(false);
  }

  get isAdminConnected$() {
    return this.isAdminConnected.asObservable();
  }

  get accessToken(): string {
    return localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
  }
  get refreshToken(): string {
    return localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);
  }

  get hasTokens() {
    return (
      !!localStorage.getItem(AuthService.ACCESS_TOKEN_KEY) &&
      !!localStorage.getItem(AuthService.REFRESH_TOKEN_KEY)
    );
  }

  loginAsAdmin(adminToken: string, userId: number): Observable<Token> {
    /**
     * Set the admin token in local storage so we can make the request.
     * After that we remove from local storage
     */
    this.clearCredentials();

    localStorage.setItem(AuthService.ACCESS_TOKEN_KEY, adminToken);

    return this.http.get<Token>(`users/${userId}/generate-token`).pipe(
      tap((token: Token) => {
        this.connectAsAdmin();
        this.saveCredentials(token);
      })
    );
  }

  login(loginCredentials: LoginCredentials) {
    return this.http.post('login', loginCredentials).pipe(
      tap((token: Token) => {
        this.isLoggedIn.next(true);
        this.saveCredentials(token);
      })
    );
  }

  register(registerCredentials: RegisterCredentials) {
    return this.http.post('users', registerCredentials).pipe(
      tap((token: Token) => {
        this.saveCredentials(token);
      })
    );
  }

  logOut() {
    this.clearCredentials();
    this.isLoggedIn.next(false);
    if (this.isAdminConnected.value) {
      this.disconnectAsAdmin();
    }
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  get isLoggedInValue(): boolean {
    return this.isLoggedIn.value;
  }

  saveCredentials(token: Token) {
    localStorage.setItem(AuthService.ACCESS_TOKEN_KEY, token.access);
    localStorage.setItem(AuthService.REFRESH_TOKEN_KEY, token.refresh);
  }

  clearCredentials() {
    localStorage.removeItem(AuthService.ACCESS_TOKEN_KEY);
    localStorage.removeItem(AuthService.REFRESH_TOKEN_KEY);
  }

  onRefreshToken() {
    const refreshToken = localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);

    return this.http
      .post('refresh-token', {
        refresh: refreshToken
      })
      .pipe(
        tap((token: Token) => {
          localStorage.setItem(AuthService.ACCESS_TOKEN_KEY, token.access);
        })
      );
  }

  forgetPassword(email: string) {
    const body = { email };
    return this.http.post('reset-password', body);
  }

  validateToken(token: string) {
    const queryParams = {
      token
    };
    return this.http.get('reset-password/token-validation', {
      params: queryParams
    });
  }

  resetPassword(password: string, token: string) {
    const body = {
      password
    };

    const queryParams = {
      token
    };

    return this.http.post('reset-password/submit', body, {
      params: queryParams
    });
  }

  setSharedLoading(value: boolean) {
    this.sharedLoading.next(value);
  }

  get sharedLoading$() {
    return this.sharedLoading.asObservable();
  }
}
