import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Notification } from '@aid/notifications/types/classes';
import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NotificationItemToastComponent } from '@aid/notifications/components/notification-item-toast/notification-item-toast.component';
import { ToastService } from '@aid/toast/services/toast.service';
import { Params, Router } from '@angular/router';
import { OrganizationService } from '@aid/core/services/organization.service';
import { NotificationType } from '@aid/notifications/types/enums';
import { NotificationsChannelService } from '@aid/notifications/services/notifications-channel.service';

@Injectable()
export class NotificationsService extends CrudPaginationService<Notification> {
  private badge = new BehaviorSubject<number>(0);

  constructor(
    protected http: HttpClient,
    private toastService: ToastService,
    private router: Router,
    private organizationService: OrganizationService,
    private notificationsChannelService: NotificationsChannelService
  ) {
    super(http, true);
  }

  get url() {
    return 'notifications';
  }

  subscribeBadge$(): Observable<number> {
    return this.http.get<number>(`${this.url}/badge`).pipe(
      tap(value => this.badge.next(value)),
      switchMap(() => this.badge.asObservable())
    );
  }

  read(notification: Notification) {
    if (notification.read) {
      return of(notification);
    }
    notification.read = true;
    return super.edit(notification);
  }

  readAll() {
    return this.http.post(`${this.url}/read`, {}).pipe(
      tap(() => {
        const values = this.values.value.map(value => ({
          ...value,
          read: true
        }));
        this.values.next([...values]);
      })
    );
  }

  seenAll() {
    if (this.badge.value <= 0) {
      return of();
    }
    return this.http
      .post(`${this.url}/seen`, {})
      .pipe(tap(() => this.badge.next(0)));
  }

  /**
   * Subscribe to Notifications Channel and get the latest notification
   * Emit a new value when notification channel receive a new value
   */
  subscribeLatest$() {
    const memberId = this.organizationService.organizationMember.member;
    return this.notificationsChannelService
      .subscribe(memberId)
      .pipe(tap(() => this.badge.next(this.badge.value + 1)));
  }

  show(notification: Notification) {
    this.toastService
      .show(NotificationItemToastComponent, notification)
      .afterClicked()
      .pipe(
        switchMap((_notification: Notification) => {
          if (_notification) {
            return this.read(_notification);
          }
          return of(null);
        })
      )
      .subscribe((_notification: Notification) => {
        if (_notification) {
          this.navigate(_notification);
        }
      });
  }

  navigate(notification: Notification): Promise<boolean> {
    const slug = this.organizationService.organization.slug;
    let commands = [];
    let queryParams: Params = null;
    switch (notification.type) {
      case NotificationType.DAMAGE:
        commands = ['damages', notification.metadata.damage];
        break;
      case NotificationType.REMINDER:
        commands = ['reminders'];
        queryParams = { reminder: notification.metadata.reminder };
        break;
      case NotificationType.CUSTOMER_COMMENT:
        commands = ['customers', notification.metadata.customer, 'comments'];
        break;
      case NotificationType.CUSTOMER:
        commands = ['customers', notification.metadata.customer];
        break;
      case NotificationType.INSURANCE_COMMENT:
        commands = [
          'customers',
          notification.metadata.customer,
          'insurances',
          notification.metadata.insurance,
          'logs'
        ];
        break;
      case NotificationType.INSURANCE:
        commands = [
          'customers',
          notification.metadata.customer,
          'insurances',
          notification.metadata.insurance
        ];
        break;
      case NotificationType.MISSING_INSURANCE:
        commands = [
          'customers',
          notification.metadata.customer,
          'insurances',
          'insurances-requests'
        ];
        break;
      case NotificationType.GDV_FILE_FINISHED:
        commands = ['gdv'];
        break;
      case NotificationType.COMMISSION_FILE_FINISHED:
        commands = ['commissions'];
        break;
      case NotificationType.DOCUMENT_IMPORT_INSURANCE:
        commands = [
          'customers',
          notification.metadata.customer,
          'insurances',
          notification.metadata.insurance,
          'logs'
        ];
        break;
      case NotificationType.DOCUMENT_IMPORT_CUSTOMER:
        commands = ['customers', notification.metadata.customer, 'comments'];
        break;
    }

    return this.router.navigate([slug, ...commands], { queryParams });
  }
}
