import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoreSidenavService {
  private readonly SIDENAV_STATE = 'aid_sidenav_collapsed';
  private collapsed = new BehaviorSubject<boolean>(null);

  initFromLocalStorage() {
    if (!localStorage.getItem(this.SIDENAV_STATE)) {
      localStorage.setItem(this.SIDENAV_STATE, 'false');
    }
    const collapsed = localStorage.getItem(this.SIDENAV_STATE) === 'true';
    this.collapsed.next(collapsed);
  }

  get collapsed$() {
    return this.collapsed.asObservable();
  }

  getCollapsed(): boolean {
    return this.collapsed.getValue();
  }

  close() {
    this.collapsed.next(true);
    localStorage.setItem(this.SIDENAV_STATE, 'true');
  }

  toggle() {
    this.collapsed.next(!this.collapsed.getValue());
    localStorage.setItem(
      this.SIDENAV_STATE,
      this.collapsed.getValue() ? 'true' : 'false'
    );
  }
}
