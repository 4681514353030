import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import * as moment from 'moment';
import { DeliveryDate } from '@aid/product-contract/types/classes';

@Component({
  selector: 'aid-product-contract-delivery-date-form',
  template: `
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="32px">
      <div fxLayout="column" fxLayoutGap="16px">
        <aid-date-picker
          [label]="'date'"
          [control]="date"
          [minDate]="currentDate"
        >
        </aid-date-picker>

        <aid-input [label]="'customer-number'" [control]="customerNumber">
        </aid-input>
      </div>

      <aid-form-actions
        [buttonLeft]="'cancel'"
        [buttonRight]="'submit'"
        [disable]="form.invalid"
        (clickRight)="onSubmit()"
        (clickLeft)="cancel.emit()"
      ></aid-form-actions>
    </form>
  `,
  styleUrls: ['./product-contract-delivery-date-form.component.scss']
})
export class ProductContractDeliveryDateFormComponent implements OnInit {
  form: UntypedFormGroup;
  currentDate = new Date();

  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      date: [null, Validators.required],
      customerNumber: [null, Validators.required]
    });
  }

  get date() {
    return this.form.get('date') as UntypedFormControl;
  }

  get customerNumber() {
    return this.form.get('customerNumber') as UntypedFormControl;
  }

  onSubmit() {
    const deliveryDate = new DeliveryDate();
    deliveryDate.date = moment(this.date.value).format('YYYY-MM-DD');
    deliveryDate.customer = this.customerNumber.value;

    this.submit.emit(deliveryDate);
  }
}
