import { User } from '@aid/core/types/classes/user.class';
import { MessageFile } from '@aid/chat/types/classes/message-file.class';

export class Message {
  id: number;
  message: string;
  sender?: User | number;
  receiver: User | number;
  created?: string | Date;
  type: 'text' | 'file';
  file?: MessageFile;
}
