import { ChatService } from '@aid/chat/services';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileService } from '@aid/shared/services';
import { MessageFile } from '@aid/chat/types/classes/message-file.class';
import { SystemChannelService } from '@aid/core/services/system-channel.service';

@Injectable()
export class MemberChatService extends ChatService {
  constructor(
    protected http: HttpClient,
    protected fileUploadService: FileService<MessageFile>,
    protected systemChannelService: SystemChannelService
  ) {
    super(http, fileUploadService, systemChannelService);
  }
}
