import { Injectable } from '@angular/core';
import { CommentsService } from '@aid/comments/services';
import { HttpClient } from '@angular/common/http';
import { FileService } from '@aid/shared/services';
import { Comment } from '@aid/comments/types/classes/comment.class';

@Injectable()
export class InsuranceCommentsService extends CommentsService {
  private static readonly RESOURCE = 'insurances';

  constructor(
    protected http: HttpClient,
    protected fileUploadService: FileService<Comment>
  ) {
    super(http, fileUploadService, InsuranceCommentsService.RESOURCE);
  }
}
