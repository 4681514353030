import { TranslateService } from '@ngx-translate/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });
    this.getAndInitTranslations();
  }
  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translate.instant('of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.translate.instant(
      'of'
    )} ${length}`;
  }; // eslint-disable-line
  getAndInitTranslations() {
    this.translate
      .get(['items-per-page', 'next-page', 'previous-page'])
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['items-per-page'];
        this.nextPageLabel = translation['next-page'];
        this.previousPageLabel = translation['previous-page'];
        this.getRangeLabel = this.rangeLabel;
        this.changes.next();
      });
  }
}
