import { NotificationsService } from './notifications.service';
import { NotificationIconService } from './notification-icon.service';
import { NotificationsChannelService } from './notifications-channel.service';

export const services = [
  NotificationsService,
  NotificationIconService,
  NotificationsChannelService
];

export * from './notifications.service';
export * from './notification-icon.service';
export * from './notifications-channel.service';
