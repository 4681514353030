import { InsuranceType, Provider } from '@aid/administration/types/classes';
import { CustomerQueryParams } from '@aid/customers/types/classes/customer-query-params.class';
import { Member } from '@aid/members/types/classes';
import { TypeOfCustomer } from '@aid/customers/types/enums';
import { Distributor } from '@aid/partnerships/types/classes';

export class CustomerFilters {
  page = 1;
  pageSize = 20;
  search: string;
  provider: Provider;
  insuranceType: InsuranceType;
  customerSpecification: string;
  agent: Member;
  customerType: TypeOfCustomer;
  distributor: Distributor;

  get params(): CustomerQueryParams {
    const params: CustomerQueryParams = {};

    if (this.search) {
      params.search = this.search;
    }

    if (this.provider) {
      params.provider = this.provider.id;
    }

    if (this.insuranceType) {
      params.insurance_type = this.insuranceType.id;
    }

    if (this.customerSpecification) {
      params.customer_specifications = this.customerSpecification;
    }

    if (this.agent) {
      params.supervisor = this.agent.id;
    }

    if (this.customerType) {
      params.customer_type = this.customerType;
    }

    if (this.distributor) {
      params.distributor = this.distributor.id;
    }

    return params;
  }

  get queryParams(): CustomerQueryParams {
    const queryParams = new CustomerQueryParams();

    if (this.provider) {
      queryParams.provider = this.provider.id;
    }
    if (this.insuranceType) {
      queryParams.insurance_type = this.insuranceType.id;
    }
    if (this.customerSpecification) {
      queryParams.customer_specifications = this.customerSpecification;
    }

    if (this.search) {
      queryParams.search = this.search;
    }

    if (this.agent) {
      queryParams.supervisor = this.agent.id;
    }

    if (this.customerType) {
      queryParams.customer_type = this.customerType;
    }

    if (this.distributor) {
      queryParams.distributor = this.distributor.id;
    }

    queryParams.page = this.page;
    queryParams.pageSize = this.pageSize;

    return queryParams;
  }

  get filters(): { [id: string]: string } {
    const filters: { [id: string]: string } = {};
    if (this.provider) {
      filters.provider = this.provider.name;
    }
    if (this.insuranceType) {
      filters.insuranceType = this.insuranceType.abbreviation;
    }
    if (this.customerSpecification) {
      filters.customerSpecification = this.customerSpecification;
    }

    if (this.agent) {
      filters.agent = this.agent.name;
    }

    if (this.customerType) {
      filters.customerType = this.customerType;
    }

    if (this.distributor) {
      filters.distributor = this.distributor.name;
    }

    return filters;
  }

  static createFromParams(
    params: CustomerQueryParams,
    currentFilter: CustomerFilters
  ) {
    const filter = new CustomerFilters();

    if (
      currentFilter &&
      currentFilter.insuranceType &&
      currentFilter.insuranceType.id === +params.insurance_type
    ) {
      filter.insuranceType = currentFilter.insuranceType;
    }

    if (
      currentFilter &&
      currentFilter.provider &&
      currentFilter.provider.id === +params.provider
    ) {
      filter.provider = currentFilter.provider;
    }

    if (
      currentFilter &&
      currentFilter.agent &&
      currentFilter.agent.id === +params.supervisor
    ) {
      filter.agent = currentFilter.agent;
    }

    if (
      currentFilter &&
      currentFilter.distributor &&
      currentFilter.distributor.id === +params.distributor
    ) {
      filter.distributor = currentFilter.distributor;
    }

    if (params.customer_specifications) {
      filter.customerSpecification = params.customer_specifications;
    }

    if (params.customer_type) {
      filter.customerType = params.customer_type;
    }

    return filter;
  }

  removeFilter(filterId: string): CustomerFilters {
    const filter = new CustomerFilters();
    if (filterId !== 'provider') {
      filter.provider = this.provider;
    }

    if (filterId !== 'insuranceType') {
      filter.insuranceType = this.insuranceType;
    }

    if (filterId !== 'customerSpecification') {
      filter.customerSpecification = this.customerSpecification;
    }

    if (filterId !== 'agent') {
      filter.agent = this.agent;
    }

    if (filterId !== 'customerType') {
      filter.customerType = this.customerType;
    }

    if (filterId !== 'distributor') {
      filter.distributor = this.distributor;
    }

    return filter;
  }
}
