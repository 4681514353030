import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { UploadPhotoDetails } from '@aid/shared/types/classes/upload-photo-details.class';
import { Subject } from 'rxjs';

@Component({
  selector: 'aid-upload-photo-dialog',
  template: `
    <aid-dialog [title]="data.title" [closeButton]="false">
      <div
        fxLayout="column"
        fxLayoutAlign="space-between center"
        class="container"
      >
        <aid-upload-photo
          [control]="control"
          [willCropPhoto]="true"
          [details]="data.details"
          [acceptedFiles]="data.acceptedFile"
          [aspectRatio]="data.aspectRatio"
          [roundCropper]="data.roundCropper"
          [horizontalSize]="data.horizontalResize"
          [verticalSize]="data.verticalResize"
        ></aid-upload-photo>

        <div fxLayout="row" fxLayoutAlign="end center" class="full-width">
          <aid-form-actions
            [buttonLeft]="'cancel'"
            [buttonRight]="'upload'"
            (clickRight)="onSave()"
            (clickLeft)="onCancel()"
          ></aid-form-actions>
        </div>
      </div>
    </aid-dialog>
  `,
  styleUrls: ['./upload-photo-dialog.component.scss']
})
export class UploadPhotoDialogComponent implements OnInit, OnDestroy {
  control: UntypedFormControl = new UntypedFormControl();

  private ngUnsubscribe = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<UploadPhotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: UploadPhotoDetails
  ) {}

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close();
  }
  onSave() {
    this.dialogRef.close(this.control.value);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
