import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aid-under-construction',
  template: `
    <div fxLayout="row" fxLayoutAlign="center start" style="padding: 32px">
      <div class="mat-headline-6">
        {{ 'under-construction' | translate }}
      </div>
    </div>
  `,
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
