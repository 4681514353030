import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'aid-loading-button',
  template: `
    <button
      mat-flat-button
      [color]="color"
      [disabled]="disabled"
      [ngClass]="{ disabled: disabled }"
      (click)="onPress()"
      type="button"
      class="relative"
    >
      <div *ngIf="loading" class="progress-spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="24"
          [strokeWidth]="3"
        >
        </mat-progress-spinner>
      </div>
      <mat-icon
        *ngIf="icon"
        [ngClass]="{ hidden: loading }"
        [svgIcon]="icon"
      ></mat-icon>
      <span [ngClass]="{ hidden: loading }">
        {{ label | translate | sentancecase }}
      </span>
    </button>
  `,
  styleUrls: ['./loading-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingButtonComponent implements OnInit {
  @Input() label: string;
  @Input() loading: boolean;
  @Input() disabled = false;
  @Input() icon: string;
  @Input() color: ThemePalette = 'primary';
  @Output() press: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onPress() {
    if (!this.loading) {
      this.press.emit();
    }
  }
}
