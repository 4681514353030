import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aid-warning-toast',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-8">
      <div fxLayout="row" fxLayoutGap="8px">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="padding-left-16"
        >
          <mat-icon svgIcon="alert_triangle"></mat-icon>
        </div>
        <div class="mat-subtitle-2 white">
          {{ title | translate | uppercase }}
        </div>
        <div class="mat-subtitle-2 white">
          {{ message | translate | sentancecase }}
        </div>
      </div>
      <button mat-icon-button (click)="close.emit()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./warning-toast.component.scss']
})
export class WarningToastComponent implements OnInit {
  @Input() message: string;
  @Input() title: string;
  @Output() close = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
