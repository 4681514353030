import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { HttpClient } from '@angular/common/http';
import { ReminderAttachment } from '@aid/reminders/types/classes/reminderAttachment.class';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { GcsFile } from '@aid/shared/types/classes';

@Injectable()
export class ReminderAttachmentService extends CrudPaginationService<
  ReminderAttachment
> {
  resourceId: number;
  resource: string = 'reminders';

  constructor(protected http: HttpClient) {
    super(http, true);
  }

  get url(): string {
    return `${this.resource}/${this.resourceId}/attachments`;
  }

  setResourceId(resource: number) {
    this.resourceId = resource;
  }

  get_attachments(reminderId: number): Observable<ReminderAttachment[]> {
    this.setResourceId(reminderId);
    return this.http
      .get<ReminderAttachment[]>(`${this.resource}/${reminderId}/attachments`)
      .pipe(tap(() => this.loading.next(false)));
  }

  viewFile(attachment: ReminderAttachment) {
    const url = `${this.url}/${attachment.id}/signed-url`;
    return this.http.get<string>(url).subscribe(value => window.open(value));
  }

  downloadFile(attachment: ReminderAttachment) {
    const url = `${this.url}/${attachment.id}/signed-url`;
    const fileName = (attachment.file as GcsFile).fileName;
    return this.http
      .get<string>(url)
      .pipe(
        map(value => value),
        switchMap(url => this.http.get(url, { responseType: 'blob' })),
        tap(blob => {
          this.download(blob, fileName);
        })
      )
      .subscribe(value => value);
  }

  download(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);

    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();
  }

  uploadBinary(preSignedUploadUrl: string, file: File) {
    const fileParts = file.name.split('.');
    const fileType =
      fileParts.length > 1 ? fileParts[fileParts.length - 1] : '';

    if (fileType == 'msg') {
      return this.http.put(preSignedUploadUrl, file, {
        headers: {
          'Content-Type': 'application/vnd.ms-outlook'
        }
      });
    } else {
      return this.http.put(preSignedUploadUrl, file);
    }
  }
}
