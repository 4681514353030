import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { User } from '@aid/core/types/classes';
import { first, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Permission } from '@aid/core/types/interfaces';
import { MembersService } from '@aid/members/services/members.service';
import { AuthService } from '@aid/auth/services';
import { OrganizationService } from '@aid/core/services/organization.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly url = 'user';
  private _user$: BehaviorSubject<User> = new BehaviorSubject(null);
  private _userPermissions$ = new BehaviorSubject<{
    [code: string]: Permission;
  }>(null);

  constructor(
    private http: HttpClient,
    private membersService: MembersService,
    private organizationService: OrganizationService,
    private authService: AuthService,
    private firebaseAuth: AngularFireAuth
  ) {}

  get() {
    if (this._user$.value) {
      return this._user$.asObservable();
    }

    return this.http.get(this.url).pipe(
      tap((user: User) => this._user$.next(new User(user))),
      switchMap(() => this._user$.asObservable())
    );
  }

  get user$(): Observable<User> {
    return this._user$.asObservable();
  }

  updateUser(user: User) {
    this._user$.next(user);
  }

  userSubject(): BehaviorSubject<User> {
    return this._user$;
  }

  get userValue(): User {
    return this._user$.value;
  }

  getPermissions(): Observable<Permission[]> {
    const memberId = this.userValue.defaultOrganization.member;
    return this.membersService
      .permissions(memberId)
      .pipe(
        tap((permissions: Permission[]) => this.setPermissions(permissions))
      );
  }
  get isOwner() {
    return this.userValue.defaultOrganization.owner;
  }
  /**
   * Get the custom token from the API and authenticate on Firebase
   * Authentication on Firebase is Required because we are using Firestore as RealTime Database for real time events
   *
   * I saw that the app is working very slow if I don't call the user after signInWithCustomToken
   * IDK why this is happening
   */
  authenticateFirebase() {
    return this.http.get<string>('users/firebase-auth').pipe(
      switchMap(token => from(this.firebaseAuth.signInWithCustomToken(token))),
      switchMap(() => this.firebaseAuth.user.pipe(first()))
    );
  }

  getCurrentMemberId() {
    for (const organization of this.userValue.memberOf) {
      if (organization.id === this.organizationService.organization.id) {
        return organization.member;
      }
    }
  }

  private setPermissions(permissions: Permission[]) {
    const userPermissions: { [code: string]: Permission } = {};
    permissions.forEach(value => {
      userPermissions[value.code] = value;
    });
    this._userPermissions$.next(userPermissions);
  }

  get userPermissions() {
    return this._userPermissions$.value;
  }

  hasPermission(checkPermissions: string[]): boolean {
    if (!this.userPermissions) {
      return false;
    }

    if (this.userPermissions.administrators) {
      return true;
    }

    for (const permission of checkPermissions) {
      if (!this.userPermissions[permission]) {
        return false;
      }
    }
    return true;
  }

  getSignatureUrl(id: number): Observable<string> {
    const url = `users/${id}/signature`;
    return this.http.get<string>(url);
  }

  logOut() {
    this.authService.logOut();
    this._user$.next(null);
  }
}
