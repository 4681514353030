import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'aid-confirm-dialog',
  template: `
    <aid-dialog
      class="confirm-dialog"
      [title]="data.title"
      [closeButton]="false"
    >
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        class="confirm-dialog__container"
      >
        <div class="mat-subtitle-2 confirm-dialog__subtitle">
          {{ data.message | translate }}
        </div>
        <aid-form-actions
          [buttonLeft]="'cancel'"
          [buttonRight]="'confirm'"
          (clickLeft)="onCancel()"
          (clickRight)="onConfirm()"
          data-cy="aid-confirm-dialog"
        ></aid-form-actions>
      </div>
    </aid-dialog>
  `,
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
    }
  ) {}

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
