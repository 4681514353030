import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '@aid/auth/services';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ResetPasswordGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = next.queryParams.token;
    if (!token) {
      this.router.navigate(['/']);
      return false;
    }

    return this.authService.validateToken(token).pipe(
      map(() => true),
      catchError(() => {
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }
}
