import { Member } from '@aid/members/types/classes';
import { Customer } from '@aid/customers/types/classes';
import { Insurance } from '@aid/insurances/types/classes';

export class Reminder {
  id: number;
  status: string;
  created: string | Date;
  dueDate: string | Date;
  text: string;
  owner: number | Member;
  assignee: number | Member;
  customer: number | Customer;
  insurance: number | Insurance;
}
