import { ModuleWithProviders, NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

import * as fromComponents from './components';
import * as fromServices from './services';
import { SharedModule } from '@aid/shared/shared.module';
import {
  defaultToastConfig,
  TOAST_CONFIGURATION
} from '@aid/toast/utils/toast-config';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [OverlayModule, SharedModule],
  providers: [...fromServices.services]
})
export class ToastModule {
  public static forRoot(
    config = defaultToastConfig
  ): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIGURATION,
          useValue: config
        }
      ]
    };
  }
}
