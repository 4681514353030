import { Injectable } from '@angular/core';
import { CrudPaginationService } from '@aid/shared/services/crud-pagination.service';
import { HttpClient } from '@angular/common/http';
import { InsuranceLog } from '@aid/insurances/types/classes';
import { FileService } from '@aid/shared/services';
import { GcsFile } from '@aid/shared/types/classes';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class InsuranceLogsService extends CrudPaginationService<InsuranceLog> {
  private insuranceId: number;
  private log: InsuranceLog;

  constructor(
    protected http: HttpClient,
    protected fileUploadService: FileService<GcsFile>
  ) {
    super(http, true);
  }

  set insurance(insurance: number) {
    this.insuranceId = insurance;
  }

  get url(): string {
    return `insurances/${this.insuranceId}/logs`;
  }

  get insuranceLog() {
    return this.log;
  }

  set insuranceLog(log: InsuranceLog) {
    this.log = log;
  }

  add(insuranceLog: InsuranceLog): Observable<InsuranceLog> {
    return super.add(insuranceLog).pipe(
      switchMap((_insuranceLog: InsuranceLog) => {
        const file = insuranceLog.file ? insuranceLog.file.file : null;
        return this.fileUploadObject(_insuranceLog, file);
      })
    );
  }

  edit(insuranceLog: InsuranceLog): Observable<InsuranceLog> {
    return super.edit(insuranceLog).pipe(
      switchMap((_insuranceLog: InsuranceLog) => {
        if (_insuranceLog.file) {
          return this.fileUploadObject(_insuranceLog, insuranceLog.file.file);
        }
        return of(_insuranceLog);
      })
    );
  }
  viewFile(insuranceLog: InsuranceLog) {
    const url = `${this.url}/${insuranceLog.id}/signed-url`;
    this.fileUploadService.viewFile(url);
  }

  fileUploadObject(insuranceLog: InsuranceLog, file?: File) {
    if (file && insuranceLog.file.uploadSignedUrl) {
      return this.uploadFile(file, insuranceLog.file.uploadSignedUrl).pipe(
        map(() => insuranceLog)
      );
    }
    return of(insuranceLog);
  }
  uploadFile(file: File, url: string) {
    var fileParts = file.name.split('.');
    var fileType = fileParts.length > 1 ? fileParts[fileParts.length - 1] : '';

    if (fileType == 'msg') {
      return this.http.put(url, file, {
        headers: {
          'Content-Type': 'application/vnd.ms-outlook'
        }
      });
    } else {
      return this.http.put(url, file);
    }
  }
}
