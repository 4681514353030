import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'squareMeter'
})
export class SquareMeterPipe implements PipeTransform {
  transform(value: any): any {
    if (!value && typeof value !== 'number') {
      return value;
    }
    return `${value}m²`;
  }
}
